import React, { useState, useEffect } from "react";
import { Button, Accordion, Card } from "react-bootstrap";
import "./readMore.scss";
import {ReactComponent as ShowmoreArrowIcon} from "../../images/showmore-icon.svg";
import {ReactComponent as ShowlessArrowIcon} from "../../images/showless-icon.svg";

export const ReadMore = (props) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [isActive, setIsActive] = useState([]);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    let tempIsActive = [];
    if (
      props.productToc &&
      props.productToc.toc &&
      props.productToc.toc.length > 0
    ) {
      props.productToc.toc.forEach((element) => tempIsActive.push(true));
    }
    setIsActive(tempIsActive);
  }, []);

  const updateIsActive = (index) => {
    let tempIsActive = [...isActive];
    tempIsActive[index] = !isActive[index];
    setIsActive(tempIsActive);
  };

  const toggleBtn = (index) => {
    let openContent = "expand-btn";
    if (isActive.length > 0 && !isActive[index]) {
      openContent += " collapse-btn";
    }
    return openContent;
  };

  const textContent = () => {
    if (props.content.length > props.descriptionLength) {
      return (
        <div className="text">
          {isReadMore && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.content.slice(0, props.descriptionLength),
                }}
              />
            </>
          )}
          {!isReadMore && (
            <div
              dangerouslySetInnerHTML={{
                __html: props.content,
              }}
            />
          )}
          <div className={`c-show-more ${isReadMore ? "collapsed" : ""}`}>
            <button
              onClick={toggleReadMore}
              id={props.buttonId}
              aria-labelledby={`${props.buttonId}  ${props.headingId}`}
              className="read-or-hide d-flex flex-row justify-content-center align-items-center"
            >
              {isReadMore ? (
                <span className="show-divider">
                  Show more{" "}
                  <ShowmoreArrowIcon/>
                </span>
              ) : (
                <span className="show-divider">
                  Show less{" "}
                  <ShowlessArrowIcon/>
                </span>
              )}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text">
          <div
            dangerouslySetInnerHTML={{
              __html: props.content,
            }}
          />
        </div>
      );
    }
  };

  const moduleContent = () => {
    let tempModules = [];
    if (props.productModule.modules.length > props.itemListLength) {
      tempModules = props.productModule.modules.slice(0, props.itemListLength);
      return (
        <div className="text">
          <ul>
            {isReadMore &&
              tempModules.map((module, index) => (
                <li key={index} className="list-module">
                  {module.title}
                </li>
              ))}
            {!isReadMore && (
              <React.Fragment>
                {props.productModule.modules.map((module, index) => (
                  <li key={index} className="list-module">
                    {module.title}
                  </li>
                ))}
              </React.Fragment>
            )}
          </ul>
          <div className={`c-show-more ${isReadMore ? "collapsed" : ""}`}>
            <button
              onClick={toggleReadMore}
              id={props.buttonId}
              aria-labelledby={`${props.buttonId}  ${props.headingId}`}
              className="read-or-hide d-flex flex-row justify-content-center align-items-center"
            >
              {isReadMore ? (
                <span className="show-divider">
                  Show more{" "}
                  <ShowmoreArrowIcon/>
                </span>
              ) : (
                <span className="show-divider">
                  Show less{" "}
                  <ShowlessArrowIcon/>
                </span>
              )}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text">
          <ul>
            {props.productModule.modules.map((module, index) => (
              <li key={index} className="list-module">
                {module.title}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  const tocContent = () => {
    if (
      props.productToc.toc &&
      props.productToc.toc.length > 0 &&
      !props.productToc.tocString
    ) {
      if (props.productToc.toc.length > props.itemListLength) {
        let tempToc = props.productToc.toc.slice(0, props.itemListLength);
        return (
          <div className="text">
            {isReadMore && getTocList(tempToc)}
            {!isReadMore && getTocList(props.productToc.toc)}
            <div className={`c-show-more ${isReadMore ? "collapsed" : ""}`}>
            <button
              onClick={toggleReadMore}
              id={props.buttonId}
              aria-labelledby={`${props.buttonId}  ${props.headingId}`}
              className="read-or-hide d-flex flex-row justify-content-center align-items-center"
            >
              {isReadMore ? (
                <span className="show-divider">
                  Show more{" "}
                  <ShowmoreArrowIcon/>
                </span>
              ) : (
                <span className="show-divider">
                  Show less{" "}
                  <ShowlessArrowIcon/>
                </span>
              )}
            </button>
          </div>
          </div>
        );
      } else {
        return <div className="text">{getTocList(props.productToc.toc)}</div>;
      }
    } else if (
      (!props.productToc.toc ||
        (props.productToc.toc && props.productToc.toc.length === 0)) &&
      props.productToc.tocString &&
      props.productToc.tocString.length > 0
    ) {
      return <React.Fragment>{textContent()}</React.Fragment>;
    } else if (
      props.productToc.toc &&
      props.productToc.toc.length > 0 &&
      props.productToc.tocString &&
      props.productToc.tocString.length > 0
    ) {
      if (props.productToc.toc.length > props.itemListLength) {
        let tempToc = props.productToc.toc.slice(0, props.itemListLength);
        return (
          <div className="text">
            {isReadMore && getTocList(tempToc)}
            {!isReadMore && (
              <React.Fragment>
                {getTocList(props.productToc.toc)}
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.content.slice(0, props.descriptionLength),
                  }}
                />
              </React.Fragment>
            )}
            <div className={`c-show-more ${isReadMore ? "collapsed" : ""}`}>
            <button
              onClick={toggleReadMore}
              id={props.buttonId}
              aria-labelledby={`${props.buttonId}  ${props.headingId}`}
              className="read-or-hide d-flex flex-row justify-content-center align-items-center"
            >
              {isReadMore ? (
                <span className="show-divider">
                  Show more{" "}
                  <ShowmoreArrowIcon/>
                </span>
              ) : (
                <span className="show-divider">
                  Show less{" "}
                  <ShowlessArrowIcon/>
                </span>
              )}
            </button>
          </div>
          </div>
        );
      } else {
        return (
          <div className="text">
            {isReadMore && getTocList(props.productToc.toc)}
            {!isReadMore && (
              <React.Fragment>
                {getTocList(props.productToc.toc)}
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.content.slice(0, props.descriptionLength),
                  }}
                />
              </React.Fragment>
            )}
            <div className={`c-show-more ${isReadMore ? "collapsed" : ""}`}>
            <button
              onClick={toggleReadMore}
              id={props.buttonId}
              aria-labelledby={`${props.buttonId}  ${props.headingId}`}
              className="read-or-hide d-flex flex-row justify-content-center align-items-center"
            >
              {isReadMore ? (
                <span className="show-divider">
                  Show more{" "}
                  <ShowmoreArrowIcon/>
                </span>
              ) : (
                <span className="show-divider">
                  Show less{" "}
                  <ShowlessArrowIcon/>
                </span>
              )}
            </button>
          </div>
          </div>
        );
      }
    }
  };

  const getTocList = (tocData) => {
    return (
      <Accordion className="table-content-wr">
        {tocData.map((topic, index) => (
          <Card className="content-container">
            <Card.Header className="content-title">
              <Accordion.Toggle
                as={Button}
                variant="link"
                id={index}
                eventKey={`"${index}"`}
                className={toggleBtn(index)}
                onClick={(event) => {
                  updateIsActive(event.target.id);
                }}
              >
                {topic.label}
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey={`"${index}"`}>
              <Card.Body className="accordion-panel">
                <ol>
                  {topic.children &&
                    topic.children.length > 0 &&
                    topic.children.map((subTopic) => <li>{subTopic.label}</li>)}
                </ol>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    );
  };

  return (
    <React.Fragment>
      {!props.isModule && !props.isToc && textContent()}
      {props.isModule && !props.isToc && moduleContent()}
      {!props.isModule && props.isToc && tocContent()}
    </React.Fragment>
  );
};
