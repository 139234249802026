import React, { useContext } from "react";
import { AccordionContext, useAccordionToggle } from "react-bootstrap";

export function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    callback && callback(eventKey);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className={isCurrentEventKey ? "expanded" : "collapsed"}
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      onClick={() => decoratedOnClick()}
    >
      {children}
    </button>
  );
}
