import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSort: window.sessionStorage.getItem("exchange.search.selectedSort")
    ? window.sessionStorage.getItem("exchange.search.selectedSort")
    : null,
  selectedSortDisplayName: window.sessionStorage.getItem(
    "exchange.search.selectedSortDisplayName"
  )
    ? window.sessionStorage.getItem("exchange.search.selectedSortDisplayName")
    : null,
};

export const sortSlice = createSlice({
  name: "sort",
  initialState,
  reducers: {
    updateSelectedSort: (state, action) => {
      state.selectedSort = action.payload.type;
      state.selectedSortDisplayName = action.payload.displayValue;
      window.sessionStorage.setItem(
        "exchange.search.selectedSort",
        action.payload.type
      );
      window.sessionStorage.setItem(
        "exchange.search.selectedSortDisplayName",
        action.payload.displayValue
      );
    },
    resetSelectedSort: (state, action) => {
      state.selectedSort = null;
      state.selectedSortDisplayName = null;
      window.sessionStorage.removeItem("exchange.search.selectedSort");
      window.sessionStorage.removeItem(
        "exchange.search.selectedSortDisplayName"
      );
    },
  },
});

export const { updateSelectedSort, resetSelectedSort } = sortSlice.actions;
export default sortSlice.reducer;
