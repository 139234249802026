import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { disciplineAsync } from "./disciplineSlice";
import { Discipline } from "./Discipline";
import { DisciplineHeaderImage } from "./DisciplineHeaderImage";
import Col from "react-bootstrap/Container";
import "./discipline.scss";

export function DisciplineContainer() {
  const disciplineList = useSelector(
    (state) => state.discipline.disciplineList
  );
  const searchValue = useSelector((state) => state.search.searchValue);
  const dispatch = useDispatch();

  useEffect(() => {
    if(disciplineList === null){
      dispatch(disciplineAsync());
    }    
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="container">
        <h2 className="hm-title">Browse your subject</h2>
        <div className="ex-home hm-discipline row justify-content-center">
          {disciplineList &&
            disciplineList._embedded &&
            disciplineList._embedded.disciplineCategories &&
            disciplineList._embedded.disciplineCategories.map(
              (discipline, index) => (
                <Col className="ex-row ex-box col-sm" key={index}>
                  <DisciplineHeaderImage index={index} />
                  <Discipline
                    discipline={discipline}
                    searchValue={searchValue}
                  />
                </Col>
              )
            )}
        </div>
      </div>
    </React.Fragment>
  );
}
