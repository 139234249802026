import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  changeCurrentInstitute,
  updateRefreshInstitution,
  changeIsInstituteUpdated,
  updateOrgClassification,
} from "../exHeader/userSlice";
import Dropdown from "react-bootstrap/Dropdown";
import "./institutePickList.scss";
import { BtnToolTip } from "../btnToolTip/BtnToolTip";
import "./institutePickList.scss";
import {
  productOffersAsync,
  resetProductOfferData,
  productAvailableToBuyOffersAsync,
} from "../../pages/pdp/productSlice";
import {
  PAGES,
  ORG_CLASSIFICATION,
  K12_TYPE,
  SERP_SORT,
} from "../../../config";
import { updateSendSearchRequest } from "../search/searchSlice";
import { resetDisciplineCount } from "../subject/subjectSlice";
import { resetSelectedSort, updateSelectedSort } from "../sort/sortSlice";
import {
  updateIsRedirectToSearch,
  updateCurrentResultPage,
  resetK12ItemsCount,
} from "../searchResult/searchResultSlice";
import { useLocation, useHistory } from "react-router-dom";
import { ReactComponent as InstituteIcon } from "../../images/institution-icon.svg";
import { ReactComponent as InstituteArrow } from "../../images/institution-arrow-01.svg";
import { ReactComponent as SearchCloseIcon } from "../../widgets/search/images/search-close-icon.svg";
import { getOrgClassification } from "../../util/userUtil";

export const InstitutesList = (props) => {
  const ref = useRef();
  const userInfo = useSelector((state) => state.user.userData);
  const productData = useSelector((state) => state.product.productData);
  const preSelectedOrganization = useSelector(
    (state) => state.user.preSelectedOrganization
  );
  const [userOrgs, setUserOrgs] = useState([]);
  const [isdropdownOpen, setIsdropdownOpen] = useState(false);
  const [otherUserOrgs, setOtherUserOrgs] = useState([]);
  const dispatch = useDispatch();
  const config = window.__env.configs["appConfig"];
  let location = useLocation();
  let history = useHistory();
  let queryParams = new URLSearchParams(location.search);
  const [currentUserOrg, setCurrentUserOrg] = useState("");
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const isRedirectToSearch = useSelector(
    (state) => state.searchResult.isRedirectToSearch
  );
  const orgClassification = useSelector(
    (state) => state.user.orgClassification
  );
  const productAvailableToBuyOffers = useSelector(
    (state) => state.product.productAvailableToBuyOffers
  );

  const getInstitute = (institute) => {
    if (queryParams.get("integration_context_token")) {
      const displaynameInstitute = institute.displayName
        ? institute.displayName
        : "";
      let displayNameIns = "";
      if (displaynameInstitute.length > 0) {
        if (window.innerWidth >= 600) {
          displayNameIns =
            displaynameInstitute.substring(displaynameInstitute, 12) + "...";
        } else {
          displayNameIns =
            displaynameInstitute.substring(displaynameInstitute, 9) + "...";
        }
      }
      return {
        id: institute.id,
        displayName: displayNameIns,
        displayNameDropdown: displaynameInstitute.toUpperCase(),
        city:
          institute.location && institute.location.city
            ? `${institute.location.city
                .slice(0, 1)
                .toUpperCase()}${institute.location.city
                .slice(1, institute.location.city.length)
                .toLowerCase()}`
            : "",
        state:
          institute.location && institute.location.state
            ? institute.location.state.toUpperCase()
            : "",
        country:
          institute.location && institute.location.country
            ? institute.location.country.toUpperCase()
            : "",
        classification:
          institute.organization && institute.organization.classification
            ? institute.organization.classification
            : "",
      };
    } else {
      const displaynameInstitute = institute.organization.displayName
        ? institute.organization.displayName
        : "";
      let displayNameIns = "";
      if (displaynameInstitute.length > 0) {
        displayNameIns =
          displaynameInstitute.substring(displaynameInstitute, 12) + "...";
      }
      return {
        id: institute.organization.orgId,
        displayName: displayNameIns,
        displayNameDropdown: displaynameInstitute.toUpperCase(),
        city:
          institute.organization &&
          institute.organization.location &&
          institute.organization.location.city
            ? `${institute.organization.location.city
                .slice(0, 1)
                .toUpperCase()}${institute.organization.location.city
                .slice(1, institute.organization.location.city.length)
                .toLowerCase()}`
            : "",
        state:
          institute.organization &&
          institute.organization.location &&
          institute.organization.location.state
            ? institute.organization.location.state.toUpperCase()
            : "",
        country:
          institute.organization &&
          institute.organization.location &&
          institute.organization.location.country
            ? institute.organization.location.country.toUpperCase()
            : "",
        classification:
          institute.organization && institute.organization.classification
            ? institute.organization.classification
            : "",
      };
    }
  };

  useEffect(() => {
    let tempCurrentOrg = "";
    if (
      queryParams.has("integration_context_token") &&
      userInfo &&
      userInfo.lmsOrg &&
      window.localStorage.getItem("exchange.user.lmsInstitute")
    ) {
      tempCurrentOrg = getInstitute(
        JSON.parse(window.localStorage.getItem("exchange.user.lmsInstitute"))
      );
      setCurrentUserOrg(tempCurrentOrg);
    } else {
      if (
        userInfo &&
        userInfo.organizations &&
        userInfo.organizations.length > 0 &&
        window.localStorage.getItem("exchange.user.defaultInstitute")
      ) {
        if (history.action === "POP" && preSelectedOrganization) {
          tempCurrentOrg = getInstitute(preSelectedOrganization);
          window.localStorage.setItem(
            "exchange.user.defaultInstitute",
            JSON.stringify(tempCurrentOrg)
          );
          dispatch(changeCurrentInstitute(tempCurrentOrg.id));
          if (
            preSelectedOrganization.organization &&
            preSelectedOrganization.organization.classification &&
            (preSelectedOrganization.organization.classification.toLowerCase() ===
              "higher ed" ||
              preSelectedOrganization.organization.classification.toLowerCase() ===
                "higher education")
          ) {
            dispatch(updateOrgClassification(ORG_CLASSIFICATION.HIGHER_ED));
          } else {
            dispatch(updateOrgClassification(ORG_CLASSIFICATION.SCHOOL));
          }
        } else {
          tempCurrentOrg = getInstitute(
            JSON.parse(
              window.localStorage.getItem("exchange.user.defaultInstitute")
            )
          );
        }

        setCurrentUserOrg(tempCurrentOrg);
      }
    }

    if (
      userInfo &&
      !userInfo.lmsOrg &&
      userInfo.organizations &&
      userInfo.organizations.length > 0 &&
      tempCurrentOrg &&
      tempCurrentOrg.id
    ) {
      if (userOrgs && userOrgs.length === 0) {
        if (history.action === "POP") {
          setUserOrgs((prevState) => [...prevState, tempCurrentOrg]);
        } else {
          setUserOrgs((prevState) => [
            ...prevState,
            getInstitute(
              JSON.parse(
                window.localStorage.getItem("exchange.user.defaultInstitute")
              )
            ),
          ]);
        }

        let otherOrgs = [];
        otherOrgs = _.filter(userInfo.organizations, function (org) {
          return org.organization.orgId !== tempCurrentOrg.id;
        });

        if (otherOrgs) {
          const otherFormatedOrgs = otherOrgs.map((org) => {
            return getInstitute(org);
          });

          if (otherFormatedOrgs) {
            if (userOrgs.length !== userInfo.organizations.length) {
              setUserOrgs((prevState) => [...prevState, ...otherFormatedOrgs]);
            }
            setOtherUserOrgs(otherFormatedOrgs);
          }
        }
      }
    }

    if (userInfo && userInfo.id && !isRedirectToSearch) {
      dispatch(updateSendSearchRequest(sendSearchRequest + 1));
      dispatch(updateIsRedirectToSearch(true));
    }
  }, [userInfo]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isdropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsdropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isdropdownOpen]);

  const instituteClickHandler = (event) => {
    const tempCurrentOrg = _.find(userOrgs, function (org) {
      return org.id === event.currentTarget.name;
    });
    const tempOtherOrgs = _.filter(userOrgs, function (org) {
      return org.id !== event.currentTarget.name;
    });
    if (props.cPage === PAGES.SERP) {
      const tempOrgClassification = getOrgClassification(tempCurrentOrg);
      if (
        tempOrgClassification === ORG_CLASSIFICATION.HIGHER_ED &&
        orgClassification !== ORG_CLASSIFICATION.HIGHER_ED
      ) {
        dispatch(resetSelectedSort());
      } else if (tempOrgClassification !== ORG_CLASSIFICATION.HIGHER_ED) {
        dispatch(
          updateSelectedSort({
            type: SERP_SORT.PUBLICATION_DATE.type,
            displayValue: SERP_SORT.PUBLICATION_DATE.displayValue,
          })
        );
      }
    }
    setCurrentUserOrg(tempCurrentOrg);
    setOtherUserOrgs([...tempOtherOrgs]);
    setUserOrgs([tempCurrentOrg, ...tempOtherOrgs]);
    dispatch(changeCurrentInstitute(tempCurrentOrg.id));

    if (props.cPage === PAGES.PDP) {
      dispatch(resetProductOfferData());
      const params = new URLSearchParams(window.location.search);
      const uuid = params.get("uuid");
      dispatch(
        productOffersAsync({
          productId: uuid,
          orgId: tempCurrentOrg.id,
          location: tempCurrentOrg.country,
          lmsIntegrationToken: queryParams.has("integration_context_token")
            ? queryParams.get("integration_context_token")
            : "",
        })
      );

      if (
        userInfo &&
        productData &&
        productData.k12 &&
        productData.k12 === K12_TYPE.AVAILABLE_FOR_LICENSE &&
        productAvailableToBuyOffers &&
        orgClassification !== ORG_CLASSIFICATION.HIGHER_ED
      ) {
        dispatch(
          productAvailableToBuyOffersAsync({
            productId: productData.productId,
          })
        );
      }
    }

    if (props.cPage === PAGES.SERP) {
      dispatch(updateCurrentResultPage(1));
      dispatch(changeIsInstituteUpdated(true));
      dispatch(resetDisciplineCount());
      dispatch(resetK12ItemsCount());
      dispatch(updateSendSearchRequest(sendSearchRequest + 1));
    }
  };

  const displayNotification = () => {
    dispatch(updateRefreshInstitution(true));
  };

  const isNoInstitutionProfile = useSelector(
    (state) => state.user.isNoInstitutionProfile
  );
  const isVerifiedAccount = useSelector(
    (state) => state.user.isVerifiedAccount
  );

  return (
    <React.Fragment>
      {userInfo &&
        !userInfo.lmsOrg &&
        userOrgs &&
        userOrgs.length > 0 &&
        currentUserOrg &&
        currentUserOrg.displayName && (
          <div
            className={
              !isVerifiedAccount || isNoInstitutionProfile
                ? "institute-wrap d-flex flex-row align-items-center institute-wrap-alert"
                : "institute-wrap d-flex flex-row align-items-center"
            }>
            <Dropdown
              show={isdropdownOpen}
              onToggle={(isOpen) => setIsdropdownOpen(isOpen)}
              ref={ref}>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="d-flex flex-row align-items-center">
                <span className="institute-img" aria-hidden="true">
                  <InstituteIcon />
                </span>
                <span className="institute-text">
                  {currentUserOrg.displayName} - {currentUserOrg.city},{" "}
                  {currentUserOrg.state}, {currentUserOrg.country}
                </span>
                <InstituteArrow />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="mobile-institutepicker">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      aria-label="Close institute picklist"
                      onClick={() => {
                        setIsdropdownOpen(false);
                      }}>
                      <SearchCloseIcon />
                    </button>
                  </div>

                  <p>Select institution</p>
                </div>

                <div className="selected-institute">
                  {currentUserOrg.displayNameDropdown} - {currentUserOrg.city},{" "}
                  {currentUserOrg.state}, {currentUserOrg.country}
                </div>
                {otherUserOrgs &&
                  otherUserOrgs.length > 0 &&
                  otherUserOrgs.map((otherOrg, index) => (
                    <Dropdown.Item
                      href="javascript:void(0)"
                      key={otherOrg.id}
                      name={otherOrg.id}
                      onClick={(event) => instituteClickHandler(event)}>
                      {otherOrg.displayNameDropdown} - {otherOrg.city},{" "}
                      {otherOrg.state}, {otherOrg.country}
                    </Dropdown.Item>
                  ))}
                <div className="add-institution">
                  <a
                    href={`${
                      config.CONSOLE_URL
                    }/account/manage/account?type=${encodeURIComponent(
                      "Return to Search"
                    )}&returnUrl=${encodeURIComponent(
                      decodeURIComponent(window.location.href)
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => displayNotification()}
                    aria-describedby="addIns">
                    {" "}
                    Add an institution{" "}
                    <span className="sr-only" id="addIns">
                      Opens in a new tab
                    </span>
                  </a>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <div id="tooltip-wr">
              <BtnToolTip institutePicker />
            </div>
          </div>
        )}
      {userInfo &&
        userInfo.lmsOrg &&
        currentUserOrg &&
        currentUserOrg.displayName && (
          <div className="lms-flow-wr">
            <div className="d-flex flex-row align-items-center lms-flow ml-3">
              <span className="institute-img" aria-hidden="true">
                <InstituteIcon />
              </span>
              <span className="institute-text">
                {currentUserOrg.displayName} - {currentUserOrg.city},{" "}
                {currentUserOrg.state}, {currentUserOrg.country}
              </span>
            </div>
          </div>
        )}
    </React.Fragment>
  );
};
