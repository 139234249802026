export const clearPendoFromLocalStorage = () => {
    const localStorage = window?.localStorage;
    if (!localStorage) {
        return;
    }

    const localStorageKeys = Object.keys(localStorage) ?? [];

    if (!localStorageKeys.length) {
        return;
    }

    localStorageKeys.forEach((localStorageKey) => {
       if (localStorageKey.includes('pendo')) {
          localStorage?.removeItem(localStorageKey);
       }
    })
}