import React, { useState, useEffect } from "react";
import "./relatedProduct.scss";
import {
  getProductImage,
  getProductBrand,
} from "../../util/productUtil";
import defaultProductImg from "../../images/empty-default-img.png";
import { PLATFORMS, PRODUCT_MODELS } from "../../../config";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetProductData } from "../../pages/pdp/productSlice";
import _ from "lodash";

export const RelatedProducts = (props) => {
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.product.productData);
  const [relatedLearningApp, setRelatedLearningApp] = useState([]);
  const [nonRelatedLearningApp, setNonRelatedLearningApp] = useState([]);

  useEffect(() => {
    setRelatedLearningApp((prevState) => []);
    setNonRelatedLearningApp((prevState) => []);

    if (props.relatedProducts.length > 0) {
      const tempRelatedProducts = props.relatedProducts.slice(0, 4);
      const tempRelatedLearningApp = _.filter(
        tempRelatedProducts,
        function (relatedProduct) {
          return (
            relatedProduct.productPlatform === PLATFORMS.LEARNING_APPLICATIONS
          );
        }
      );
      const tempNonRelatedLearningApp = _.filter(
        tempRelatedProducts,
        function (relatedProduct) {
          return (
            relatedProduct.productPlatform !== PLATFORMS.LEARNING_APPLICATIONS
          );
        }
      );

      if (tempRelatedLearningApp && tempRelatedLearningApp.length > 0) {
        setRelatedLearningApp((prevState) => [...tempRelatedLearningApp]);
      }

      if (tempNonRelatedLearningApp && tempNonRelatedLearningApp.length > 0) {
        setNonRelatedLearningApp((prevState) => [...tempNonRelatedLearningApp]);
      }
    }
  }, [props.relatedProducts]);

  const lerningAppsClassName = () => {
    let classNameLearningApps = "relProduct-image ";
    if (props.relatedProducts.productPlatform === "LEARNING_APPLICATIONS") {
      classNameLearningApps += " pdp-learning-apps";
    }
    return classNameLearningApps;
  };

  const getPlatforms = (product) => {
    if (product.productPlatform === PLATFORMS.REVEL) {
      return "Revel";
    } else if (product.productPlatform === PLATFORMS.ETEXT) {
      return "eText";
    } else if (product.productPlatform === PLATFORMS.COLLECTION) {
      return "Collections";
    } else if (product.productPlatform === PLATFORMS.LEARNING_APPLICATIONS) {
      return "Learning Applications";
    } else if (product.productPlatform === PLATFORMS.PRINT) {
      return "Loose-leaf";
    } else if (
      product.productPlatform === PLATFORMS.GLP &&
      (product.productModel.name === PRODUCT_MODELS.REVEL_CITE ||
        product.productModel.name === PRODUCT_MODELS.SUMATRA_CITE)
    ) {
      return "Revel";
    } else if (
      product.productPlatform === PLATFORMS.GLP &&
      product.productModel.name === PRODUCT_MODELS.ETEXT2_CITE
    ) {
      return "eText";
    }
  };

  const getProductLink = (product) => {
    return `/products/${product.productId}/${product.slug}?uuid=${product.productId}`;
  };

  const setProductDataToSessionStorage = (event) => {
    if (
      event.target.getAttribute("data-platform") ===
      PLATFORMS.LEARNING_APPLICATIONS
    ) {
      let tempProductData = [];
      tempProductData.push(productData);
      window.sessionStorage.setItem(
        "exchange.product.baseRelated",
        JSON.stringify(tempProductData)
      );
    } else {
      window.sessionStorage.removeItem("exchange.product.baseRelated");
    }
    dispatch(resetProductData());
  };

  const getRelatedProducts = (relatedProducts, isLearningApp) => {
    return (
      <div className="pdp-rightside-wr">
        {!isLearningApp && (
          <h3 className="related-product-title">Alternate formats</h3>
        )}
        {relatedProducts.slice(0, 4).map((relProduct) => (
          <div className="d-flex flex-row relProduct-container">
            <div className={lerningAppsClassName()}>
              <img
                src={
                  getProductImage(relProduct)
                    ? getProductImage(relProduct)
                    : defaultProductImg
                }
                alt=""
                aria-hidden="true"
                onError={(event) => (event.target.src = defaultProductImg)}
              />
            </div>
            <div className="d-flex flex-column relProduct-info">
              <p className="relProduct-title mb-0">
                {getPlatforms(relProduct)}
              </p>
              <p className="relProduct-name mb-0">
                {relProduct.productPlatform === PLATFORMS.PRINT ? (
                  relProduct.name
                ) : (
                  <Link
                    data-platform={relProduct.productPlatform}
                    to={getProductLink(relProduct)}
                    onClick={(event) => setProductDataToSessionStorage(event)}
                  >
                    {relProduct.name}
                  </Link>
                )}
              </p>
              {relProduct.sourceIsbn && relProduct.sourceIsbn.isbn13 && (
                <p className="relProduct-isbn mb-0">
                  <span>ISBN-13: </span>
                  {relProduct.sourceIsbn.isbn13}
                </p>
              )}
              {relProduct.brand &&
                relProduct.brand.name &&
                relProduct.brand.name.length > 0 && (
                   <div className="brand d-flex flex-row">
                  {getProductBrand(relProduct)}
                 </div>
                )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      {nonRelatedLearningApp.length > 0 &&
        getRelatedProducts(nonRelatedLearningApp, false)}
      {relatedLearningApp.length > 0 &&
        getRelatedProducts(relatedLearningApp, true)}
    </React.Fragment>
  );
};
