import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InstitutesList } from "../../widgets/institutePickList/InstitutesList";
import { SrpPagination } from "../../widgets/srpPagination/SrpPagination";
import defaultProductImg from "../../images/empty-default-img.png";
import { ReactComponent as PurchaseIcon } from "../../images/purchase-icon.svg";
import "./serp.scss";
import { useLocation, Link, useHistory } from "react-router-dom";
import {
  SEARCH_PAGE_RESULT_COUNT,
  PLATFORMS,
  PAGES,
  PRODUCT_TYPES,
  SERP_PLATFORMS,
  ORG_CLASSIFICATION,
  OFFER_TYPES,
} from "../../../config";
import {
  getSrpProductImage,
  getComingSoonOverlayer,
} from "../../util/productUtil";
import { resetSearch } from "../../widgets/search/searchSlice";
import { reinitializedSearchResult } from "./searchResultSlice";
import { updatePreSelectedOrganization } from "../exHeader/userSlice";
import ReactGA from "react-ga4";
import { ReactComponent as ListIcon } from "../../images/list-icon.svg";
import { ReactComponent as ListDisabledIcon } from "../../images/list-disable-icon.svg";
import { ReactComponent as GridIcon } from "../../images/grid-icon.svg";
import { ReactComponent as GridDisabledIcon } from "../../images/grid-disable-icon.svg";
import { ReactComponent as FilterByArrowIcon } from "../../images/filter-by-arrow.svg";
import { ReactComponent as SeatLicenseIcon } from "../../images/k12-seat-license.svg";

export function SearchResult(props) {
  const noMatchesRef = useRef(null);
  const dispatch = useDispatch();
  const searchResult = useSelector((state) => state.searchResult.searchResult);
  const k12SearchResult = useSelector(
    (state) => state.searchResult.k12SearchResult
  );
  const k12ItemsCount = useSelector(
    (state) => state.searchResult.k12ItemsCount
  );
  const userData = useSelector((state) => state.user.userData);
  const isK12ResultReceived = useSelector(
    (state) => state.searchResult.isK12ResultReceived
  );
  const isHigheredResultReceived = useSelector(
    (state) => state.searchResult.isHigheredResultReceived
  );
  const selectedPlatform = useSelector(
    (state) => state.platform.selectedPlatform
  );
  const [allSearchResult, setAllSearchResult] = useState(null);
  const currentResultPage = useSelector(
    (state) => state.searchResult.currentResultPage
  );
  const selectedSubjectDisplayName = useSelector(
    (state) => state.subject.selectedSubjectDisplayName
  );
  const orgClassification = useSelector(
    (state) => state.user.orgClassification
  );
  const isInternalError = useSelector(
    (state) => state.searchResult.isInternalError
  );
  const isSubjectCoutInternalError = useSelector(
    (state) => state.subject.isSubjectCoutInternalError
  );
  const isDisciplineInternalError = useSelector(
    (state) => state.discipline.isDisciplineInternalError
  );
  const isProductInternalError = useSelector(
    (state) => state.product.isProductInternalError
  );
  const isOfferInternalError = useSelector(
    (state) => state.product.isOfferInternalError
  );
  const isProductDisciplineInternalError = useSelector(
    (state) => state.product.isProductDisciplineInternalError
  );
  const currentInstitute = useSelector((state) => state.user.currentInstitute);
  let queryParams = new URLSearchParams(useLocation().search);
  let history = useHistory();
  const [view, setView] = useState("LIST");

  const handleResize = () => {
    if (window.innerWidth < 992) {
      window.sessionStorage.setItem("exchange.search.layout", "LIST");
      setView("LIST");
    }
  };

  const isSeatLicenseActivated = (offer) => {
    if (queryParams.has("integration_context_token")) {
      return (
        offer.source["cnt:auxiliaryFields"].offerType.offerTypeCode ===
          OFFER_TYPES.SEAT_LICENSE &&
        currentInstitute.id === offer.source["schema:orgId"]
      );
    } else {
      return (
        offer.source["cnt:auxiliaryFields"].offerType.offerTypeCode ===
          OFFER_TYPES.SEAT_LICENSE &&
        currentInstitute.organization.orgId === offer.source["schema:orgId"]
      );
    }
  };

  const reOrderK12Products = (products) => {
    let k12ProductsWithSeatLicence = [];
    let k12ProductsWithOutSeatLicence = [];
    products.forEach(findK12SeatLicenceProduct);

    function findK12SeatLicenceProduct(product) {
      if (
        product.innerHits &&
        product.innerHits.searchResults &&
        product.innerHits.searchResults.find(isSeatLicenseActivated)
      ) {
        k12ProductsWithSeatLicence.push(product);
      } else {
        k12ProductsWithOutSeatLicence.push(product);
      }
    }
    return [...k12ProductsWithSeatLicence, ...k12ProductsWithOutSeatLicence];
  };

  useEffect(() => {
    if (window.innerWidth > 992) {
      if (!window.sessionStorage.getItem("exchange.search.layout")) {
        window.sessionStorage.setItem("exchange.search.layout", "LIST");
      }
      setView(window.sessionStorage.getItem("exchange.search.layout"));
    } else {
      window.sessionStorage.setItem("exchange.search.layout", "GRID");
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if(searchResult !== null) {
      if (
        (orgClassification !== ORG_CLASSIFICATION.HIGHER_ED &&
          (!selectedPlatform || selectedPlatform === SERP_PLATFORMS.REVEL.type) &&
          currentResultPage === 1 &&
          isHigheredResultReceived &&
          isK12ResultReceived) ||
        (currentResultPage === 1 &&
          history.action === "POP" &&
          searchResult &&
          searchResult.searchResults &&
          k12SearchResult &&
          k12SearchResult.searchResults) && searchResult.count !== null
      ) {
        setAllSearchResult({
          count: searchResult.count + k12SearchResult.count,
          items: [
            ...reOrderK12Products(k12SearchResult.searchResults),
            ...searchResult.searchResults,
          ],
        });
      } else if (
        (isHigheredResultReceived &&
          searchResult &&
          searchResult.searchResults) ||
        (history.action === "POP" && searchResult && searchResult.searchResults) && searchResult.count !== 0
      ) {
        setAllSearchResult({
          count: searchResult.count,
          items: [...searchResult.searchResults],
        });
      } else if (
        !isHigheredResultReceived &&
        !isK12ResultReceived &&
        searchResult &&
        searchResult.searchResults &&
        searchResult.searchResults.length === 0 &&
        k12SearchResult &&
        k12SearchResult.searchResults &&
        k12SearchResult.searchResults.length === 0
      ) {
        setAllSearchResult({
          count: 0,
          items: [],
        });
      } else if (
        searchResult &&
        searchResult.items &&
        searchResult.items.length === 0 &&
        k12SearchResult &&
        k12SearchResult.items &&
        k12SearchResult.items.length === 0
      ) {
        setAllSearchResult({
          count: 0,
          items: [],
        });
      } else if (searchResult === null && k12SearchResult === null) {
        setAllSearchResult(null);
      }
    }
    
  }, [isHigheredResultReceived, isK12ResultReceived]);

  useEffect(() => {
    setTimeout(() => {
      if(noMatchesRef.current !== null) {
        noMatchesRef.current.setAttribute("tabIndex", "-1");
        noMatchesRef.current.focus();
        noMatchesRef.current.removeAttribute("tabIndex");
      }
    }, 1200);
  }, []);


  const getAuthorsNameList = (authors) => {
    let authorList = [];
    authors.map((author) => {
      const givenName = author.givenName ? author.givenName : "";
      const familyName = author.familyName ? author.familyName : "";
      const authorName = givenName + " " + familyName;
      if (authorName.length > 1) {
        authorList.push(authorName);
      }
      return authorName;
    });
    const authorNamesubstring = authorList.join(", ");
    if (authorNamesubstring.length > 45 && view === "GRID") {
      return authorNamesubstring.substring(authorNamesubstring, 45) + "....";
    } else {
      return authorNamesubstring;
    }
  };

  const getProductDescription = (product) => {
    const listDescription =
      product.source["schema:description"] &&
      product.source["schema:description"]["schema:txt"]
        ? product.source["schema:description"]["schema:txt"]
        : "";
    if (listDescription.length > 145) {
      return listDescription.substring(listDescription, 145) + "....";
    }
    return listDescription;
  };

  const getProductBrand = (product) => {
    const revelBrand = "TM";
    const productBrand =
      product.source["schema:brand"] &&
      product.source["schema:brand"]["schema:name"]
        ? product.source["schema:brand"]["schema:name"]
        : "";
    if (productBrand && productBrand.toUpperCase() === "REVEL") {
      return (
        <p className="brand-name mb-0">
          Platform:{" "}
          <span>
            {" "}
            {productBrand} <sup> {revelBrand} </sup>
          </span>
        </p>
      );
    } else if (productBrand && productBrand.length > 0) {
      return (
        <p className="brand-name mb-0">
          Platform: <span>{productBrand}</span>
        </p>
      );
    }
  };

  const getProductImageSerp = (product) => {
    if (product) {
      let classNameLearningApps = "";
      if (
        product.source["cnt:auxiliaryFields"].productPlatform ===
        PLATFORMS.LEARNING_APPLICATIONS
      ) {
        classNameLearningApps += " pdp-learning-apps";
      }
      return classNameLearningApps;
    } else {
      return "";
    }
  };
  const getProductOverlayerSerp = (product) => {
    if (product) {
      let classNameLearningAppsOverlayer = "";
      if (
        product.source["cnt:auxiliaryFields"].productPlatform ===
        PLATFORMS.LEARNING_APPLICATIONS
      ) {
        classNameLearningAppsOverlayer += " pdp-learning-apps-overlayer";
      }
      return classNameLearningAppsOverlayer;
    } else {
      return "";
    }
  };

  const getSearchResultCountText = () => {
    if (allSearchResult) {
      if (allSearchResult.count <= SEARCH_PAGE_RESULT_COUNT) {
        return `Showing ${allSearchResult.count} of ${allSearchResult.count} Results`;
      } else {
        if (currentResultPage === 1) {
          return `Showing 1 - ${allSearchResult.items.length} of ${allSearchResult.count} Results`;
        } else {
          return `Showing ${
            currentResultPage * SEARCH_PAGE_RESULT_COUNT -
            (SEARCH_PAGE_RESULT_COUNT - 1) +
            k12ItemsCount
          } - ${
            (currentResultPage - 1) * SEARCH_PAGE_RESULT_COUNT +
            allSearchResult.items.length +
            k12ItemsCount
          } of ${allSearchResult.count + k12ItemsCount} Results`;
        }
      }
    }
  };

  const getProductLink = (product) => {
    let pdpUrl;
    if (queryParams.has("q")) {
      queryParams.delete("q");
    }
    if (queryParams.has("integration_context_token")) {
      pdpUrl = `/products/${product.productId}/${
        product.source["cnt:slug"] ? product.source["cnt:slug"] : ""
      }?uuid=${product.productId}&integration_context_token=${queryParams.get(
        "integration_context_token"
      )}`;
    } else if (queryParams.has("ref") && queryParams.has("returnurl")) {
      pdpUrl = `/products/${product.productId}/${
        product.source["cnt:slug"] ? product.source["cnt:slug"] : ""
      }?uuid=${product.productId}&ref=${queryParams.get(
        "ref"
      )}&returnurl=${encodeURIComponent(queryParams.get("returnurl"))}`;
    } else if (queryParams.has("ref")) {
      pdpUrl = `/products/${product.productId}/${
        product.source["cnt:slug"] ? product.source["cnt:slug"] : ""
      }?uuid=${product.productId}&ref=${queryParams.get("ref")}`;
    } else {
      pdpUrl = `/products/${product.productId}/${
        product.source["cnt:slug"] ? product.source["cnt:slug"] : ""
      }?uuid=${product.productId}`;
    }

    if (
      queryParams.has("organizationId") &&
      queryParams.get("organizationId").length > 0
    ) {
      return `${pdpUrl}&organizationId=${queryParams.get("organizationId")}`;
    } else {
      return pdpUrl;
    }
  };

  const getSearchResultValue = () => {
    if (queryParams.has("q")) {
      return decodeURIComponent(queryParams.get("q"));
    } else if (
      selectedSubjectDisplayName &&
      selectedSubjectDisplayName.length > 0
    ) {
      return selectedSubjectDisplayName;
    } else {
      return "";
    }
  };

  const viewBtnClickHandler = (selectedView) => {
    setView(selectedView);
    window.sessionStorage.setItem("exchange.search.layout", selectedView);
  };

  const redirectToHome = () => {
    if (queryParams.has("uuid")) {
      queryParams.delete("uuid");
    }
    if (queryParams.has("q")) {
      queryParams.delete("q");
    }
    if (queryParams.has("disc")) {
      queryParams.delete("disc");
    }
    if (queryParams.has("cat")) {
      queryParams.delete("cat");
    }

    dispatch(resetSearch());
    dispatch(reinitializedSearchResult());
    setAllSearchResult(null);
    history.push(`/?${queryParams.toString()}`);
  };

  const productClickHandler = (event) => {
    dispatch(updatePreSelectedOrganization(currentInstitute));
    ReactGA.event({
      category: "search results view",
      action: "click on product on pdp page",
      label: event.target.getAttribute("data-product-id"),
    });
  };

  return (
    <div className="serp-wrap">
      <div className="d-flex search-institute-wrap">
        <InstitutesList cPage={PAGES.SERP} />
      </div>
      {allSearchResult &&
        allSearchResult.items &&
        allSearchResult.items.length > 0 && (
          <React.Fragment>
            <div className="serp-content">
              <div className="search-result-view">
                <h1 className="my-0">
                  Search results for{" "}
                  <span className="searched-txt">{`”${getSearchResultValue().replace(
                    /^"|"$/g,
                    ""
                  )}”`}</span>
                </h1>
              </div>
              <div className="list-grid-icons-wr">
                <ul className="list-grid-icons d-flex flex-row" role="tablist">
                  <li className="list-icon" role="presentation">
                    {view === "LIST" ? (
                      <button
                        id="list"
                        aria-label="list"
                        aria-describedby="searchResult"
                        onClick={() => viewBtnClickHandler("LIST")}
                        className="view-text"
                        role="tab"
                        aria-controls="list-content"
                        aria-selected="true">
                        <ListIcon />
                      </button>
                    ) : (
                      <button
                        aria-label="list"
                        onClick={() => viewBtnClickHandler("LIST")}
                        className="view-text"
                        role="tab"
                        aria-controls="list-content"
                        aria-selected="false">
                        <ListDisabledIcon />
                      </button>
                    )}
                  </li>
                  <li className="grid-icon" role="presentation">
                    {view === "GRID" ? (
                      <button
                        id="grid"
                        aria-label="grid"
                        aria-describedby="searchResult"
                        onClick={() => viewBtnClickHandler("GRID")}
                        className="view-text"
                        role="tab"
                        aria-controls="grid-content"
                        aria-selected="true">
                        <GridIcon />
                      </button>
                    ) : (
                      <button
                        aria-label="grid"
                        onClick={() => viewBtnClickHandler("GRID")}
                        className="view-text"
                        role="tab"
                        aria-controls="grid-content"
                        aria-selected="false">
                        <GridDisabledIcon />
                      </button>
                    )}
                  </li>
                </ul>
              </div>
              <button
                className="filter-by-wr"
                aria-label="filter by"
                onClick={(event) => props.filterByClickHandler(true, event)}>
                Filter by
                <FilterByArrowIcon />
              </button>
            </div>
            <div
              className="show-results"
              role="status"
              aria-live="polite"
              id="searchResult">
              {getSearchResultCountText()}
            </div>
          </React.Fragment>
        )}

      {allSearchResult &&
        allSearchResult.items &&
        allSearchResult.items.length === 0 && (
          <React.Fragment>
            <button
              className="filter-by-wr no-srp-result"
              aria-label="filter by"
              onClick={(event) => props.filterByClickHandler(true, event)}>
              Filter by
              <FilterByArrowIcon />
            </button>
            <div>
              <h1 id="no-matches" className="my-0" role="alert" aria-live="assertive" ref={noMatchesRef}>
                {getSearchResultValue().replace(/^"|"$/g, "").length > 0 && (
                  <React.Fragment>
                    No results for{" "}
                    <span className="searched-txt">{`”${getSearchResultValue().replace(
                      /^"|"$/g,
                      ""
                    )}”`}</span>
                  </React.Fragment>
                )}
                {getSearchResultValue().replace(/^"|"$/g, "").length === 0 && (
                  <React.Fragment>
                    Your search did not match any documents.
                  </React.Fragment>
                )}
              </h1>
              <div className="no-search-result-wr">
                <h6>Suggestions:</h6>
                <ul>
                  <li>Make sure all words are spelled correctly.</li>
                  <li>Try different keywords.</li>
                  <li>Try more general keywords.</li>
                </ul>
                <button
                  onClick={redirectToHome}
                  className="custom-btn secondary-btn no-result-btn">
                  Back to the homepage
                </button>
              </div>
            </div>
          </React.Fragment>
        )}

      {(isInternalError ||
        isSubjectCoutInternalError ||
        isDisciplineInternalError ||
        isProductInternalError ||
        isOfferInternalError ||
        isProductDisciplineInternalError) && (
        <div className="serp-content">
          <div className="search-result-view">
            <h1 className="my-0" role="alert" aria-live="assertive">
              Search results for{" "}
              <span className="searched-txt">{`”${getSearchResultValue().replace(
                /^"|"$/g,
                ""
              )}”`}</span>
            </h1>
          </div>

          <button
            className="filter-by-wr"
            aria-label="filter by"
            onClick={(event) => props.filterByClickHandler(true, event)}>
            Filter by
            <FilterByArrowIcon />
          </button>
        </div>
      )}

      <React.Fragment>
        <ul
          className="list-view"
          role="tabpanel"
          aria-labelledby="list"
          id="list-content">
          {view === "LIST" &&
            allSearchResult &&
            allSearchResult.items &&
            allSearchResult.items.map((item, index) => (
              <li key={index}>
                <div className="row flex-row serp-products">
                  <div
                    className={`praduct-wrap col-lg-2 col-xl-3 ${getProductImageSerp(
                      item
                    )}`}>
                    <Link
                      to={getProductLink(item)}
                      tabIndex="-1"
                      data-product-id={item.productId}
                      onClick={(event) => productClickHandler(event)}
                      aria-hidden="true">
                      <img
                        src={
                          getSrpProductImage(item)
                            ? getSrpProductImage(item)
                            : defaultProductImg
                        }
                        alt=""
                        aria-hidden="true"
                        onError={(event) =>
                          (event.target.src = defaultProductImg)
                        }
                      />

                      {item.source["cnt:productType"] !==
                        PRODUCT_TYPES.SAMPLE &&
                        item.source["cnt:productType"] !== PRODUCT_TYPES.BETA &&
                        getComingSoonOverlayer(item, PAGES.SERP) && (
                          <div
                            className="overlay-badge serp-list-overlayer"
                            aria-hidden="true"
                            id={`overlay_${index}`}>
                            <span className="overlay-text text-center">
                              Coming
                              <br />
                              soon
                            </span>
                          </div>
                        )}
                      {item.source["cnt:productType"] ===
                        PRODUCT_TYPES.SAMPLE && (
                        <div
                          className="overlay-badge serp-list-overlayer"
                          aria-hidden="true"
                          id={`overlay_${index}`}>
                          <span className="overlay-text sample text-center">
                            Sample
                            <br />
                            <span className="sub-text">Not for sale</span>
                          </span>
                        </div>
                      )}
                      {item.source["cnt:productType"] ===
                        PRODUCT_TYPES.BETA && (
                        <div
                          className="overlay-badge serp-list-overlayer"
                          aria-hidden="true"
                          id={`overlay_${index}`}>
                          <span className="overlay-text text-center class-test">
                            Class test
                          </span>
                        </div>
                      )}
                    </Link>
                  </div>
                  <div className="serp-product-infor col-lg-9 col-xl-9 list-view-info">
                    <div className="product-title-serp list-title">
                      {item.source["cnt:productType"] === PRODUCT_TYPES.BETA ||
                      item.source["cnt:productType"] === PRODUCT_TYPES.SAMPLE ||
                      (item.source["cnt:productType"] !==
                        PRODUCT_TYPES.SAMPLE &&
                        item.source["cnt:productType"] !== PRODUCT_TYPES.BETA &&
                        getComingSoonOverlayer(item, PAGES.SERP)) ? (
                        <Link
                          to={getProductLink(item)}
                          data-product-id={item.productId}
                          onClick={(event) => productClickHandler(event)}
                          aria-describedby={`overlay_${index}`}>
                          {item.source["schema:name"]}
                        </Link>
                      ) : (
                        <Link
                          to={getProductLink(item)}
                          data-product-id={item.productId}
                          onClick={(event) => productClickHandler(event)}>
                          {item.source["schema:name"]}
                        </Link>
                      )}
                    </div>
                    {item.source[
                      "cnt:auxiliaryFields"
                    ].productPlatform.toLowerCase() !== "collection" && (
                      <div className="product-ed">
                        {item.source["schema:bookEdition"] &&
                          item.source["cnt:auxiliaryFields"].productPlatform !==
                            PLATFORMS.LEARNING_APPLICATIONS && (
                            <p className="my-0">{`${item.source["schema:bookEdition"]}e`}</p>
                          )}
                      </div>
                    )}
                    <div className="product-authors">
                      <p className="my-0">
                        {item.source["cnt:auxiliaryFields"].authors &&
                          getAuthorsNameList(
                            item.source["cnt:auxiliaryFields"].authors
                          )}
                      </p>
                    </div>
                    <div className="product-description">
                      <p className="my-0">{getProductDescription(item)}</p>
                    </div>

                    <div className="d-flex k12-product">
                      <div className="d-flex flex-column">
                        <div className="product-isbn">
                          {item.source["schema:sourceIsbn"] &&
                            item.source["schema:sourceIsbn"]["schema:isbn13"] &&
                            item.source["schema:sourceIsbn"]["schema:isbn13"]
                              .length > 0 && (
                              <p className="my-0">
                                <strong>{`ISBN-13: `}</strong>
                                {`${item.source["schema:sourceIsbn"]["schema:isbn13"]}`}
                              </p>
                            )}
                        </div>
                        {item.source["schema:brand"] &&
                          item.source["schema:brand"]["schema:name"] &&
                          item.source["schema:brand"]["schema:name"].length >
                            0 && (
                            <div className="brand d-flex flex-row">
                              {getProductBrand(item)}
                            </div>
                          )}
                      </div>
                      {orgClassification !== ORG_CLASSIFICATION.HIGHER_ED &&
                        item.innerHits &&
                        item.innerHits.searchResults &&
                        item.innerHits.searchResults.find(
                          isSeatLicenseActivated
                        ) && (
                          <div className="k12label-wr d-flex align-items-center">
                            <SeatLicenseIcon />
                            <span>K-12 license activated</span>
                          </div>
                        )}
                      {userData &&
                        (orgClassification === ORG_CLASSIFICATION.ANONYMOUS ||
                          orgClassification === ORG_CLASSIFICATION.SCHOOL) &&
                        !(
                          item.innerHits &&
                          item.innerHits.searchResults &&
                          item.innerHits.searchResults.find(
                            isSeatLicenseActivated
                          )
                        ) &&
                        item.source["cnt:auxiliaryFields"] &&
                        item.source["cnt:auxiliaryFields"].k12 &&
                        item.source["cnt:auxiliaryFields"].k12 ===
                          "availableForLicense" && (
                          <div className="k12label-wr purchase-wr d-flex align-items-center">
                            <PurchaseIcon />
                            <span>K-12 purchase options</span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        <div
          className="grid-view row"
          role="tabpanel"
          aria-labelledby="grid"
          id="grid-content">
          {view === "GRID" &&
            allSearchResult &&
            allSearchResult.items &&
            allSearchResult.items.map((item, index) => (
              <div
                className="col-lg-2 col-xl-3 serp-products custom-grid-view"
                key={`overlay_${index}`}>
                <div className={`praduct-wrap ${getProductImageSerp(item)}`}>
                  <Link
                    to={getProductLink(item)}
                    tabIndex="-1"
                    data-product-id={item.productId}
                    onClick={(event) => productClickHandler(event)}
                    aria-hidden="true">
                    <img
                      src={
                        getSrpProductImage(item)
                          ? getSrpProductImage(item)
                          : defaultProductImg
                      }
                      alt=""
                      aria-hidden="true"
                      className="d-flex align-items-end"
                      onError={(event) =>
                        (event.target.src = defaultProductImg)
                      }
                    />

                    {item.source["cnt:productType"] !== PRODUCT_TYPES.SAMPLE &&
                      item.source["cnt:productType"] !== PRODUCT_TYPES.BETA &&
                      getComingSoonOverlayer(item, PAGES.SERP) && (
                        <div class="overlay-cbadge-wrapper">
                          <div
                            className={`overlay-badge ${getProductOverlayerSerp(
                              item
                            )}`}
                            id={`overlay_${index}`}
                            aria-hidden="true">
                            <span className="overlay-text text-center">
                              Coming
                              <br />
                              soon
                            </span>
                          </div>
                        </div>
                      )}
                    {item.source["cnt:productType"] ===
                      PRODUCT_TYPES.SAMPLE && (
                      <div class="overlay-cbadge-wrapper">
                        <div
                          className={`overlay-badge ${getProductOverlayerSerp(
                            item
                          )}`}
                          id={`overlay_${index}`}
                          aria-hidden="true">
                          <span className="overlay-text sample text-center">
                            Sample
                            <br />
                            <span className="sub-text">Not for sale</span>
                          </span>
                        </div>
                      </div>
                    )}
                    {item.source["cnt:productType"] === PRODUCT_TYPES.BETA && (
                      <div class="overlay-cbadge-wrapper">
                        <div
                          className={`overlay-badge ${getProductOverlayerSerp(
                            item
                          )}`}
                          id={`overlay_${index}`}
                          aria-hidden="true">
                          <span className="overlay-text text-center class-test">
                            Class test
                          </span>
                        </div>
                      </div>
                    )}
                  </Link>
                </div>
                <div className="serp-product-infor">
                  <div className="product-title-serp title-grid-view">
                    {item.source["cnt:productType"] === PRODUCT_TYPES.BETA ||
                    item.source["cnt:productType"] === PRODUCT_TYPES.SAMPLE ||
                    (item.source["cnt:productType"] !== PRODUCT_TYPES.SAMPLE &&
                      item.source["cnt:productType"] !== PRODUCT_TYPES.BETA &&
                      getComingSoonOverlayer(item, PAGES.SERP)) ? (
                      <Link
                        to={getProductLink(item)}
                        data-product-id={item.productId}
                        onClick={(event) => productClickHandler(event)}
                        aria-describedby={`overlay_${index}`}>
                        {item.source["schema:name"]}
                      </Link>
                    ) : (
                      <Link
                        to={getProductLink(item)}
                        data-product-id={item.productId}
                        onClick={(event) => productClickHandler(event)}>
                        {item.source["schema:name"]}
                      </Link>
                    )}
                  </div>
                  <div className="product-infor-inner">
                    {item.source[
                      "cnt:auxiliaryFields"
                    ].productPlatform.toLowerCase() !== "collection" && (
                      <div className="product-ed">
                        {item.source["schema:bookEdition"] &&
                          item.source["cnt:auxiliaryFields"].productPlatform !==
                            PLATFORMS.LEARNING_APPLICATIONS && (
                            <p className="my-0">{`${item.source["schema:bookEdition"]}e`}</p>
                          )}
                      </div>
                    )}

                    <div className="product-authors author-grid-view">
                      <p className="my-0">
                        {item.source["cnt:auxiliaryFields"].authors &&
                          getAuthorsNameList(
                            item.source["cnt:auxiliaryFields"].authors
                          )}
                      </p>
                    </div>
                    <div className="product-isbn">
                      {item.source["schema:sourceIsbn"] &&
                        item.source["schema:sourceIsbn"]["schema:isbn13"] &&
                        item.source["schema:sourceIsbn"]["schema:isbn13"]
                          .length > 0 && (
                          <p className="my-0">
                            <strong>{`ISBN-13: `}</strong>
                            {`${item.source["schema:sourceIsbn"]["schema:isbn13"]}`}
                          </p>
                        )}
                    </div>
                    <div className="brand d-flex flex-row">
                      {item.source["schema:brand"] &&
                        item.source["schema:brand"]["schema:name"] &&
                        item.source["schema:brand"]["schema:name"].length >
                          0 && (
                          <React.Fragment>
                            {getProductBrand(item)}
                          </React.Fragment>
                        )}
                    </div>
                    {orgClassification !== ORG_CLASSIFICATION.HIGHER_ED &&
                      item.innerHits &&
                      item.innerHits.searchResults &&
                      item.innerHits.searchResults.find(
                        isSeatLicenseActivated
                      ) && (
                        <div className="k12label-wr  d-flex align-items-center">
                          <SeatLicenseIcon />
                          <span>K-12 license activated</span>
                        </div>
                      )}
                    {userData &&
                      (orgClassification === ORG_CLASSIFICATION.ANONYMOUS ||
                        orgClassification === ORG_CLASSIFICATION.SCHOOL) &&
                      !(
                        item.innerHits &&
                        item.innerHits.searchResults &&
                        item.innerHits.searchResults.find(
                          isSeatLicenseActivated
                        )
                      ) &&
                      item.source["cnt:auxiliaryFields"] &&
                      item.source["cnt:auxiliaryFields"].k12 &&
                      item.source["cnt:auxiliaryFields"].k12 ===
                        "availableForLicense" && (
                        <div className="k12label-wr purchase-wr d-flex align-items-center">
                          <PurchaseIcon />
                          <span>K-12 purchase options</span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
      {allSearchResult && allSearchResult.count > SEARCH_PAGE_RESULT_COUNT && (
        <SrpPagination />
      )}
    </div>
  );
}
