import axios from "axios";

export function fetchDiscipline() {
  return axios.get(
    `${window.location.protocol}//${
      process.env.REACT_APP_EXCHANGE_URL
        ? process.env.REACT_APP_EXCHANGE_URL
        : window.location.host
    }/api/discipline`
  );
}
