import React from "react";
import { Link } from "react-router-dom";
import { formatAutoCompleteData, highlightText } from "./helper";
import defaultProductImage from "../../images/empty-default-img.png";
import { PAGES, PLATFORMS, PRODUCT_MODELS } from "../../../config";
import {
  resetAutoComplete,
  chngeSearchValue,
  updateIsFocusCloseButton,
  resetSearch,
} from "../../widgets/search/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  updateSendSearchRequest,
  updateIsMobileSearchView,
} from "../search/searchSlice";
import { updateIsRedirectToSearch } from "../searchResult/searchResultSlice";
import useRefPlatforms from "../../util/useRefPlatforms";
import { resetDisciplineCount } from "../subject/subjectSlice";

export function AutoComplete(props) {
  const dispatch = useDispatch();
  let location = useLocation();

  let queryParams = new URLSearchParams(location.search);
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const [handleRefPlatform] = useRefPlatforms();

  const formatData = formatAutoCompleteData(
    props.autoCompleteList.searchResults,
    props.searchValue
  );

  const resetAutoCompleteData = (event) => {
    if (props.cPage !== PAGES.SERP) {
      handleRefPlatform();
    }

    let tmpSearchValue = event.target.getAttribute("data-search-value");
    if (!tmpSearchValue) {
      tmpSearchValue = event.currentTarget.getAttribute("data-search-value");
    }
    dispatch(chngeSearchValue(tmpSearchValue));
    dispatch(resetAutoComplete());
    dispatch(resetDisciplineCount());
    dispatch(updateIsRedirectToSearch(true));
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
    dispatch(updateIsMobileSearchView(false));
  };

  const createHtmlMarkup = (data, searchValue, isIsbn = false) => {
    if (isIsbn) {
      return {
        __html: "<strong>ISBN 13: </strong>" + highlightText(data, searchValue),
      };
    } else {
      return {
        __html: highlightText(data, searchValue),
      };
    }
  };

  const redirectShowAllToSearch = (searchText) => {
    if (queryParams.has("uuid")) {
      queryParams.delete("uuid");
    }
    if (props.cPage !== PAGES.SERP) {
      handleRefPlatform();
    }
    queryParams.set("q", encodeURIComponent(searchText));
    return `/search?${queryParams.toString()}`;
  };

  const redirectAuthorToSearch = (author) => {
    if (queryParams.has("uuid")) {
      queryParams.delete("uuid");
    }
    queryParams.set("q", encodeURIComponent(author));
    return `/search?${queryParams.toString()}`;
  };

  const authorsList = formatData.authorsList.map((author, index) => (
    <li
      role="option"
      className="search-product-authors list-group-item"
      key={index}
      id={`author${index}`}
      tabIndex="-1"
      onFocus={(e) => {
        e.target.children[0].setAttribute("aria-selected", "true");
      }}
      onBlur={(e) => {
        e.target.children[0].setAttribute("aria-selected", "false");
      }}>
      <Link
        to={redirectAuthorToSearch(author)}
        data-search-value={author}
        dangerouslySetInnerHTML={createHtmlMarkup(author, props.searchValue)}
        className="list-group-item-link search-dropdown-items dropdown-list-items"
        onClick={(event) => resetAutoCompleteData(event)}
        aria-selected={false}
      />
    </li>
  ));

  const getProductLink = (book, searchText) => {
    let queryStrings = "";
    queryStrings = `uuid=${encodeURIComponent(book.uuid)}`;
    queryParams.delete("uuid");
    queryParams.delete("q");
    if (queryParams.toString().length > 0) {
      queryStrings += `&${queryParams.toString()}`;
    }

    return `/products/${encodeURIComponent(book.productid)}/${
      book.slug ? encodeURIComponent(book.slug) : ""
    }?${queryStrings}`;
  };

  const getPlatformName = (product) => {
    if (product.productPlatform === PLATFORMS.ETEXT) {
      return (
        <div className="brand d-flex flex-row">
          <p className="brand-name mb-0">
            Platform: <span> Pearson eText</span>
          </p>
        </div>
      );
    } else if (product.productPlatform === PLATFORMS.REVEL) {
      return (
        <div className="brand d-flex flex-row">
          <p className="brand-name mb-0">
            Platform:{" "}
            <span>
              {" "}
              REVEL<sup>TM</sup>
            </span>
          </p>
        </div>
      );
    } else if (product.productPlatform === PLATFORMS.COLLECTION) {
      return (
        <div className="brand d-flex flex-row">
          <p className="brand-name mb-0">
            Platform: <span> Collections</span>
          </p>
        </div>
      );
    } else if (product.productPlatform === PLATFORMS.LEARNING_APPLICATIONS) {
      return (
        <div className="brand d-flex flex-row">
          <p className="brand-name mb-0">
            Platform: <span> Application</span>
          </p>
        </div>
      );
    }
    if (
      product.productPlatform === PLATFORMS.GLP &&
      product.productModel === PRODUCT_MODELS.ETEXT2_CITE
    ) {
      return (
        <div className="brand d-flex flex-row">
          <p className="brand-name mb-0">
            Platform: <span> Pearson eText</span>
          </p>
        </div>
      );
    } else if (
      product.productPlatform === PLATFORMS.GLP &&
      product.productModel === PRODUCT_MODELS.REVEL_CITE
    ) {
      return (
        <div className="brand d-flex flex-row">
          <p className="brand-name mb-0">
            Platform:
            <span>
              {" "}
              REVEL<sup>TM</sup>
            </span>
          </p>
        </div>
      );
    }
  };

  const productLinkClickHandler = () => {
    dispatch(updateIsMobileSearchView(false));
    dispatch(resetSearch());
  };

  const productsList = formatData.productsList.map((product, index) => (
    <li
      role="option"
      className={
        index === 0 && formatData.authorsList.length > 0
          ? "search-product-list list-group-item list-active"
          : "search-product-list list-group-item"
      }
      key={index}
      id={product.resourceId}
      tabIndex="-1">
      <Link
        to={getProductLink(product, props.searchValue)}
        className="search-dropdown-items dropdown-list-items"
        onClick={() => productLinkClickHandler()}>
        <div className="search-product-items">
          <img
            alt=""
            src={
              product.thumbnailUrl ? product.thumbnailUrl : defaultProductImage
            }
            className={product.thumbnailUrlCss}
          />
          <div className="search-iteams">
            <div
              className="search-title s-items"
              dangerouslySetInnerHTML={createHtmlMarkup(
                product.title,
                props.searchValue
              )}
            />
            <div
              className="search-author s-items"
              dangerouslySetInnerHTML={createHtmlMarkup(
                product.productAuthorNames,
                props.searchValue
              )}
            />
            <div
              className="search-isbn s-items"
              dangerouslySetInnerHTML={createHtmlMarkup(
                product.isbn,
                props.searchValue,
                true
              )}
            />
            {getPlatformName(product)}
          </div>
        </div>
      </Link>
    </li>
  ));

  const typeAheadKeyDownHandler = (event) => {
    event.preventDefault();
    //Only Tab key
    if (!event.shiftKey && event.keyCode === 9) {
      if (event.target.localName === "ul") {
        event.target.children[0].focus();
      } else if (event.target.nextElementSibling) {
        event.target.nextElementSibling.focus();
      } else if (event.target.innerText === "View all") {
        dispatch(resetAutoComplete());
      }
    }
    //Arrow Down key
    else if (event.keyCode === 40 && event.target.nextElementSibling) {
      event.target.nextElementSibling.focus();
    }
    //Arrow Up key
    else if (event.keyCode === 38) {
      if (
        event.target.previousSibling &&
        event.target.previousSibling.localName === "li"
      ) {
        event.target.previousSibling.focus();
      } else if (event.target.previousSibling) {
        event.target.previousSibling.previousSibling.focus();
      }
    }
    //Shift Key with Tab
    else if (event.shiftKey && event.keyCode === 9) {
      if (
        event.target.previousSibling &&
        event.target.previousSibling.localName === "li"
      ) {
        event.target.previousSibling.focus();
      } else if (
        event.target.previousSibling &&
        event.target.previousSibling.previousSibling &&
        event.target.previousSibling.previousSibling.localName === "li"
      ) {
        event.target.previousSibling.previousSibling.focus();
      } else if (event.target.parentElement.localName === "ul") {
        event.target.parentElement.focus();
      } else {
        dispatch(updateIsFocusCloseButton(true));
      }
    }
    else if (event.key === "Enter") {
      if(document.activeElement.classList.contains("list-group-item")) {
        document.activeElement.getElementsByTagName("a")[0].click();
      }
      else if(document.activeElement.classList.contains("view-all")) {
        document.activeElement.getElementsByTagName("a")[0].click();
      }
    }
  };

  return (
    <React.Fragment>
      <div className="search-dropdown">
        <ul
          className="list-group list-group-search-result"
          role="listbox"
          id="autocomplete-listbox"
          tabIndex="0"
          onKeyDown={(event) => typeAheadKeyDownHandler(event)}
          aria-expanded={
            authorsList.length > 0 || productsList.length ? "true" : "false"
          }>
          {authorsList && authorsList.length > 0 && <>{authorsList}</>}
          {productsList && productsList.length > 0 && <> {productsList}</>}
          <li role="option" className="view-all" tabIndex="-1">
            <div className="search-results">
              <div className="search-results-inner d-flex align-items-center flex-row justify-content-between">
                <div className="search-results-right">
                  <Link
                    to={redirectShowAllToSearch(props.searchValue)}
                    data-search-value={props.searchValue}
                    onClick={(event) => resetAutoCompleteData(event)}>
                    View all
                  </Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
