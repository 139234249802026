import React, { useEffect } from "react";
import { SERPleftbar } from "../../widgets/serpLeftBar/SERPleftbar";
import { SearchResult } from "../../widgets/searchResult/SearchResult";
import { resetProductData } from "../pdp/productSlice";
import { ExHeader } from "../../widgets/exHeader/ExHeader";
import { updateIsDisplay } from "../../widgets/hamburger/hamburgerSlice";
import { PAGES } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import "./srp.scss";
import ReactGA from "react-ga4";
import { updateIsRedirectToSearch } from "../../widgets/searchResult/searchResultSlice";
import { ScrollToTop } from "../../widgets/scrollToTop/ScrollToTop";
import { SearchMobileView } from "../../widgets/searchMobileView/SearchMobileView";
import { updateDisplaySerpLeftBar } from "../../widgets/serpLeftBar/serpLeftBarSlice";
import { updateSendSearchRequest } from "../../widgets/search/searchSlice";
import { Notification } from "../../widgets/notification/Notification";
import { useLocation } from "react-router-dom";

export function Srp() {
  const dispatch = useDispatch();
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const isRedirectToSearch = useSelector(
    (state) => state.searchResult.isRedirectToSearch
  );
  const userConsent = useSelector((state) => state.user.userConsent);

  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (
      window.piSession &&
      window.piSession.userId() === null &&
      !isRedirectToSearch &&
      !queryParams.has("integration_context_token")
    ) {
      dispatch(updateIsRedirectToSearch(true));
      dispatch(updateSendSearchRequest(sendSearchRequest + 1));
    }
    dispatch(resetProductData());

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: "pageview",
      action: "on serp page",
    });
  }, [userConsent]);

  const filterByClickHandler = (isBtnClick, event) => {
    event.stopPropagation();
    if (isBtnClick) {
      dispatch(updateDisplaySerpLeftBar(true));
    } else {
      dispatch(updateDisplaySerpLeftBar(false));
    }
  };

  const isMobileView = useSelector((state) => state.user.isMobileView);
  const isMobileSearchView = useSelector(
    (state) => state.search.isMobileSearchView
  );
  const userData = useSelector((state) => state.user.userData);

  return (
    <React.Fragment>
      {!isMobileSearchView && (
        <div
          className="container-fluid main-bg"
          onClick={(event) => filterByClickHandler(false, event)}>
          <div>
            <a
              className="skip-nav"
              data-role="none"
              href="#main-content-starts">
              Skip to main content
            </a>
            <div className="container-fluid header-wr">
              <div
                className={
                  userData === null
                    ? "container header-container header-srp-container"
                    : "container header-container header-srp-container signed-in-wr"
                }>
                <ExHeader cPage={PAGES.SERP} />
              </div>
            </div>
          </div>
          <main
            onClick={() => dispatch(updateIsDisplay(false))}
            id="main-content-starts"
            tabIndex="-1">
            <Notification cPage={PAGES.SERP} />
            <div className="serp-container" >
              <div className="row serp-container-inner">
                <div
                  className={
                    userData === null
                      ? "col-xl-3 col-lg-3 serp-container-leftside"
                      : "col-xl-3 col-lg-3 serp-container-leftside tab-filter-wr"
                  }
                  onClick={(event) => filterByClickHandler(true, event)}>
                  <SERPleftbar filterByClickHandler={filterByClickHandler} />
                </div>
                <div className="col-lg-8 col-xl-9 serp-container-rightside">
                  <SearchResult filterByClickHandler={filterByClickHandler} />
                </div>
              </div>
            </div>
            <ScrollToTop />
          </main>
        </div>
      )}
      {isMobileView && isMobileSearchView && <SearchMobileView />}
    </React.Fragment>
  );
}
