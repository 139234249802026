import React, { useEffect, useState } from "react";
import "./feature.scss";
import { useSelector } from "react-redux";

export function Feature(props) {
  const [isStudentView, setIsStudentView] = useState(false);
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (userData && userData.isStudent) {
      setIsStudentView(true);
    }
  });

  return (
    <React.Fragment>
      <div className="feature-main-wr">
        {props.productDisciplineFeature.contents.map((featureItem, index) => (
          <div className="feature-content-container">
            <div className="feature-inner-container d-flex flex-row">
              <div className="features-img">
                {featureItem.resourceUrl && (
                  <img
                    src={featureItem.resourceUrl}
                    alt=""
                    width="200"
                    height="200"
                  />
                )}
              </div>
              <div className="d-flex flex-column feature-info">
                {featureItem.primaryTitle && (
                  <p className="feature-primary-title">
                    {featureItem.primaryTitle}
                  </p>
                )}
                {featureItem.secondaryTitle && (
                  <p className="feature-secondary-title">
                    {featureItem.secondaryTitle}
                  </p>
                )}
                {isStudentView && featureItem.studentDescription && (
                  <p className="mb-0 feature-description">
                    {featureItem.studentDescription}
                  </p>
                )}
                {!isStudentView && featureItem.description && (
                  <p className="mb-0 feature-description">
                    {featureItem.description}
                  </p>
                )}
                {featureItem.ctaText && featureItem.ctaUrl && (
                  <div className="feature-btn-container">
                    <button
                      className="custom-btn outline-btn feature-btn"
                      onClick={() => window.open(featureItem.ctaUrl)}>
                      {featureItem.ctaText}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
