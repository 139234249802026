import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export function VerifyAccountPopup(props) {
  const userData = useSelector((state) => state.user.userData);  

  const verifyModalHandler = (event) => {
    event.setAttribute("aria-describedby", "goToForm-para go-to-form-btn");
    event.setAttribute("id", "ad-modal");
  };

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      const modal = document.querySelector("#ad-modal");
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const firstFocusableElement =
        modal.querySelectorAll(focusableElements)[0];
      const focusableContent = modal.querySelectorAll(focusableElements);
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1];
      const activeElement = window.document.activeElement;
      if (event.shiftKey && event.key === "Tab") {
        if (activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          event.preventDefault();
        }
      } else if (event.key === "Tab") {
        if (activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          event.preventDefault();
        }
      }
    });
  }, []);

  useEffect(() => {
    let gotoFormBtn = document.querySelector("#go-to-form-btn");
    gotoFormBtn.addEventListener("touchstart", startTouch, false);
    gotoFormBtn.addEventListener("touchmove", moveTouch, false);

    let initialX = null;
    let initialY = null;
    function startTouch(e) {
      initialX = e.touches[0].clientX;
      initialY = e.touches[0].clientY;
    }
    function moveTouch(e) {
      if (initialX === null) {
        return;
      }
      if (initialY === null) {
        return;
      }
      let currentX = e.touches[0].clientX;
      let currentY = e.touches[0].clientY;

      let diffX = initialX - currentX;
      let diffY = initialY - currentY;

      if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX < 0) {
          let closeBtn = document.querySelector(".close");
          closeBtn && closeBtn.focus();
        }
      }
      initialX = null;
      initialY = null;

      e.preventDefault();
    }
  }, []);

  const goToFormClickHandler = (e) => {
    const conf = window.__env.configs["appConfig"];
    props.verifyHandleClose(false);
    window.open(
      `${conf.VERIFY_ACCOUNT_REDIRECTIONS}?catalog_code=revel&firstName=${
        userData.userDetails && userData.userDetails.givenName
          ? encodeURIComponent(userData.userDetails.givenName)
          : ""
      }&lastName=${
        userData.userDetails && userData.userDetails.familyName
          ? encodeURIComponent(userData.userDetails.familyName)
          : ""
      }&emailAddress=${
        userData.userDetails &&
        userData.userDetails.emails &&
        userData.userDetails.emails.length > 0
          ? encodeURIComponent(userData.userDetails.emails[0].emailAddress)
          : ""
      }&forced_logout=forced_logged_out`,
      "_blank"
      
    );
    e.preventDefault();
  };
  return (
    <Modal
      show={props.showModal}
      onHide={props.verifyHandleClose}
      aria-labelledby="goToForm-heading"
      onEntered={(event) => verifyModalHandler(event)}>
      <div className="share-modal-wr">
        <Modal.Header closeButton></Modal.Header>
        <h2 className="mt-0" id="goToForm-heading">
          Verify your account{" "}
        </h2>
        <p id="goToForm-para" tabindex="-1">
          Verify your instructor status to create assignments, access course
          materials and invite students to enroll in your courses.
        </p>
        <a
          id="go-to-form-btn"
          className="custom-btn secondary-btn"
          href="#"
          onClick={(e) => goToFormClickHandler(e)}
          target="_blank"
          rel="noopener noreferrer"
          aria-describedby="form-advanced-warning">
          Go to form
          <span className="sr-only" id="form-advanced-warning">
            Opens in a new tab
          </span>
        </a>
      </div>
    </Modal>
  );
}
