import axios from "axios";
import { SEARCH_PAGE_RESULT_COUNT } from "../../../config";
export function fetchAutoComplete(
  searchValue,
  country,
  institute,
  orgClassification,
  token
) {
  return axios.get(
    `${window.location.protocol}//${
      process.env.REACT_APP_EXCHANGE_URL
        ? process.env.REACT_APP_EXCHANGE_URL
        : window.location.host
    }/api/autoComplete?q=${searchValue}&cat=&country=${country}&disc=&institution=${institute}&limit=${SEARCH_PAGE_RESULT_COUNT}&offset=0&token=${token}&type=all&orgClassification=${orgClassification}`
  );
}
