import React from "react";
import { OFFER_TYPES, OFFER_DISPLAY_NAMES } from "../../../../../config";
import { getCurrencySymbol } from "../../../../util/offerUtils";

export const StudentOfferTab = (props) => {
  /**
   * Display Instant Access and Temp Access offer information.
   * @param {*}
   * @returns React Fragment
   */
  const getOfferInfo = (offers, offerTypeCode) => {
    return (
      <React.Fragment>
        <div className="student-tab-content access-code-wr institutional-licensing-wr">
          <p className="access-title mb-0">
            {OFFER_DISPLAY_NAMES[offerTypeCode]}
          </p>
          {offers[0].description && (
            <p className="sub-text mb-0">{offers[0].description}</p>
          )}
          {offers.map((offer, index) => (
            <React.Fragment>
              <div className="d-flex align-items-center justify-content-between available-offer">
                {offer.offerType &&
                  offer.offerType.definition &&
                  offer.offerType.offerTypeCode !== OFFER_TYPES.FREE_TRIAL &&
                  offer.offerType.definition.subscriptionDuration && (
                    <p className="title mb-0">{`${
                      offer.offerType.definition.subscriptionDuration
                    }-day access`}</p>
                  )}
                {offer.offerType &&
                  offer.offerType.definition &&
                  offer.offerType.offerTypeCode !== OFFER_TYPES.FREE_TRIAL &&
                  offer.offerType.definition.currency && (
                    <p className="title mb-0">
                      {`${getCurrencySymbol(
                        offer.offerType.definition.currency.toUpperCase()
                      )}${offer.offerType.definition.price.toFixed(2)}`}
                    </p>
                  )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {props.instantAccessOffers &&
        props.instantAccessOffers.length > 0 &&
        getOfferInfo(props.instantAccessOffers, OFFER_TYPES.INSTANT_ACCESS)}

      {props.tempAccessOffers && props.tempAccessOffers.length > 0 && (
        <React.Fragment>
          {props.instantAccessOffers &&
            props.instantAccessOffers.length > 0 && (
              <hr className="access-code-wr separator" aria-hidden="true" />
            )}
          {getOfferInfo(props.tempAccessOffers, OFFER_TYPES.FREE_TRIAL)}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
