import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSearchResult } from "./searchResultApi";

const initialState = {
  searchResult: null,
  k12SearchResult: null,
  currentResultPage: 1,
  isRedirectToSearch: false,
  isInternalError: false,
  isHigheredResultReceived: false,
  isK12ResultReceived: false,
  k12ItemsCount: 0,
};

export const searchResultAsync = createAsyncThunk(
  "searchResult/fetchSearchResult",
  async (args) => {
    const response = await fetchSearchResult(
      args.searchValue,
      args.category,
      args.country,
      args.institution,
      args.discipline,
      args.featureFlag,
      args.limit,
      args.offset,
      args.type,
      args.sort,
      args.show,
      args.token,
      args.orgClassification,
      args.resultType
    );
    return response.data;
  }
);

export const searchResultSlice = createSlice({
  name: "searchResult",
  initialState,
  reducers: {
    updateCurrentResultPage: (state, action) => {
      state.currentResultPage = action.payload;
    },
    resetSearchResult: (state, action) => {
      state.searchResult = { items: [] };
      state.k12SearchResult = { items: [] };
      state.currentResultPage = 1;
    },
    reinitializedSearchResult: (state, action) => {
      state.searchResult = null;
      state.k12SearchResult = null;
      state.currentResultPage = 1;
    },
    reinitializedK12SearchResult: (state, action) => {
      state.k12SearchResult = null;
    },
    updateIsRedirectToSearch: (state, action) => {
      state.isRedirectToSearch = action.payload;
    },
    updateIsInternalError: (state, action) => {
      state.isInternalError = action.payload;
    },
    reinitializedSearchResultStatus: (state, action) => {
      state.isHigheredResultReceived = false;
      state.isK12ResultReceived = false;
    },
    resetK12ItemsCount: (state, action) => {
      state.k12ItemsCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchResultAsync.fulfilled, (state, action) => {
        if (action.meta.arg.resultType === "k12") {
          state.k12SearchResult = action.payload;
          state.isInternalError = false;
          state.isK12ResultReceived = true;
          state.k12ItemsCount = action.payload.count;
        } else {
          state.searchResult = action.payload;
          state.isInternalError = false;
          state.isHigheredResultReceived = true;
        }
      })
      .addCase(searchResultAsync.rejected, (state, action) => {
        if (
          action &&
          action.meta &&
          action.meta.arg &&
          action.error &&
          action.error.message.includes("500")
        ) {
          state.isInternalError = true;
          state.searchResult = null;
          state.k12SearchResult = null;
          state.currentResultPage = null;
          state.isK12ResultReceived = true;
          state.isHigheredResultReceived = true;
        }
      });
  },
});

export const {
  updateCurrentResultPage,
  resetSearchResult,
  updateIsRedirectToSearch,
  reinitializedSearchResult,
  updateIsInternalError,
  reinitializedSearchResultStatus,
  reinitializedK12SearchResult,
  resetK12ItemsCount,
} = searchResultSlice.actions;
export default searchResultSlice.reducer;
