import axios from "axios";
import { SERP_PLATFORMS } from "../../../config";

export function fetchDisciplineCount(
  searchValue,
  category,
  country,
  institution,
  discipline,
  featureFlag,
  limit,
  offset,
  type,
  sort,
  show,
  token,
  orgClassification,
  resultType
) {
  let queryParams = "";
  if (token && token.length > 0) {
    queryParams = `q=${searchValue}&cat=${category}&country=&disc=${discipline}&ftrFlag=${featureFlag}&limit=${limit}&offset=${offset}&token=${
      token ? token : ""
    }&type=${type}`;
  } else {
    if (institution && institution.length > 0) {
      queryParams = `q=${searchValue}&cat=${category}&country=${country}&disc=${discipline}&institution=${institution}&limit=${limit}&offset=${offset}&token=&type=${type}`;
    } else {
      queryParams = `q=${searchValue}&cat=${category}&country=${country}&disc=${discipline}&ftrFlag=${featureFlag}&limit=${limit}&offset=${offset}&token=&type=${type}`;
    }
  }

  if (sort && sort.length > 0) {
    queryParams = `${queryParams}&sort=${sort}`;
  }

  if (show && show.length > 0) {
    queryParams = `${queryParams}&filter=${show}`;
  }

  if (type === SERP_PLATFORMS.ALL.type || type === SERP_PLATFORMS.REVEL.type) {
    queryParams = `${queryParams}&orgClassification=${orgClassification}&resultType=${resultType}`;
  }

  return axios.get(
    `${window.location.protocol}//${
      process.env.REACT_APP_EXCHANGE_URL
        ? process.env.REACT_APP_EXCHANGE_URL
        : window.location.host
    }/api/discipline/count?${queryParams}`
  );
}
