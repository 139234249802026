import { React, useEffect, useRef } from "react";
import { InstitutionalOfferTab } from "./institutionalOfferTab/InstitutionalOfferTab";
import { StudentOfferTab } from "./studentOfferTab/StudentOfferTab";
import { Tabs, Tab } from "react-bootstrap";
import "../offers.scss";




export const OfferTabs = (props) => {  
  const tabRef = useRef(null);

  const handleKeyDown = (e) => {
      if(document.activeElement.getAttribute("id") === "offer-tab-navigation-tab-Institutions"){        
        tabRef.current.nextElementSibling.children[0].removeAttribute("tabIndex");
        tabRef.current.nextElementSibling.children[1].setAttribute("tabIndex", "-1");
      }

      else if(document.activeElement.getAttribute("id") === "offer-tab-navigation-tab-Students"){
        tabRef.current.nextElementSibling.children[0].setAttribute("tabIndex", "-1");
        tabRef.current.nextElementSibling.children[1].removeAttribute("tabIndex");
      }      
  }

  useEffect(() => {
    document.addEventListener("keyup", handleKeyDown);
    document.addEventListener("click", handleKeyDown);

    return () => {
      document.removeEventListener("keyup", handleKeyDown);
      document.addEventListener("click", handleKeyDown);
    };
  }, []);

 

  return (
    <>
    <span aria-hidden="true" ref={tabRef}></span>
    <Tabs id="offer-tab-navigation" transition={false} className="offer-tabs" aria-label="Institute and Student Details">    
        <Tab eventKey="Institutions" title="Institutions" className="defaultKey">          
          <InstitutionalOfferTab accessCodeOffers={props.accessCodeOffers}/>
        </Tab>
        <Tab eventKey="Students" title="Students" className="defaultKey">          
          <StudentOfferTab instantAccessOffers={props.instantAccessOffers} tempAccessOffers={props.tempAccessOffers}/>
        </Tab>
    </Tabs>
    </>
  );

};