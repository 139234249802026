import React, { useEffect, useState, useRef } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./arrows";
import { NavTab } from "./navTab";
import "./globalStyles.css";
import usePreventBodyScroll from "./usePreventBodyScroll";
import "./hideScrollbar.css";
import { useSelector, useDispatch } from "react-redux";
import { PRODUCT_TABS } from "../../../config";
import { updateActiveMobileProductTab } from "../../pages/pdp/productSlice";
import "./mobileProductTabNav.scss";

export function MobileProductTabNav() {
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const productTabs = useSelector((state) => state.product.productTabs);
  const [navigationTabs, setNavigationTabs] = useState([]);
  const dispatch = useDispatch();
  const collectionTabContent = useRef();
  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  useEffect(() => {
    let tempNavigationTabs = [];
    if (productTabs && productTabs.overview) {
      tempNavigationTabs.push(PRODUCT_TABS.OVERVIEW);
    }

    if (productTabs && productTabs.gettingStarted) {
      tempNavigationTabs.push(PRODUCT_TABS.GETTING_STARTED);
    }

    if (productTabs && productTabs.features) {
      tempNavigationTabs.push(PRODUCT_TABS.FEATURES);
    }

    if (productTabs && productTabs.authors) {
      tempNavigationTabs.push(PRODUCT_TABS.AUTHORS);
    }

    if (productTabs && productTabs.contents) {
      tempNavigationTabs.push(PRODUCT_TABS.CONTENTS);
    }

    setNavigationTabs(tempNavigationTabs);
  }, [productTabs]);

  const tabClickHandler = (event) => {
    dispatch(updateActiveMobileProductTab(event.target.dataset.itemId));
    let topPos = collectionTabContent.current.offsetTop + 20;
    if (topPos) {
      window.scrollTo({
        top: topPos,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="pdp-tabcontent" ref={collectionTabContent}>
      <nav
        onMouseEnter={disableScroll}
        onMouseLeave={enableScroll}
        role="tablist">
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onWheel={onWheel}>
          {navigationTabs &&
            navigationTabs.map((navigationTab, index) => (
              <NavTab
                title={navigationTab.VALUE}
                itemId={navigationTab.KEY}
                key={index}
                tabClickHandler={tabClickHandler}
              />
            ))}
        </ScrollMenu>
      </nav>
    </div>
  );
}
