import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  productAsync,
  productOffersAsync,
  productDisciplineFeatureAsync,
  productTocAsync,
  productModuleAsync,
  updateIsActiveSeatLicenseAvaliable,
  productAvailableToBuyOffersAsync,
  resetProductData,
} from "./productSlice";
import { Product } from "../../widgets/product/Product";
import { ProductTabs } from "../../widgets/productTabs/ProductTabs";
import { RelatedProducts } from "../../widgets/relatedProducts/RelatedProducts";
import "./pdp.scss";
import {
  PLATFORMS,
  PRODUCT_MODELS,
  PAGES,
  PRODUCT_STAGE,
  ORG_CLASSIFICATION,
  OFFER_TYPES,
  K12_TYPE,
} from "../../../config";
import { InstitutesList } from "../../widgets/institutePickList/InstitutesList";
import { MobileFeature } from "../../widgets/mobileFeature/MobileFeature";
import { Notification } from "../../widgets/notification/Notification";
import { useParams } from "react-router-dom";
import {
  resetAutoComplete,
  updateSendSearchRequest,
} from "../../widgets/search/searchSlice";
import { ExHeader } from "../../widgets/exHeader/ExHeader";
import { updateRefreshInstitution } from "../../widgets/exHeader/userSlice";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { disciplineAsync } from "../../widgets/discipline/disciplineSlice";
import { ScrollToTop } from "../../widgets/scrollToTop/ScrollToTop";
import { SearchMobileView } from "../../widgets/searchMobileView/SearchMobileView";
import { CTAOfferContainer } from "../../widgets/ctaOffer/CTAOfferContainer";

export function Pdp() {
  let { id } = useParams();
  const productData = useSelector((state) => state.product.productData);
  const currentInstitute = useSelector((state) => state.user.currentInstitute);
  const relatedProductData = useSelector(
    (state) => state.product.relatedProductData
  );
  const productDisciplineFeature = useSelector(
    (state) => state.product.productDisciplineFeature
  );
  const disciplineList = useSelector(
    (state) => state.discipline.disciplineList
  );
  const productToc = useSelector((state) => state.product.productToc);
  const productModule = useSelector((state) => state.product.productModule);
  const productOffers = useSelector((state) => state.product.productOffers);
  const productMobileFeature = useSelector(
    (state) => state.product.productMobileFeature
  );
  const isDisplayPdp = useSelector((state) => state.product.isDisplayPdp);
  const isProductAvailable = useSelector(
    (state) => state.product.isProductAvailable
  );
  const userData = useSelector((state) => state.user.userData);
  const isMobileView = useSelector((state) => state.user.isMobileView);
  const orgClassification = useSelector(
    (state) => state.user.orgClassification
  );
  const isMobileSearchView = useSelector(
    (state) => state.search.isMobileSearchView
  );
  const userConsent = useSelector((state) => state.user.userConsent);

  const dispatch = useDispatch();
  const [isDisciplineFeature, setIsDisciplineFeature] = useState(false);
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  const getProductModelFeatures = useCallback(() => {
    let pModel = "";
    if (productData.productPlatform === PLATFORMS.COLLECTION) {
      pModel = "Collections";
    } else if (
      productData.productModel &&
      productData.productModel.name &&
      productData.productModel.name.toUpperCase() === PRODUCT_MODELS.SUMATRA
    ) {
      pModel = productData.productModel.name.toUpperCase();
    } else if (productData.productPlatform === PLATFORMS.REVEL) {
      pModel = "Revel";
    } else if (productData.productPlatform === PLATFORMS.ETEXT) {
      pModel = productData.productModel.name.toUpperCase();
    } else if (
      productData.productPlatform === PLATFORMS.LEARNING_APPLICATIONS
    ) {
      pModel = productData.productModel.name;
    } else if (productData.productPlatform === PLATFORMS.GLP) {
      pModel = productData.productModel.name.toUpperCase();
    } else {
      pModel =
        productData.productPlatform &&
        productData.productModel.name !== PRODUCT_MODELS.CUSTOM_COLLECTIONS
          ? productData.productPlatform
          : ""; //No features should be loaded for CUSTOM_COLLECTIONS
    }

    return pModel;
  }, [productData]);

  useEffect(() => {
    dispatch(updateRefreshInstitution(false));
    dispatch(updateSendSearchRequest(0));
    if (!disciplineList) {
      dispatch(disciplineAsync());
    }
  }, []);

  useEffect(() => {
    setIsDisciplineFeature(false);
    dispatch(resetAutoComplete());
  }, [id]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const uuid = params.get("uuid");
    if (uuid && uuid.length > 0) {
      dispatch(resetProductData());
      dispatch(
        productAsync({
          productId: uuid,
          isRelated: false,
          lmsIntegrationToken: queryParams.has("integration_context_token")
            ? queryParams.get("integration_context_token")
            : "",
        })
      );
      ReactGA.event({
        category: "product detailed",
        action: "on pdp page",
        label: uuid,
      });
    }
  }, [id, userConsent]);

  useEffect(() => {
    ReactGA.event({
      category: "pageview",
      action: "on pdp page",
    });
  }, [userConsent]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const uuid = params.get("uuid");
    if (uuid && uuid.length > 0) {
      if (userData && currentInstitute) {
        dispatch(
          productOffersAsync({
            productId: uuid,
            orgId: queryParams.has("integration_context_token")
              ? currentInstitute.id
              : currentInstitute.organization.orgId,
            location: queryParams.has("integration_context_token")
              ? currentInstitute.location.country
              : currentInstitute.organization.location.country,
            lmsIntegrationToken: queryParams.has("integration_context_token")
              ? queryParams.get("integration_context_token")
              : "",
          })
        );
      } else {
        dispatch(
          productOffersAsync({
            productId: uuid,
            lmsIntegrationToken: queryParams.has("integration_context_token")
              ? queryParams.get("integration_context_token")
              : "",
          })
        );
      }
    }
  }, [userData, currentInstitute, id]);

  useEffect(() => {
    if (
      userData &&
      productData &&
      productData.k12 &&
      productData.k12 === K12_TYPE.AVAILABLE_FOR_LICENSE &&
      orgClassification !== ORG_CLASSIFICATION.HIGHER_ED
    ) {
      dispatch(
        productAvailableToBuyOffersAsync({
          productId: productData.productId,
        })
      );
    }
  }, [userData, productData, currentInstitute]);

  useEffect(() => {
    if (
      (window.piSession && window.piSession.userId() === null) ||
      (userData && userData.id)
    ) {
      if (productData !== null && isDisciplineFeature === false) {
        window.document.title = `${productData.name} - Exchange`;
        let pDiscipline = "";
        let isInstFeatureVisible = false;
        let pModel = getProductModelFeatures();
        if (
          (productData.disciplines &&
            productData.disciplines.length === 1 &&
            productData.disciplines[0].primaryDiscipline &&
            productData.disciplines[0].primaryDiscipline.length > 0) ||
          pModel.length > 0 ||
          productData.productPlatform === PLATFORMS.LEARNING_APPLICATIONS
        ) {
          if (
            productData.disciplines &&
            productData.disciplines.length === 1 &&
            productData.disciplines[0].primaryDiscipline &&
            productData.disciplines[0].primaryDiscipline.length > 0
          ) {
            pDiscipline = encodeURIComponent(
              productData.disciplines[0].primaryDiscipline
            );
          }

          if (
            (window.piSession && window.piSession.userId() === null) ||
            (userData !== null && userData.isInstructor)
          ) {
            isInstFeatureVisible = true;
          }
          const args = {
            pDiscipline: pDiscipline,
            pModel: pModel,
            pId: productData.productId,
            isLearningApp:
              productData.productPlatform === PLATFORMS.LEARNING_APPLICATIONS,
            instrFeature: isInstFeatureVisible,
          };
          dispatch(productDisciplineFeatureAsync(args));
          setIsDisciplineFeature(true);

          if (productData.productPlatform === PLATFORMS.COLLECTION) {
            dispatch(productModuleAsync({ productId: productData.productId }));
          } else {
            dispatch(productTocAsync({ productId: productData.productId }));
          }

          if (productData.productPlatform !== PLATFORMS.LEARNING_APPLICATIONS) {
            dispatch(
              productAsync({
                productId: productData.productId,
                isRelated: true,
                lmsIntegrationToken: queryParams.has(
                  "integration_context_token"
                )
                  ? queryParams.get("integration_context_token")
                  : "",
              })
            );
          }
        }
      }
    }
  }, [productData, id, userData]);

  // handle responsive view

  const [isTabMobileView, setIsTabMobileView] = useState(false);
  const handleresponsiveView = () => {
    if (window.innerWidth < 992) {
      setIsTabMobileView(true);
    } else {
      setIsTabMobileView(false);
    }
  };

  useEffect(() => {
    handleresponsiveView();
    window.addEventListener("resize", handleresponsiveView);
    return () => window.removeEventListener("resize", handleresponsiveView);
  }, []);

  // skip nav a11y implementation
  const [displaySkipNav, setdisplaySkipNav] = useState("");
  const [skipNavOnpressEnter, setSkipNavOnpressEnter] = useState(false);

  const handleKeyPressSkipNav = (e) => {
    if (e && (e.keyCode || e.which) === 13) {
      setSkipNavOnpressEnter(true);
    }
  };

  useEffect(() => {
    if (productOffers && productOffers.offers) {
      const tempSeatLicense = productOffers.offers.find(
        validateActiveSeatLicense
      );
      if (tempSeatLicense) {
        dispatch(updateIsActiveSeatLicenseAvaliable(true));
      }
    }
  }, [productOffers]);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth",});
  },[]);

  const validateActiveSeatLicense = (offer) => {
    if (queryParams.has("integration_context_token")) {
      return (
        orgClassification === ORG_CLASSIFICATION.SCHOOL &&
        offer.offerType.offerTypeCode === OFFER_TYPES.SEAT_LICENSE &&
        offer.orgId === currentInstitute.id
      );
    } else {
      return (
        orgClassification === ORG_CLASSIFICATION.SCHOOL &&
        offer.offerType.offerTypeCode === OFFER_TYPES.SEAT_LICENSE &&
        offer.orgId === currentInstitute.organization.orgId
      );
    }
  };

  return (
    <React.Fragment>
      {!isMobileSearchView && (
        <React.Fragment>
          <a
            className="skip-nav skip-nav-fixed-header"
            href="#main-content-starts"
            onFocus={() => {
              setdisplaySkipNav("fixedHeaderSkipNavLinkOnFocus");
            }}
            onBlur={() => {
              setdisplaySkipNav("fixedHeaderSkipNavLinkOnBlur");
            }}
            onKeyDown={(event) => handleKeyPressSkipNav(event)}>
            Skip to main content
          </a>
          <div className={`container-fluid custom-pdp ${displaySkipNav}`}>
            <div className="container-fluid header-wr">
              <div
                className={
                  userData === null
                    ? "header-container"
                    : "header-container signed-in-wr"
                }>
                <ExHeader cPage={PAGES.PDP} />
              </div>
            </div>
            <div className="horizontal-separator"></div>
            <main
              className="pdp-container main-bg"
              id="main-content-starts"
              tabIndex="-1">
              <Notification cPage={PAGES.PDP} />
              {isDisplayPdp && isProductAvailable && (
                <React.Fragment>
                  <div
                    className={`pdp-wrap row ${
                      skipNavOnpressEnter ? "skipNavOnpressEnter" : ""
                    }`}>
                    <div
                      className="pdp-leftside col-lg-7 col-xl-9"
                      id="pdp-leftside">
                      {isTabMobileView && productData && (
                        <div className="pdp-rightside tab-mobile-view-only">
                          {userData && <InstitutesList cPage={PAGES.PDP} />}
                        </div>
                      )}

                      {(productData ||
                        (productData &&
                          productData.productStage ===
                            PRODUCT_STAGE.REVIEW)) && (
                        <Product
                          productData={productData}
                          productOffers={productOffers}
                        />
                      )}

                      {productData && isTabMobileView && (
                        <React.Fragment>
                          <div className="tab-mobile-view-only">
                            <CTAOfferContainer />
                          </div>
                          <aside className="pdp-rightside tab-mobile-view-only">
                            {((productData &&
                              productData.productPlatform &&
                              productData.productPlatform !==
                                PLATFORMS.LEARNING_APPLICATIONS &&
                              !queryParams.has("integration_context_token") &&
                              relatedProductData &&
                              relatedProductData.isRelatedTo &&
                              relatedProductData.isRelatedTo.length > 0) ||
                              window.sessionStorage.getItem(
                                "exchange.product.baseRelated"
                              )) && (
                              <RelatedProducts
                                relatedProducts={
                                  relatedProductData &&
                                  relatedProductData.isRelatedTo
                                    ? relatedProductData.isRelatedTo
                                    : JSON.parse(
                                        window.sessionStorage.getItem(
                                          "exchange.product.baseRelated"
                                        )
                                      )
                                }
                              />
                            )}
                          </aside>
                        </React.Fragment>
                      )}

                      {productData && (
                        <ProductTabs
                          productData={productData}
                          productDisciplineFeature={productDisciplineFeature}
                          productToc={productToc}
                          productModule={productModule}
                          isTabMobileView={isTabMobileView}
                        />
                      )}
                    </div>

                    {productData && (
                      <div className="pdp-rightside col-lg-4 col-xl-3">
                        {!isTabMobileView && (
                          <React.Fragment>
                            <div className="desktop-view-only">
                              {userData && <InstitutesList cPage={PAGES.PDP} />}
                            </div>
                            <CTAOfferContainer />
                            {((productData &&
                              productData.productPlatform &&
                              productData.productPlatform !==
                                PLATFORMS.LEARNING_APPLICATIONS &&
                              !queryParams.has("integration_context_token") &&
                              relatedProductData &&
                              relatedProductData.isRelatedTo &&
                              relatedProductData.isRelatedTo.length > 0) ||
                              window.sessionStorage.getItem(
                                "exchange.product.baseRelated"
                              )) && (
                              <aside className="desktop-view-only">
                                <RelatedProducts
                                  relatedProducts={
                                    relatedProductData &&
                                    relatedProductData.isRelatedTo
                                      ? relatedProductData.isRelatedTo
                                      : JSON.parse(
                                          window.sessionStorage.getItem(
                                            "exchange.product.baseRelated"
                                          )
                                        )
                                  }
                                />
                              </aside>
                            )}
                          </React.Fragment>
                        )}
                        {productMobileFeature &&
                          (productData.productPlatform === PLATFORMS.ETEXT ||
                            (productData.productPlatform === PLATFORMS.GLP &&
                              productData.productModel.name ===
                                PRODUCT_MODELS.ETEXT2_CITE)) && (
                            <div className="pdp-rightside-wr">
                              <MobileFeature />
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
              <ScrollToTop />
            </main>
          </div>
        </React.Fragment>
      )}
      {isMobileView && isMobileSearchView && <SearchMobileView />}
    </React.Fragment>
  );
}
