import React, { useContext, useState, useEffect } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import "./mobileProductTabNav.scss";

const Arrow = ({ disabled, onClick, children, side }) => {
  return (
    <button
      aria-label={side ? "Go to previous tab" : "Go to next tab"}
      disabled={disabled}
      onClick={onClick}
      className={disabled ? "arrow-style arrow-style-disabled" : "arrow-style"}>
      {children}
    </button>
  );
};

export function LeftArrow() {
  const {
    getPrevItem,
    isFirstItemVisible,
    scrollToItem,
    visibleItemsWithoutSeparators,
    initComplete,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );

  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  // NOTE: for scroll 1 item
  const clickHandler = () => {
    const prevItem = getPrevItem();
    scrollToItem(prevItem?.entry?.target, "smooth", "start");
  };

  return (
    <Arrow disabled={disabled} onClick={clickHandler} side={true}>
      <svg
        width="8"
        height="15"
        viewBox="0 0 8 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        >
        <path
          className="hc-icons"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.264258 8.17728C-0.097387 7.78499 -0.0878425 7.17363 0.292892 6.79289L6.29289 0.792893C6.68342 0.402369 7.31658 0.402369 7.70711 0.792893C8.09763 1.18342 8.09763 1.81658 7.70711 2.20711L2.41421 7.5L7.7071 12.7929C8.09763 13.1834 8.09763 13.8166 7.7071 14.2071C7.31658 14.5976 6.68341 14.5976 6.29289 14.2071L0.292892 8.2071C0.28297 8.19718 0.273414 8.18725 0.264258 8.17728Z"
          fill="#151515"
        />
      </svg>
    </Arrow>
  );
}

export function RightArrow() {
  const {
    getNextItem,
    isLastItemVisible,
    scrollToItem,
    visibleItemsWithoutSeparators,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );

  const clickHandler = () => {
    const nextItem = getNextItem();
    scrollToItem(nextItem?.entry?.target, "smooth", "start");
  };

  return (
    <Arrow disabled={disabled} onClick={clickHandler} side={false}>
      <svg
        width="8"
        height="15"
        viewBox="0 0 8 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
       >
        <path
          className="hc-icons"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.73574 6.82271C8.09739 7.21501 8.08784 7.82637 7.70711 8.20711L1.70711 14.2071C1.31658 14.5976 0.683418 14.5976 0.292893 14.2071C-0.097631 13.8166 -0.0976309 13.1834 0.292893 12.7929L5.58579 7.5L0.292895 2.20711C-0.0976294 1.81658 -0.0976293 1.18342 0.292895 0.792893C0.683419 0.40237 1.31658 0.40237 1.70711 0.792894L7.70711 6.79289C7.71703 6.80282 7.72658 6.81275 7.73574 6.82271Z"
          fill="#151515"
        />
      </svg>
    </Arrow>
  );
}
