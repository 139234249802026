import { SERP_SHOW, SERP_PLATFORMS, SERP_SORT } from "../../config";
import _ from "lodash";

export const isSelectedShowApplicable = (
  selectedPlatform,
  selectedShow,
  hasInstitution,
  isLMS
) => {
  const showByMap = new Map();
  if (isLMS) {
    showByMap.set("all", [
      SERP_SHOW.AVAILABLE,
      SERP_SHOW.SAMPLES,
      SERP_SHOW.COMING_SOON,
      SERP_SHOW.INSTITUTION,
    ]);
    showByMap.set(SERP_PLATFORMS.REVEL.type, [
      SERP_SHOW.AVAILABLE,
      SERP_SHOW.INSTITUTION,
    ]);
    showByMap.set(SERP_PLATFORMS.ETEXT.type, [
      SERP_SHOW.AVAILABLE,
      SERP_SHOW.INSTITUTION,
    ]);
    showByMap.set(SERP_PLATFORMS.CUSTOM.type, [SERP_SHOW.AVAILABLE]);
    showByMap.set(SERP_PLATFORMS.CLASS_TEST.type, [SERP_SHOW.INSTITUTION]);
    showByMap.set(SERP_PLATFORMS.REVIEWER.type, []);
    showByMap.set(SERP_PLATFORMS.APPLICATION.type, []);
  } else {
    if (hasInstitution) {
      showByMap.set("all", [
        SERP_SHOW.AVAILABLE,
        SERP_SHOW.SAMPLES,
        SERP_SHOW.COMING_SOON,
        SERP_SHOW.INSTITUTION,
      ]);
      showByMap.set(SERP_PLATFORMS.REVEL.type, [
        SERP_SHOW.AVAILABLE,
        SERP_SHOW.SAMPLES,
        SERP_SHOW.COMING_SOON,
        SERP_SHOW.INSTITUTION,
      ]);
      showByMap.set(SERP_PLATFORMS.ETEXT.type, [
        SERP_SHOW.AVAILABLE,
        SERP_SHOW.INSTITUTION,
      ]);
      showByMap.set(SERP_PLATFORMS.CLASS_TEST.type, [SERP_SHOW.INSTITUTION]);
    } else {
      showByMap.set("all", [
        SERP_SHOW.AVAILABLE,
        SERP_SHOW.SAMPLES,
        SERP_SHOW.COMING_SOON,
      ]);
      showByMap.set(SERP_PLATFORMS.REVEL.type, [
        SERP_SHOW.AVAILABLE,
        SERP_SHOW.SAMPLES,
        SERP_SHOW.COMING_SOON,
      ]);
      showByMap.set(SERP_PLATFORMS.ETEXT.type, [SERP_SHOW.AVAILABLE]);
      showByMap.set(SERP_PLATFORMS.CLASS_TEST.type, []);
    }
    showByMap.set(SERP_PLATFORMS.COLLECTION.type, [SERP_SHOW.AVAILABLE]);
    showByMap.set(SERP_PLATFORMS.APPLICATION.type, []);
    showByMap.set(SERP_PLATFORMS.REVIEWER.type, []);
    showByMap.set(SERP_PLATFORMS.CUSTOM.type, [SERP_SHOW.AVAILABLE]);
  }

  const showByItems = showByMap.get(selectedPlatform);
  const tempShowByItem = _.find(showByItems, function (showByItem) {
    return showByItem.type === selectedShow;
  });

  if (tempShowByItem) {
    return true;
  } else {
    return false;
  }
};

export const isSelectedSortApplicable = (selectedPlatform, selectedSort) => {
  const sortByMap = new Map();
  sortByMap.set("all", [
    SERP_SORT.TITLE,
    SERP_SORT.AUTHOR,
    SERP_SORT.PUBLICATION_DATE,
  ]);
  sortByMap.set(SERP_PLATFORMS.REVEL.type, [
    SERP_SORT.TITLE,
    SERP_SORT.AUTHOR,
    SERP_SORT.PUBLICATION_DATE,
  ]);
  sortByMap.set(SERP_PLATFORMS.ETEXT.type, [
    SERP_SORT.TITLE,
    SERP_SORT.PUBLICATION_DATE,
  ]);
  sortByMap.set(SERP_PLATFORMS.CUSTOM.type, [
    SERP_SORT.TITLE,
    SERP_SORT.AUTHOR,
    SERP_SORT.PUBLICATION_DATE,
  ]);
  sortByMap.set(SERP_PLATFORMS.CLASS_TEST.type, [
    SERP_SORT.TITLE,
    SERP_SORT.AUTHOR,
    SERP_SORT.PUBLICATION_DATE,
  ]);
  sortByMap.set(SERP_PLATFORMS.REVIEWER.type, [
    SERP_SORT.TITLE,
    SERP_SORT.AUTHOR,
    SERP_SORT.PUBLICATION_DATE,
  ]);
  sortByMap.set(SERP_PLATFORMS.APPLICATION.type, []);

  const sortByItems = sortByMap.get(selectedPlatform);
  const tempSortByItem = _.find(sortByItems, function (sortByItem) {
    return sortByItem.type === selectedSort;
  });

  if (tempSortByItem) {
    return true;
  } else {
    return false;
  }
};
