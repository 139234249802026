import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  PLATFORMS,
  OFFER_TYPES,
  PRODUCT_STAGE,
  ORG_CLASSIFICATION,
  PRODUCT_AUDIENCE,
} from "../../../config";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import "./callToAction.scss";
import { Tooltip, Form, OverlayTrigger } from "react-bootstrap";
import ReactGA from "react-ga4";

export function CallToAction(props) {
  const productData = useSelector((state) => state.product.productData);
  const productOffers = useSelector((state) => state.product.productOffers);
  const currentInstitute = useSelector((state) => state.user.currentInstitute);
  const userData = useSelector((state) => state.user.userData);
  const orgClassification = useSelector(
    (state) => state.user.orgClassification
  );
  const showDDA = useSelector((state) => state.product.showDDA);
  const [formProductData, setFormProductData] = useState(null);
  const [isSeamlessBilling, setIsSeamlessBilling] = useState(true);
  const [isCTAButtonDisable, setIsCTAButtonDisable] = useState(false);
  let history = useHistory();
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);

  const ctaButtonDisable = () => {
    let tempCtaBtnDisable = false;
    if (productOffers) {
      if (
        productOffers &&
        productData &&
        productData.productPlatform !== PLATFORMS.COLLECTION
      ) {
        if (productOffers.comingSoon === true) {
          tempCtaBtnDisable = true;
        } else if (!productOffers.offers) {
          tempCtaBtnDisable = true;
        }
      }

      if (
        userData &&
        productData &&
        productData.productPlatform !== PLATFORMS.COLLECTION
      ) {
        if (
          userData.organizations &&
          userData.isInstructor &&
          userData.organizations.length === 0
        ) {
          let doExist = null;
          doExist = _.find(userData.permissions, function (permission) {
            return (
              permission === "initiate_aiv" ||
              permission === "create_revel_course" ||
              permission === "create_reveltest_course"
            );
          });
          if (!doExist) {
            tempCtaBtnDisable = true;
          }
        }
      }

      if (
        orgClassification === ORG_CLASSIFICATION.HIGHER_ED &&
        productData &&
        productData.audience === PRODUCT_AUDIENCE.K12
      ) {
        tempCtaBtnDisable = true;
      }
    } else {
      if (
        (productData &&
          userData &&
          productData.productStage === PRODUCT_STAGE.REVIEW &&
          userData.organizations &&
          userData.organizations.length > 0) ||
        (orgClassification === ORG_CLASSIFICATION.HIGHER_ED &&
          productData &&
          productData.audience === PRODUCT_AUDIENCE.K12)
      ) {
        tempCtaBtnDisable = false;
      } else {
        tempCtaBtnDisable = true;
      }
    }
    setIsCTAButtonDisable(tempCtaBtnDisable);
  };

  useEffect(() => {
    let otherFormData = {};
    let orgId = null;
    let directBillOffer = null;
    let seatLicenseOffer = null;

    if (productOffers) {
      orgId = productOffers.orgId;
      directBillOffer = _.find(productOffers.offers, function (offer) {
        return offer.offerType.offerTypeCode === OFFER_TYPES.DIRECT_BILL;
      });
      seatLicenseOffer = _.find(productOffers.offers, function (offer) {
        if (currentInstitute) {
          if (queryParams.has("integration_context_token")) {
            return (
              offer.offerType.offerTypeCode === OFFER_TYPES.SEAT_LICENSE &&
              offer.orgId === currentInstitute.id
            );
          } else {
            return (
              offer.offerType.offerTypeCode === OFFER_TYPES.SEAT_LICENSE &&
              offer.orgId === currentInstitute.organization.orgId
            );
          }
        }
      });

      if (seatLicenseOffer) {
        otherFormData = {
          isSeatLicense: true,
          orgId: orgId,
          offerId: seatLicenseOffer.offerId,
          licenseId: seatLicenseOffer.licenseId,
        };
      } else if (showDDA) {
        if (!isSeamlessBilling) {
          otherFormData = { isDirectBill: false, orgId: orgId };
        } else {
          otherFormData = {
            isDirectBill: true,
            orgId: orgId,
            offerId: directBillOffer && directBillOffer.offerId,
          };
        }
      } else {
        if (directBillOffer) {
          otherFormData = {
            isDirectBill: true,
            orgId: orgId,
            offerId: directBillOffer.offerId,
          };
        } else {
          otherFormData = {
            isSeatLicense: false,
            isDirectBill: false,
            orgId: orgId,
          };
        }
      }
    }

    let tempProductData = { ...productData, ...otherFormData };
    if (productData && productData.productStage === PRODUCT_STAGE.REVIEW) {
      if (
        currentInstitute &&
        currentInstitute.organization &&
        currentInstitute.organization.orgId
      ) {
        tempProductData.orgId = currentInstitute.organization.orgId;
      }
      tempProductData.isDirectBill = false;
    }
    setFormProductData(tempProductData);
  }, [productOffers, currentInstitute]);

  useEffect(() => {
    ctaButtonDisable();
  }, [productOffers]);

  const getDemoBtn = () => {
    return (
      <React.Fragment>
        {productData &&
          productData.potentialAction &&
          productData.potentialAction.previewAction &&
          productData.potentialAction.previewAction.target &&
          productData.potentialAction.previewAction.target.url &&
          productData.productPlatform !== PLATFORMS.ETEXT &&
          productData.productPlatform !== PLATFORMS.LEARNING_APPLICATIONS && (
            <button
              className="custom-btn outline-btn view-demo-btn"
              onClick={() =>
                window.open(
                  productData.potentialAction.previewAction.target.url
                )
              }>
              View demo
              <span className="sr-only" id="viewDemo">
                Opens in a new tab
              </span>
            </button>
          )}
      </React.Fragment>
    );
  };

  const changeActionUrl = (url) => {
    let changedUrl = url;
    if (queryParams.has("returnurl")) {
      let res = url.substring(0, 2);
      if (res === "//") {
        let urlTokens = url.split("/");
        urlTokens.splice(0, 3);
        changedUrl = urlTokens.join("/");
        changedUrl = queryParams.get("returnurl") + "/" + changedUrl;
      }
    }
    return changedUrl;
  };

  const postToConsole = () => {
    ReactGA.event({
      category: "CTA",
      action: "click CTA button",
      label: productData.potentialAction.useAction[0].name,
    });
    window.localStorage.setItem("exchange.user.ctanavigated", "true");
    const form = document.createElement("form");
    form.method = "POST";
    form.action = `${changeActionUrl(
      productData.potentialAction.useAction[0].target.urlTemplate
    )}${encodeURIComponent(window.location.href)}`;
    const hfProduct = document.createElement("input");
    const hfToken = document.createElement("input");
    hfProduct.setAttribute("type", "hidden");
    hfProduct.setAttribute("name", "product");
    hfProduct.setAttribute("value", JSON.stringify(formProductData));
    form.appendChild(hfProduct);
    hfToken.setAttribute("type", "hidden");
    hfToken.setAttribute("name", "piToken");
    hfToken.setAttribute("value", userData.credentials.token);
    form.appendChild(hfToken);
    document.body.appendChild(form);
    form.submit();
  };

  const postToLMS = () => {
    ReactGA.event({
      category: "CTA",
      action: "click CTA button",
      label: productData.potentialAction.useAction[0].name,
    });
    window.localStorage.setItem("exchange.user.ctanavigated", "true");
    delete formProductData.orgId;
    delete formProductData.isDirectBill;
    delete formProductData.isSeatLicense;
    const form = document.createElement("form");
    form.method = "POST";
    form.action = `${window.location.protocol}${
      productData.potentialAction.useAction[0].target.urlTemplate
    }${queryParams.get("integration_context_token")}`;
    const hfProduct = document.createElement("input");
    const hfToken = document.createElement("input");
    hfProduct.setAttribute("type", "hidden");
    hfProduct.setAttribute("name", "product");
    hfProduct.setAttribute("value", JSON.stringify(formProductData));
    form.appendChild(hfProduct);
    hfToken.setAttribute("type", "hidden");
    hfToken.setAttribute("name", "piToken");
    hfToken.setAttribute("value", userData.credentials.token);
    form.appendChild(hfToken);
    document.body.appendChild(form);
    form.submit();
  };

  const redirectCollection = () => {
    ReactGA.event({
      category: "CTA",
      action: "click CTA button",
      label: productData.potentialAction.useAction[0].name,
    });
    if (
      productData.potentialAction &&
      productData.potentialAction.useAction &&
      productData.potentialAction.useAction.length > 0 &&
      productData.potentialAction.useAction[0].target
    ) {
      if (productData.potentialAction.useAction[0].target.url) {
        window.open(productData.potentialAction.useAction[0].target.url);
      } else if (productData.potentialAction.useAction[0].target.urlTemplate) {
        window.open(
          productData.potentialAction.useAction[0].target.urlTemplate
        );
      }
    }
  };

  const ddaOptionHandler = (event) => {
    let otherFormData = {};
    if (productOffers) {
      if (event.target.defaultValue === "access_card_billing") {
        setIsSeamlessBilling(false);
        otherFormData = { isDirectBill: false,  orgId: productOffers.orgId };
        let tempProductData = { ...productData, ...otherFormData };
        setFormProductData(tempProductData);
      } else {
        setIsSeamlessBilling(true);
        let dBOffer = _.find(productOffers.offers, function (offer) {
          return offer.offerType.offerTypeCode === OFFER_TYPES.DIRECT_BILL;
        });
        otherFormData = { isDirectBill: true, orgId: productOffers.orgId, offerId: dBOffer && dBOffer.offerId};
        let tempProductData = { ...productData, ...otherFormData };
        setFormProductData(tempProductData);
      }
    }   
  };

  const getDDACTA = () => {
    let config = window.__env.configs["appConfig"];

    return (
      <React.Fragment>
        <div className="pdp-rightside-wr">
          <div className="pdp-btn-container">
            <button
              className="custom-btn primary-btn"
              onClick={() =>
                queryParams.get("integration_context_token")
                  ? postToLMS()
                  : postToConsole()
              }>
              {productData.potentialAction.useAction[0].name}
            </button>
            {getDemoBtn()}
          </div>
        </div>
        <div className="pdp-rightside-wr">
          <div className="dda-access-info">
            <h3 className="opt-title-dda my-0">Student access options</h3>

            <Form className="dda-access d-flex flex-column">
              <OverlayTrigger
                placement="top-start"
                trigger="hover"
                overlay={
                  <Tooltip
                    id="seamless-billing"
                    className="fade show tooltip radio-btn-tooltip">
                    {" "}
                    Institutional Access to start your subscription immediately
                  </Tooltip>
                }>
                {({ ref, ...triggerHandler }) => (
                  <div className="radio-btn-access d-flex align-items-center">
                    <input
                      id="option-1"
                      value="seamless_billing"
                      checked={isSeamlessBilling}
                      onClick={(event) => ddaOptionHandler(event)}
                      type="radio"
                      name="select"
                    />
                    <label
                      for="option-1"
                      ref={ref}
                      {...triggerHandler}
                      className="radio-btn-label seam-lessbilling">
                      Seamless billing
                    </label>
                  </div>
                )}
              </OverlayTrigger>

              <OverlayTrigger
                placement="top-start"
                trigger="hover"
                overlay={
                  <Tooltip
                    id="access-card"
                    className="fade show tooltip radio-btn-tooltip">
                    Students can redeem an access code from a physical card
                    purchased from the campus bookstore
                  </Tooltip>
                }>
                {({ ref, ...triggerHandler }) => (
                  <div className="radio-btn-access d-flex align-items-center">
                    <input
                      id="option-2"
                      value="access_card_billing"
                      checked={!isSeamlessBilling}
                      onClick={(event) => ddaOptionHandler(event)}
                      type="radio"
                      name="select"
                    />
                    <label
                      for="option-2"
                      ref={ref}
                      {...triggerHandler}
                      className="radio-btn-label access-card">
                      Access card
                    </label>
                  </div>
                )}
              </OverlayTrigger>
            </Form>

            <p className="offer-info-dda institute-txt">
              This course will be taught at: <br />
              {`${
                currentInstitute.organization.name &&
                currentInstitute.organization.name > 20
                  ? `${currentInstitute.organization.name.substring(0, 20)}...`
                  : currentInstitute.organization.name.charAt(0).toUpperCase() +
                    currentInstitute.organization.name.slice(1)
              } -
               
              ${
                currentInstitute.organization.location.city &&
                currentInstitute.organization.location.city
                  .charAt(0)
                  .toUpperCase() +
                  currentInstitute.organization.location.city.slice(1)
              },
               
              ${
                currentInstitute.organization.location.state &&
                currentInstitute.organization.location.state.toUpperCase()
              },
              
              ${
                currentInstitute.organization.location.country &&
                currentInstitute.organization.location.country.toUpperCase()
              }`}
            </p>
            <p className="offer-info-dda mb-0">
              If this is not the correct institution, make that change in{" "}
              <a
                href={`${
                  config.CONSOLE_URL
                }/account/manage/account?returnUrl=${encodeURIComponent(
                  decodeURIComponent(window.location.href)
                )}`}
                target="_blank"
                onClick={() =>
                  window.localStorage.removeItem(
                    "exchange.user.defaultInstitute"
                  )
                }
                rel="noopener noreferrer"
                className="text-link"
                aria-describedby="accountSetting">
                Account settings.
                <span
                  className="sr-only"
                  id="accountSetting">
                  Opens in a new tab
                </span>
              </a>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getNonDDACTA = () => {
    return (
      <div className="pdp-btn-container">
        {!isCTAButtonDisable ? (
          <button
            className="custom-btn primary-btn"
            onClick={() =>
              queryParams.get("integration_context_token")
                ? postToLMS()
                : postToConsole()
            }>
            {productData.potentialAction.useAction[0].name}
          </button>
        ) : (
          <button
            className="custom-btn disabled-btn"
            disabled
            aria-disabled="true">
            {productData.potentialAction.useAction[0].name}
          </button>
        )}
        {getDemoBtn()}
      </div>
    );
  };

  const getStudentCTA = () => {
    return (
      <div className="pdp-btn-container">
        {isCTAButtonDisable ? (
        <button
          className="custom-btn disabled-btn"
          disabled
          aria-disabled="true">
          {productData.potentialAction.useAction[0].name}
        </button>) : 
        productData &&
        productData.productPlatform &&
        productData.productPlatform !== PLATFORMS.COLLECTION ? (
          <button
            className="custom-btn primary-btn"
            onClick={() => joinCourseClickHandler()}>
            {productData.potentialAction.useAction[0].name}
          </button>
        ) : (
          <button
            className="custom-btn primary-btn"
            onClick={() => redirectCollection()}>
            {productData.potentialAction.useAction[0].name}
          </button>
        )}
        {getDemoBtn()}
      </div>
    );
  };

  const getAnonymousCTA = () => {
    return (
      <div className="pdp-btn-container">
        <button
          className="custom-btn primary-btn"
          onClick={() => signInClickHandler()}>
          Sign in
        </button>
        {getDemoBtn()}
      </div>
    );
  };

  const getInstructorCTA = () => {
    return (
      <React.Fragment>
        {showDDA && getDDACTA()}
        {!showDDA && getNonDDACTA()}
      </React.Fragment>
    );
  };

  const getCollectionCTA = () => {
    return (
      <div className="pdp-btn-container">
        <button
          className="custom-btn primary-btn"
          onClick={() => redirectCollection()}>
          {productData.potentialAction.useAction[0].name}
        </button>
      </div>
    );
  };
  const getCTAButton = () => {
    if (!userData) {
      return getAnonymousCTA();
    } else {
      if (
        productData.potentialAction &&
        productData.potentialAction.useAction &&
        productData.potentialAction.useAction.length > 0
      ) {
        if (userData && userData.id && userData.isInstructor) {
          if (productData.productPlatform === PLATFORMS.COLLECTION) {
            return getCollectionCTA();
          } else {
            return getInstructorCTA();
          }
        } else if (userData && userData.id && !userData.isInstructor) {
          return getStudentCTA();
        }
      }
    }
  };

  const signInClickHandler = () => {
    ReactGA.event({
      category: "CTA",
      action: "click Sign in",
    });
    window.piSession.login();
  };

  const joinCourseClickHandler = () => {
    ReactGA.event({
      category: "CTA",
      action: "click CTA button",
      label: productData.potentialAction.useAction[0].name,
    });
    history.push(
      `${productData.potentialAction.useAction[0].target.urlTemplate}${window.location.href}`
    );
  };

  return <React.Fragment>{productData && getCTAButton()}</React.Fragment>;
}
