import React, { useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import "./btnTooltip.scss";


export function BtnToolTip({ institutePicker }) {
  const iplToolTipRef = useRef(null);
  const pdpToolTipRef = useRef(null);
  const gotItLinkRef = useRef(null);
  const colLinkRef = useRef(null);

  const closeToolTip = (e) => {
    if (
      gotItLinkRef.current &&
      gotItLinkRef.current.parentElement &&
      gotItLinkRef.current.parentElement.parentElement &&
      gotItLinkRef.current.parentElement.parentElement.classList &&
      gotItLinkRef.current.parentElement.parentElement.classList.contains(
        "show"
      )
    ) {
      e.preventDefault();
      iplToolTipRef.current.click();
      iplToolTipRef.current.focus();
    }

  };

  
  const shiftTab = (e) => {
    const activeEl = document.activeElement.getAttribute("id");
    if (e.key === "Tab") {
      if (e.shiftKey) {

        switch (activeEl) {
          case "noncollection-link":
            if(iplToolTipRef.current) {
              iplToolTipRef.current.focus();
              window.scrollTo(0, 0);
              e.preventDefault();
            }
            
            break;
          case "collection-link":
            if(pdpToolTipRef.current) {
              pdpToolTipRef.current.focus();
              window.scrollTo(0, 0);
              e.preventDefault();
            }
            break;
          default:
            
        }
      } else {

        switch (activeEl) {
          case "tool-tip-triger2":
            if(gotItLinkRef.current) {
              e.preventDefault();
              gotItLinkRef.current.focus();
            }
            break;
          case "tool-tip-triger":
            if(colLinkRef.current) {
              e.preventDefault();
              colLinkRef.current.focus();
            }
            break;
          case "noncollection-link":
            if(iplToolTipRef.current) {
              e.preventDefault();
              iplToolTipRef.current.focus();
            }
            break;
          case "collection-link":
          if(pdpToolTipRef.current) {
            e.preventDefault();
            pdpToolTipRef.current.focus();
          }
          break;
          default:
            
        }
      }
    }
  };


  const hideTooltipWhenEsc = (e) => {
    const key = e.key;

    switch (key) {
      case "Escape":
        if (
          document.activeElement.getAttribute("id") === "tool-tip-triger2" &&
          document.activeElement.hasAttribute("aria-describedby") &&
          iplToolTipRef.current
        ) {
          iplToolTipRef.current.focus();
          iplToolTipRef.current.click();
          e.preventDefault();
        } else if (
          document.activeElement.getAttribute("id") === "tool-tip-triger" && 
          document.activeElement.hasAttribute("aria-describedby") &&
          pdpToolTipRef.current
        ) {
          pdpToolTipRef.current.focus();
          pdpToolTipRef.current.click();
          e.preventDefault();
        }
        break;
    }

  };                                            
                                                                           
  function handleTouchStart(evt) {
    //iPhone specifix defect fix
    if (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {

      const touchEl = evt.target.id;
      switch (touchEl) {
        
        case "tool-tip-triger2":
          setTimeout(() => {
            if(gotItLinkRef.current !== null) {
              gotItLinkRef.current.focus();
            }
          }, 100);
          break;

        case "noncollection-link":
          setTimeout(() => {
            if(iplToolTipRef.current !== null) {
              iplToolTipRef.current.focus();
            }
          }, 100);
          break;

        case "tool-tip-triger":
          setTimeout(() => {
            if(colLinkRef.current !== null) {
              colLinkRef.current.focus();
            }
          }, 100);
          break;
        default:
      }
    } 
                                
  };           

  useEffect(() => {
    document.addEventListener("keyup", hideTooltipWhenEsc);
    document.addEventListener("keydown", shiftTab);
    document.addEventListener('touchstart', handleTouchStart, false); 

    return () => {
      document.removeEventListener("keyup", hideTooltipWhenEsc);
      document.removeEventListener("keydown", shiftTab);
      document.removeEventListener('touchstart', handleTouchStart, false); 
    };

  }, []);
  

  return (

    <React.Fragment>
      <OverlayTrigger
        placement="bottom-end"
        trigger="click"
        overlay={
          <Tooltip
            id="info-button-tooltip"
            className="fade show tooltip bs-tooltip-bottom custom-btn-tooltip">
            {institutePicker ? (
              <>
                <p className="mb-2 btn-tooltip-txt highlighted-txt">
                  <b>Select where you will be teaching this course.</b>
                </p>
                <p className="mb-2 btn-tooltip-txt">
                  Pricing varies by institution.
                </p>
                <a
                  id="noncollection-link"
                  ref={gotItLinkRef}
                  href="javascript:void(0);"
                  onClick={(e) => {
                    closeToolTip(e);
                  }}
                  className="text-link d-flex justify-content-end pearson-collink serp">
                  Got it
                </a>
              </>
            ) : (
              <p className="mb-0 btn-tooltip-txt">
                See more information about{" "}
                <a
                  id="collection-link"
                  ref={colLinkRef}
                  href="https://www.pearsonhighered.com/collections/index.html"
                  className="text-link pearson-collink"
                  target="_blank"
                  rel="noreferrer">
                  <span className="sr-only" id="viewDemo">
                    Opens in a new tab
                  </span>
                  Pearson Collection.
                </a>
              </p>
            )}
            {propTypes.text}
          </Tooltip>
        }>
        <a
          id={institutePicker ? "tool-tip-triger2" : "tool-tip-triger"}
          href="#"
          ref={institutePicker ? iplToolTipRef : pdpToolTipRef}
          className="d-inline-flex align-items-center tool-tip-btn"
          aria-label={
            institutePicker
              ? "Institute info tooltip"
              : "Collection info tooltip"
          }></a>
      </OverlayTrigger>
    </React.Fragment>
  );

}