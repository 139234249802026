import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDiscipline } from "./disciplineApi";

const initialState = {
  disciplineList: null,
  selectedDisciplineCategory: null,
  selectedPrimaryDiscipline: null,
  isDisciplineInternalError: false,
};

export const disciplineAsync = createAsyncThunk(
  "discipline/fetchDiscipline",
  async () => {
    const response = await fetchDiscipline();
    return response.data;
  }
);

export const disciplineSlice = createSlice({
  name: "discipline",
  initialState,
  reducers: {
    updateSelectedDisciplineCategory: (state, action) => {
      state.selectedDisciplineCategory = action.payload;
    },
    updateSelectedPrimaryDiscipline: (state, action) => {
      state.selectedPrimaryDiscipline = action.payload;
    },
    updateIsDisciplineInternalError: (state, action) => {
      state.isDisciplineInternalError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(disciplineAsync.fulfilled, (state, action) => {
        state.disciplineList = action.payload;
        state.isDisciplineInternalError = false;
      })
      .addCase(disciplineAsync.rejected, (state, action) => {
        if (action && action.error && action.error.message.includes("500")) {
          state.isDisciplineInternalError = true;
        }
      });
  },
});

export const {
  updateSelectedDisciplineCategory,
  updateSelectedPrimaryDiscipline,
  updateIsDisciplineInternalError,
} = disciplineSlice.actions;
export default disciplineSlice.reducer;
