import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { SERP_SORT, SERP_PLATFORMS } from "../../../config";
import { updateSelectedSort, resetSelectedSort } from "./sortSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { updateSendSearchRequest } from "../search/searchSlice";
import { updateCurrentResultPage } from "../searchResult/searchResultSlice";
import { resetDisciplineCount } from "../subject/subjectSlice";

export function Sort(props) {
  const dispatch = useDispatch();
  const selectedSort = useSelector((state) => state.sort.selectedSort);
  const selectedPlatform = useSelector(
    (state) => state.platform.selectedPlatform
  );
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const [sortItems, setSortItems] = useState([]);

  useEffect(() => {
    setSortItems((prevState) => []);

    if (selectedPlatform && selectedPlatform === SERP_PLATFORMS.ETEXT.type) {
      setSortItems((prevState) => [...prevState, SERP_SORT.TITLE]);
      setSortItems((prevState) => [...prevState, SERP_SORT.PUBLICATION_DATE]);
      if (selectedSort === SERP_SORT.AUTHOR.type) {
        dispatch(resetSelectedSort());
      }
    } else {
      setSortItems((prevState) => [...prevState, SERP_SORT.TITLE]);
      setSortItems((prevState) => [...prevState, SERP_SORT.AUTHOR]);
      setSortItems((prevState) => [...prevState, SERP_SORT.PUBLICATION_DATE]);
    }
  }, [selectedPlatform]);

  const sortClickHandler = (event) => {
    dispatch(resetDisciplineCount());
    dispatch(updateCurrentResultPage(1));
    dispatch(
      updateSelectedSort({
        type: event.target.value,
        displayValue: event.target.labels[0].innerText,
      })
    );
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
  };

  const validateSortByChecked = (type) => {
    if (!selectedSort) {
      return false;
    } else {
      if (type === selectedSort) {
        ReactGA.event({
          category: "Facet",
          action: "click Sort By on SERP filter by Platform",
          label: selectedSort,
        });
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Form>
      <div key="sort-by" className="sort-by col-12">
        {sortItems.map((sortType, index) => (
          <Form.Check
            label={sortType.displayValue}
            name="sort"
            type="radio"
            id={sortType.type}
            value={sortType.type}
            checked={validateSortByChecked(sortType.type)}
            onChange={(event) => sortClickHandler(event)}
            key={index}
          />
        ))}
      </div>
    </Form>
  );
}
