import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  autocompleteAsync,
  chngIsAutoComplete,
  chngeSearchValue,
  resetAutoComplete,
  updateSendSearchRequest,
  resetSearch,
  updateIsFocusCloseButton,
  updateIsMobileSearchView,
} from "./searchSlice";
import { AutoComplete } from "./AutoComplete";
import { Form, FormControl, Button } from "react-bootstrap";
import {
  AUTO_COMPLETE_LENGTH,
  SEARCH_PAGE_RESULT_COUNT,
  PAGES,
  ORG_CLASSIFICATION,
  SERP_PLATFORMS,
} from "../../../config";
import "./search.scss";
import {
  searchResultAsync,
  resetSearchResult,
  reinitializedSearchResult,
  updateIsRedirectToSearch,
  reinitializedK12SearchResult,
  reinitializedSearchResultStatus,
} from "../searchResult/searchResultSlice";
import {
  disciplineCountAsync,
  resetSubject,
  resetDisciplineCount,
} from "../subject/subjectSlice";
import { changeIsInstituteUpdated } from "../exHeader/userSlice";
import { resetSelectedPlatform } from "../platform/platformSlice";
import { resetSelectedShow } from "../show/showSlice";
import { resetSelectedSort } from "../sort/sortSlice";
import ReactGA from "react-ga4";
import useRefPlatforms from "../../util/useRefPlatforms";
import { updateIsDisplay } from "../hamburger/hamburgerSlice";
import { ReactComponent as SearchIcon } from "../../widgets/search/images/search-icon.svg";
import { ReactComponent as SearchCloseIcon } from "../../widgets/search/images/search-close-icon.svg";

export function Search(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  const refBtnClose = useRef(null);
  const searchInputRef = React.useRef(null);
  let queryParams = new URLSearchParams(location.search);
  const searchValue = useSelector((state) => state.search.searchValue);
  const currentResultPage = useSelector(
    (state) => state.searchResult.currentResultPage
  );
  const selectedPlatform = useSelector(
    (state) => state.platform.selectedPlatform
  );
  const autoCompleteList = useSelector(
    (state) => state.search.autoCompleteList
  );
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const isAutoComplete = useSelector((state) => state.search.isAutoComplete);
  const selectedSort = useSelector((state) => state.sort.selectedSort);
  const selectedShow = useSelector((state) => state.show.selectedShow);
  const currentInstitute = useSelector((state) => state.user.currentInstitute);
  const isInstituteUpdated = useSelector(
    (state) => state.user.isInstituteUpdated
  );
  const isFocusCloseButton = useSelector(
    (state) => state.search.isFocusCloseButton
  );

  const isMobileSearchView = useSelector(
    (state) => state.search.isMobileSearchView
  );
  const orgClassification = useSelector(
    (state) => state.user.orgClassification
  );

  const [handleRefPlatform] = useRefPlatforms();

  const getSearchReqParams = (resultType) => {
    let searchRequestPayload = {};
    searchRequestPayload.searchValue = searchValue
      ? encodeURIComponent(searchValue)
      : "";
    if (queryParams.get("cat")) {
      searchRequestPayload.category = queryParams.get("cat");
    } else {
      searchRequestPayload.category = "";
    }
    if (
      currentInstitute &&
      currentInstitute.organization &&
      currentInstitute.organization.location &&
      currentInstitute.organization.location.country &&
      currentInstitute.organization.location.country.length > 0
    ) {
      searchRequestPayload.country =
        currentInstitute.organization.location.country.toUpperCase();
      searchRequestPayload.institution = currentInstitute.organization.orgId;
    } else {
      searchRequestPayload.country = "";
      searchRequestPayload.institution = "";
    }
    if (queryParams.get("disc")) {
      searchRequestPayload.discipline = queryParams.get("disc");
    } else {
      searchRequestPayload.discipline = "";
    }
    searchRequestPayload.featureFlag = false;
    searchRequestPayload.limit = SEARCH_PAGE_RESULT_COUNT;
    searchRequestPayload.offset =
      (currentResultPage - 1) * SEARCH_PAGE_RESULT_COUNT;
    if (selectedPlatform === null) {
      searchRequestPayload.type = SERP_PLATFORMS.ALL.type;
    } else {
      searchRequestPayload.type = selectedPlatform;
    }
    if (selectedSort) {
      searchRequestPayload.sort = selectedSort;
    } else {
      searchRequestPayload.sort = "";
    }
    if (selectedShow) {
      searchRequestPayload.show = selectedShow;
    } else {
      searchRequestPayload.show = "";
    }
    if (queryParams.has("integration_context_token")) {
      searchRequestPayload.token = queryParams.get("integration_context_token");
    } else {
      searchRequestPayload.token = "";
    }
    searchRequestPayload.orgClassification = orgClassification;
    searchRequestPayload.resultType = resultType;
    return searchRequestPayload;
  };

  const searchTxtKeyPressHandler = (event) => {
    if (event.key === 'Enter') {
      searchBtnClickHandler(event);
    }
  };

  const searchBtnClickHandler = (event) => {
    ReactGA.event({
      category: "search",
      action: "click search event completed",
    });
    ReactGA.event({
      category: "search",
      action: "searched keyword",
      label: searchValue,
    });
    event.preventDefault();
    if (searchValue && searchValue.length > 0) {
      if (props.cPage === PAGES.PDP) {
        dispatch(resetSubject());
        dispatch(resetSelectedPlatform());
        dispatch(resetSelectedShow());
        dispatch(resetSelectedSort());
      }
      dispatch(reinitializedSearchResult());
      dispatch(resetAutoComplete());
      if (
        props.cPage === PAGES.SERP &&
        queryParams.has("ref") &&
        queryParams.get("ref").toLowerCase() !== "etext" &&
        queryParams.get("ref").toLowerCase() !== "collections"
      ) {
        handleRefPlatform();
      } else if (props.cPage !== PAGES.SERP) {
        handleRefPlatform();
      }
      dispatch(updateIsRedirectToSearch(true));
      dispatch(resetDisciplineCount());
      dispatch(updateSendSearchRequest(sendSearchRequest + 1));
      queryParams.set("q", encodeURIComponent(searchValue));
      if (props.cPage === PAGES.PDP) {
        if (queryParams.has("uuid")) {
          queryParams.delete("uuid");
        }
      }
      history.push(`/search?${queryParams.toString()}`);
      window.document.title = `${searchValue} - Exchange Search`;
    } else {
      window.document.title = "Exchange Search";
      dispatch(resetSubject());
      dispatch(resetSelectedPlatform());
      dispatch(resetSelectedShow());
      dispatch(resetSelectedSort());
      dispatch(resetSearchResult());
      if (queryParams.has("q")) {
        queryParams.delete("q");
      }
      if (queryParams.has("disc")) {
        queryParams.delete("disc");
      }
      if (queryParams.has("cat")) {
        queryParams.delete("cat");
      }
    }
    dispatch(updateIsMobileSearchView(false));
  };

  const autoCompleteHandler = (event) => {
    dispatch(chngeSearchValue(event.target.value));
    if (event.target.value.length >= AUTO_COMPLETE_LENGTH) {
      dispatch(
        autocompleteAsync({
          searchValue: encodeURIComponent(event.target.value),
          country:
            currentInstitute &&
            currentInstitute.organization &&
            currentInstitute.organization.location &&
            currentInstitute.organization.location.country
              ? currentInstitute.organization.location.country.toUpperCase()
              : "",
          orgId:
            currentInstitute && currentInstitute.organization
              ? currentInstitute.organization.orgId
              : "",
          orgClassification: orgClassification,
          token: queryParams.has("integration_context_token")
            ? queryParams.get("integration_context_token")
            : "",
        })
      );
    } else {
      dispatch(chngIsAutoComplete(false));
    }
  };

  const resetSearchValue = () => {
    window.document.title = `Exchange Search`;
    dispatch(chngeSearchValue(""));
    dispatch(resetAutoComplete());
    if (queryParams.has("q")) {
      queryParams.delete("q");
    }
    history.push({
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    if (isInstituteUpdated) {
      dispatch(changeIsInstituteUpdated(false));
    }
  }, [isInstituteUpdated]);

  useEffect(() => {
    if (isFocusCloseButton) {
      refBtnClose.current.focus();
      dispatch(updateIsFocusCloseButton(false));
    }
  }, [isFocusCloseButton]);

  useEffect(() => {
    if (props.cPage === PAGES.SERP) {
      if (sendSearchRequest > 0) {
        if (
          (searchValue && searchValue.length > 0) ||
          (queryParams.get("q") && queryParams.get("q").length > 0) ||
          (queryParams.get("disc") && queryParams.get("disc").length > 0) ||
          (queryParams.get("cat") && queryParams.get("cat").length > 0)
        ) {
          dispatch(reinitializedSearchResultStatus());
          if (orgClassification === ORG_CLASSIFICATION.HIGHER_ED) {
            dispatch(searchResultAsync(getSearchReqParams("highered")));
            dispatch(disciplineCountAsync(getSearchReqParams("highered")));
            dispatch(reinitializedK12SearchResult());
          } else {
            dispatch(searchResultAsync(getSearchReqParams("highered")));
            dispatch(disciplineCountAsync(getSearchReqParams("highered")));
            if (
              currentResultPage === 1 &&
              (!selectedPlatform ||
                selectedPlatform === SERP_PLATFORMS.ALL.type ||
                selectedPlatform === SERP_PLATFORMS.REVEL.type)
            ) {
              dispatch(searchResultAsync(getSearchReqParams("k12")));
              dispatch(disciplineCountAsync(getSearchReqParams("k12")));
            } else {
              dispatch(reinitializedK12SearchResult());
            }
          }
        } else {
          dispatch(reinitializedSearchResultStatus());
          dispatch(resetSearchResult());
          dispatch(resetDisciplineCount());
        }
      }
    }
  }, [sendSearchRequest]);

  useEffect(() => {
    if (queryParams.has("q") && searchValue !== queryParams.get("q")) {
      dispatch(chngeSearchValue(decodeURIComponent(queryParams.get("q"))));
    }
    if (props.cPage !== PAGES.PDP) {
      window.sessionStorage.removeItem("exchange.product.baseRelated");
    }
    if (props.cPage === PAGES.PDP) {
      dispatch(resetSearch());
    }
  }, []);

  const searchBarClass = () => {
    let className = "searchdbar";
    if (searchValue && searchValue.length >= AUTO_COMPLETE_LENGTH) {
      if (autoCompleteList && autoCompleteList.searchResults) {
        className += " searchdbar-active";
      }
    }
    return className;
  };

  const searchBarClose = () => {
    let classNameClose = "searchbar-close-icon";
    if (searchValue && searchValue.length >= 1) {
      classNameClose += " searchbar-close-active";
    }
    return classNameClose;
  };

  const handleOnFocusSearch = () => {
    dispatch(updateIsDisplay(false));
  };

  useEffect(() => {
    window.addEventListener("resize", resizeSearchClickHandler);
    return () => window.removeEventListener("resize", resizeSearchClickHandler);
  }, []);

  useEffect(() => {
    if (isMobileSearchView) {
      searchInputRef.current.focus();
    }
  });

  const searchClickHandler = () => {
    if (window.innerWidth < 992) {
      dispatch(updateIsMobileSearchView(true));
    } else {
      dispatch(updateIsMobileSearchView(false));
    }
  };

  const resizeSearchClickHandler = () => {
    if (isMobileSearchView) {
      searchClickHandler();
    }
  };

  return (
    <React.Fragment>
      <div className="searcbar-wrap">
        <Form role="search" className="d-flex justify-content-center">
          <Form.Label className="sr-only" htmlFor="searchBox">
            Enter Title, Author, ISBN, or Subject keywords
          </Form.Label>
          <FormControl
            className={searchBarClass()}
            ref={searchInputRef}
            id="searchBox"
            autoComplete="off"
            value={searchValue}
            type="text"
            placeholder="Enter Title, Author, ISBN, or Subject keywords"
            autoCapitalize="off"
            spellCheck="false"
            aria-owns="autocomplete-listbox"
            onFocus={handleOnFocusSearch}
            onBlur={(e) =>
              (e.target.placeholder =
                "Enter Title, Author, ISBN, or Subject keywords")
            }
            onChange={(event) => {
              autoCompleteHandler(event);
            }}
            onKeyDown={(event) => searchTxtKeyPressHandler(event)}
            aria-autocomplete="both"
            aria-activedescendant=""
            onClick={() => searchClickHandler()}
          />
          <Button
            aria-label="Search"
            className="searchbar-icon"
            type="submit"
            title="Submit the search query."
            onClick={(event) => {
              searchBtnClickHandler(event);
            }}>
            <SearchIcon />
          </Button>
          <Button
            aria-label="Close"
            type="reset"
            title="Clear the search query."
            className={searchBarClose()}
            ref={refBtnClose}
            onClick={() => resetSearchValue()}>
            <SearchCloseIcon />
          </Button>
        </Form>
        {isAutoComplete && (
          <AutoComplete
            autoCompleteList={autoCompleteList}
            searchValue={searchValue}
            cPage={props.cPage}
          />
        )}
      </div>
    </React.Fragment>
  );
}
