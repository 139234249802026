import React from "react";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../../../../util/offerUtils";
import { PRODUCT_AUDIENCE } from "../../../../../../config";
import "./accessCode.scss";

/**
 * @returns Access Code offer component.
 */
export const AccessCode = (props) => {
  const productData = useSelector((state) => state.product.productData);

  /**
   * Display Access Code offer information.
   * @param {*} accessCodeOffers
   * @returns React Fragment
   */
  const getAccesCodeOfferInfo = (accessCodeOffers) => {
    return (
      <React.Fragment>
        <p className="access-title mb-0">Access codes</p>
        <p className="sub-text mb-0">
          Buy access codes per student, per course
        </p>

        {accessCodeOffers.map((offer, index) => (
          <React.Fragment>
            <div className="d-flex align-items-center justify-content-between available-offer">
              {productData &&
                productData.audience === PRODUCT_AUDIENCE.HIGHER_ED &&
                offer.offerType &&
                offer.offerType.definition &&
                offer.offerType.definition.subscriptionDuration && (
                  <p className="title mb-0">
                    {`${offer.offerType.definition.subscriptionDuration}-day access`}
                  </p>
                )}
              {productData &&
                productData.audience === PRODUCT_AUDIENCE.K12 &&
                offer.title &&
                offer.title.length > 0 && (
                  <p className="title mb-0">{offer.title}</p>
                )}
              {offer.offerType &&
                offer.offerType.definition &&
                offer.offerType.definition.currency && (
                  <p className="title mb-0">
                    {`${getCurrencySymbol(
                      offer.offerType.definition.currency.toUpperCase()
                    )}${offer.offerType.definition.price.toFixed(2)}`}
                    *
                  </p>
                )}
            </div>
            <p className="sub-text mb-0">
              {" "}
              {accessCodeOffers[index].description}{" "}
            </p>

            {offer.offerType &&
              offer.offerType.definition &&
              offer.offerType.definition.isbn &&
              offer.offerType.definition.isbn.isbn13 && (
                <p className="sub-text mb-0">
                  <b>ISBN</b> {offer.offerType.definition.isbn.isbn13}
                </p>
              )}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {props.accessCodeOffers && props.accessCodeOffers.length > 0 && (
        <div className="access-code-wr institutional-licensing-wr">
          {getAccesCodeOfferInfo(props.accessCodeOffers)}
        </div>
      )}
    </React.Fragment>
  );
};
