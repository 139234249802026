import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedShow: window.sessionStorage.getItem("exchange.search.selectedShow")
    ? window.sessionStorage.getItem("exchange.search.selectedShow")
    : null,
  selectedShowDisplayName: window.sessionStorage.getItem(
    "exchange.search.selectedShowDisplayName"
  )
    ? window.sessionStorage.getItem("exchange.search.selectedShowDisplayName")
    : null,
};

export const showSlice = createSlice({
  name: "show",
  initialState,
  reducers: {
    updateSelectedShow: (state, action) => {
      state.selectedShow = action.payload.type;
      state.selectedShowDisplayName = action.payload.displayValue;
      window.sessionStorage.setItem(
        "exchange.search.selectedShow",
        action.payload.type
      );
      window.sessionStorage.setItem(
        "exchange.search.selectedShowDisplayName",
        action.payload.displayValue
      );
    },
    resetSelectedShow: (state, action) => {
      state.selectedShow = null;
      state.selectedShowDisplayName = null;
      window.sessionStorage.removeItem("exchange.search.selectedShow");
      window.sessionStorage.removeItem(
        "exchange.search.selectedShowDisplayName"
      );
    },
  },
});

export const { updateSelectedShow, resetSelectedShow } = showSlice.actions;
export default showSlice.reducer;
