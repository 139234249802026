import React from "react";
import "./product.scss";
import { BtnToolTip } from "../btnToolTip/BtnToolTip";
import {
  PLATFORMS,
  PDP_AUTHORS_LENGTH,
  OVERVIEW_DESCRIPTION_LENGTH,
  PRODUCT_TYPES,
  PAGES,
  PRODUCT_STAGE,
} from "../../../config";
import {
  getProductImage,
  getProductImageClassName,
  getProductBrand,
  getComingSoonOverlayer,
  isProductNameWithEdition,
} from "../../util/productUtil";
import defaultProductImg from "../../images/empty-default-img.png";
import physicalProduct from "../../images/physical-product.png";
import DOMPurify from "dompurify";
import { ReadMore } from "../readMore/ReadMore";
import Moment from "react-moment";

export function Product(props) {
  const getAuthors = () => {
    let authors = props.productData.authors.map((author) => {
      const givenName = author.givenName ? author.givenName : "";
      const familyName = author.familyName ? author.familyName : "";
      const additionalName = author.additionalName ? author.additionalName : "";
      if (additionalName && additionalName.length > 0) {
        return `${givenName} ${additionalName} ${familyName}`;
      } else {
        return `${givenName} ${familyName}`;
      }
    });

    let authorsList = authors.join(", ");
    if (authorsList.length > PDP_AUTHORS_LENGTH) {
      return `${authorsList.slice(0, PDP_AUTHORS_LENGTH)} ...`;
    } else {
      return authorsList;
    }
  };

  const mainProductInfo = () => {
    if (props.productData.productPlatform === PLATFORMS.LEARNING_APPLICATIONS) {
      return (
        <div className="d-flex flex-row">
          <div className={getProductImageClassName(props.productData)}>
            <img
              src={
                getProductImage(props.productData)
                  ? getProductImage(props.productData)
                  : defaultProductImg
              }
              alt=""
              aria-hidden="true"
              onError={(event) => (event.target.src = defaultProductImg)}
            />

            {props.productData.productType !== PRODUCT_TYPES.SAMPLE &&
              props.productData.productType !== PRODUCT_TYPES.BETA &&
              props.productOffers &&
              getComingSoonOverlayer(props.productOffers, PAGES.PDP) && (
                <div className="overlay-badge-pdp">
                  <span className="overlay-text-pdp text-center">
                    Coming
                    <br />
                    soon
                  </span>
                </div>
              )}
            {props.productData.productType === PRODUCT_TYPES.SAMPLE && (
              <div className="overlay-badge-pdp">
                <span className="overlay-text-pdp sample text-center">
                  Sample
                  <br />
                  <span className="sub-text">Not for sale</span>
                </span>
              </div>
            )}
            {props.productData.productType === PRODUCT_TYPES.BETA && (
              <div className="overlay-badge-pdp">
                <span className="overlay-text-pdp text-center class-test">
                  Class test
                </span>
              </div>
            )}
          </div>

          <div className="product-information d-flex flex-column">
            <h1 className="product-title my-0">
              {props.productData.name ? props.productData.name : ""}
            </h1>
            {props.productData.authors &&
              props.productData.authors.length > 0 && (
                <div className="product-authors">{getAuthors()}</div>
              )}
            {props.productData.copyrightYear &&
              props.productData.publisher &&
              props.productData.publisher.length > 0 && (
                <div className="product-copyright">
                  © {props.productData.copyrightYear}{" "}
                  {props.productData.publisher}
                </div>
              )}
            {props.productData.isbn && props.productData.isbn.length > 0 && (
              <div className="product-isbn">
                <span>ISBN-13: </span>
                {props.productData.isbn[1]}
              </div>
            )}
            {props.productData.brand &&
              props.productData.brand.name &&
              props.productData.brand.name.length > 0 && (
                <div className="brand d-flex flex-row">
                  {getProductBrand(props.productData)}
                </div>
              )}
          </div>
        </div>
      );
    } else if (props.productData.productPlatform === PLATFORMS.COLLECTION) {
      return (
        <div className="collection-product-info">
          <div className="d-flex flex-row">
            <div className={getProductImageClassName(props.productData)}>
              <img
                src={
                  getProductImage(props.productData)
                    ? getProductImage(props.productData)
                    : defaultProductImg
                }
                alt=""
                aria-hidden="true"
                onError={(event) => (event.target.src = defaultProductImg)}
              />
              {props.productData.productType !== PRODUCT_TYPES.SAMPLE &&
                props.productData.productType !== PRODUCT_TYPES.BETA &&
                getComingSoonOverlayer(props.productData, PAGES.PDP) && (
                  <div className="overlay-badge-pdp">
                    <span className="overlay-text-pdp text-center">
                      Coming
                      <br />
                      soon
                    </span>
                  </div>
                )}
              {props.productData.productType === PRODUCT_TYPES.SAMPLE && (
                <div className="overlay-badge-pdp">
                  <span className="overlay-text-pdp sample text-center">
                    Sample
                    <br />
                    <span className="sub-text">Not for sale</span>
                  </span>
                </div>
              )}
              {props.productData.productType === PRODUCT_TYPES.BETA && (
                <div className="overlay-badge-pdp">
                  <span className="overlay-text-pdp text-center class-test">
                    Class test
                  </span>
                </div>
              )}
            </div>

            <div className="product-information d-flex flex-column">
              <div className="d-flex flex-row">
                <h1
                  className="collection-title mb-0"
                  id="showMoreCollectionInfo">
                  Collection
                </h1>
                <BtnToolTip />
              </div>

              <h2 className="product-title-collection my-0">
                {props.productData.name ? props.productData.name : ""}
              </h2>
              {props.productData.authors &&
                props.productData.authors.length > 0 && (
                  <div className="product-authors">{getAuthors()}</div>
                )}
              {props.productData.moduleCount && (
                <div className="product-modules">{`${props.productData.moduleCount} Modules`}</div>
              )}
              {props.productData.brand &&
                props.productData.brand.name &&
                props.productData.brand.name.length > 0 && (
                  <div className="brand d-flex flex-row">
                    {getProductBrand(props.productData)}
                  </div>
                )}
            </div>
          </div>

          <p className="info-text mb-0">
            The content for this curated Collection is from the following
            textbook:
          </p>
          <div className="d-flex flex-row book-wr">
            <div
              className={`book-img ${getProductImageClassName(
                props.productData
              )}`}>
              <img
                src={physicalProduct}
                alt=""
                onError={(event) => (event.target.src = defaultProductImg)}
              />
            </div>
            <div className="product-information d-flex flex-column">
              <p className="product-sub-title my-0">
                {props.productData.name ? props.productData.name : ""}
              </p>
              {props.productData.authors &&
                props.productData.authors.length > 0 && (
                  <div className="product-authors">{getAuthors()}</div>
                )}
              {props.productData.isbn && props.productData.isbn.length > 0 && (
                <div className="product-isbn">
                  <span>ISBN-13: </span>
                  {props.productData.isbn[1]}
                </div>
              )}

              <div className="brand d-flex flex-row">
                <p className="brand-name mb-0">
                  Platform:
                  <span> Book</span>
                </p>
              </div>
            </div>
          </div>
          <div>
            {props.productData.description &&
              props.productData.description.html &&
              props.productData.description.html.length > 0 && (
                <ReadMore
                  content={DOMPurify.sanitize(
                    props.productData.description.html
                  )}
                  descriptionLength={OVERVIEW_DESCRIPTION_LENGTH}
                  buttonId="showMoreButtonCollectionInfo"
                  headingId="showMoreCollectionInfo"
                />
              )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-row">
          <div className={getProductImageClassName(props.productData)}>
            <img
              src={
                getProductImage(props.productData)
                  ? getProductImage(props.productData)
                  : defaultProductImg
              }
              alt=""
              onError={(event) => (event.target.src = defaultProductImg)}
            />
            {props.productData.productType !== PRODUCT_TYPES.SAMPLE &&
              props.productData.productType !== PRODUCT_TYPES.BETA &&
              props.productOffers &&
              getComingSoonOverlayer(props.productOffers, PAGES.PDP) && (
                <div className="overlay-badge-pdp">
                  <span className="overlay-text-pdp text-center">
                    Coming
                    <br />
                    soon
                  </span>
                </div>
              )}
            {props.productData.productType === PRODUCT_TYPES.SAMPLE && (
              <div className="overlay-badge-pdp">
                <span className="overlay-text-pdp sample text-center">
                  Sample
                  <br />
                  <span className="sub-text">Not for sale</span>
                </span>
              </div>
            )}
            {props.productData.productType === PRODUCT_TYPES.BETA && (
              <div className="overlay-badge-pdp">
                <span className="overlay-text-pdp text-center class-test">
                  Class test
                </span>
              </div>
            )}
          </div>

          <div className="product-information d-flex flex-column">
            <h1 className="product-title my-0">
              {props.productData.name ? props.productData.name : ""}
              {props.productData.bookEdition &&
                props.productData.bookEdition.length > 0 &&
                props.productData.name &&
                !isProductNameWithEdition(props.productData.name) && (
                  <span>{`, ${props.productData.bookEdition}e`}</span>
                )}
            </h1>
            {props.productData.authors &&
              props.productData.authors.length > 0 && (
                <div className="product-authors">{getAuthors()}</div>
              )}
            {props.productData.copyrightYear &&
              props.productData.publisher &&
              props.productData.publisher.length > 0 && (
                <div className="product-copyright">
                  © {props.productData.copyrightYear}{" "}
                  {props.productData.publisher}
                </div>
              )}
            {props.productData.isbn && props.productData.isbn.length > 0 && (
              <div className="product-isbn">
                <span>ISBN-13: </span>
                {props.productData.isbn[1]}
              </div>
            )}
            {props.productData.brand &&
              props.productData.brand.name &&
              props.productData.brand.name.length > 0 && (
                <div className="brand d-flex flex-row">
                  {getProductBrand(props.productData)}
                </div>
              )}
            {props.productData &&
              props.productData.productStage &&
              props.productData.typeSafeContentPublishedDate &&
              props.productData.productStage === PRODUCT_STAGE.REVIEW && (
                <span>
                  Content Published:{" "}
                  <Moment format="MM/DD/YYYY h:mm A">
                    {props.productData.typeSafeContentPublishedDate.replace(
                      "Z",
                      ""
                    )}
                  </Moment>{" "}
                  UTC
                </span>
              )}
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="product-header">{mainProductInfo()}</div>
    </React.Fragment>
  );
}
