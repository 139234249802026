import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { SERP_PLATFORMS } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedPlatform } from "./platformSlice";
import "./platform.scss";
import {
  checkReviewerPermissions,
  checkClassTestPermissions,
} from "../../util/userUtil";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  isSelectedShowApplicable,
  isSelectedSortApplicable,
} from "../../util/platformUtil";
import { resetSelectedShow } from "../show/showSlice";
import { resetSelectedSort } from "../sort/sortSlice";
import { updateSendSearchRequest } from "../search/searchSlice";
import { resetDisciplineCount } from "../subject/subjectSlice";
import {
  updateIsRedirectToSearch,
  updateCurrentResultPage,
} from "../searchResult/searchResultSlice";
import useRefPlatforms from "../../util/useRefPlatforms";
import _ from "lodash";

export function Platform(props) {
  const dispatch = useDispatch();
  const selectedPlatform = useSelector(
    (state) => state.platform.selectedPlatform
  );
  const selectedShow = useSelector((state) => state.show.selectedShow);
  const selectedSort = useSelector((state) => state.sort.selectedSort);
  const userData = useSelector((state) => state.user.userData);
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const isRedirectToSearch = useSelector(
    (state) => state.searchResult.isRedirectToSearch
  );
  const [handleRefPlatform] = useRefPlatforms();
  const [platforms, setPlatforms] = useState([]);
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  const checkRevelConsolePermission = () => {
    let doExist = _.find(userData.permissions, function (permission) {
      return permission === "create_reveltest_course";
    });
    if (doExist) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setPlatforms((prevState) => []);
    if (
      (window.piSession &&
        window.piSession.userId() &&
        userData &&
        userData.id &&
        !isRedirectToSearch) ||
      (window.piSession &&
        !window.piSession.userId() &&
        !isRedirectToSearch &&
        !queryParams.has("integration_context_token"))
    ) {
      handleRefPlatform();
      dispatch(updateIsRedirectToSearch(true));
      dispatch(updateSendSearchRequest(sendSearchRequest + 1));
    }

    if (
      queryParams.has("ref") &&
      queryParams.get("ref").toLowerCase().includes("revel")
    ) {
      setPlatforms((prevState) => [...prevState, SERP_PLATFORMS.REVEL]);
    } else {
      setPlatforms((prevState) => [...prevState, SERP_PLATFORMS.ETEXT]);
      setPlatforms((prevState) => [...prevState, SERP_PLATFORMS.REVEL]);
      if (
        userData &&
        userData.isInstructor &&
        userData.organizations &&
        userData.organizations.length > 0 &&
        !queryParams.has("integration_context_token")
      ) {
        setPlatforms((prevState) => [...prevState, SERP_PLATFORMS.COLLECTION]);
      }
      setPlatforms((prevState) => [...prevState, SERP_PLATFORMS.APPLICATION]);
      if (!queryParams.has("integration_context_token")) {
        if (
          queryParams.has("ref") &&
          queryParams.get("ref").toLowerCase() === "console"
        ) {
          //Do Nothing here
        } else {
          setPlatforms((prevState) => [...prevState, SERP_PLATFORMS.CUSTOM]);
        }
      }
    }
    if (
      userData &&
      userData.isInstructor &&
      userData.permissions &&
      userData.permissions.length > 0 &&
      (checkClassTestPermissions(userData.permissions) ||
        checkRevelConsolePermission())
    ) {
      setPlatforms((prevState) => [...prevState, SERP_PLATFORMS.CLASS_TEST]);
    }

    if (
      userData &&
      userData.isInstructor &&
      userData.permissions &&
      userData.permissions.length > 0 &&
      checkReviewerPermissions(userData.permissions)
    ) {
      setPlatforms((prevState) => [...prevState, SERP_PLATFORMS.REVIEWER]);
    }
  }, [userData]);

  const platformClickHandler = (event) => {
    let hasInstitution = false;
    dispatch(updateCurrentResultPage(1));
    dispatch(resetDisciplineCount());
    if (
      userData &&
      userData.isInstructor &&
      userData.organizations &&
      userData.organizations.length > 0
    ) {
      hasInstitution = true;
    }
    const tempShowApplicable = isSelectedShowApplicable(
      event.target.value,
      selectedShow,
      hasInstitution,
      queryParams.has("integration_context_token")
    );
    if (!tempShowApplicable) {
      dispatch(resetSelectedShow());
    }
    const tempSortApplicable = isSelectedSortApplicable(
      event.target.value,
      selectedSort
    );
    if (!tempSortApplicable) {
      dispatch(resetSelectedSort());
    }
    dispatch(
      updateSelectedPlatform({
        type: event.target.value,
        displayValue: event.target.getAttribute("data-displayvalue"),
      })
    );
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
    ReactGA.event({
      category: "Facet",
      action: "click items on SERP filter by Platform",
      label: event.target.value,
    });
  };

  const validatePlatformByChecked = (type) => {
    if (!selectedPlatform) {
      return false;
    } else {
      if (type === selectedPlatform) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Form>
      <div key={`custom-inline-radio`} className="platform" role="radiogroup">
        {platforms &&
          platforms.map((platform, index) => (
            <Form.Check
              type="radio"
              id={`rd-${index}`}
              key={index}
              role="radio"
              aria-checked={validatePlatformByChecked(platform.type)}
            >
              <Form.Check.Input
                type="radio"
                isValid
                className="input-platform"
                name="grpPlatforms"
                value={platform.type}
                data-displayvalue={platform.displayValue}
                checked={validatePlatformByChecked(platform.type)}
                onChange={(event) => platformClickHandler(event)}
              />
              <Form.Check.Label>
                {platform.displayValue === "REVEL" ? (
                  <span className="platform-facet-txt">
                    {platform.displayValue} <sup> TM </sup>
                  </span>
                ) : (
                  <span className="platform-facet-txt">
                    {platform.displayValue}
                  </span>
                )}
              </Form.Check.Label>
            </Form.Check>
          ))}
      </div>
    </Form>
  );
}
