import React, { useState, useRef, useEffect } from "react";
import { Modal, Alert } from "react-bootstrap";
import "./updateSharePopup.scss";

export function UpdateSharePopup(props) {
  let mobileFeature = props.mobileFeature;
  let androidFeature = props.androidFeature;
  let appleFeature = props.appleFeature;

  const [show, setShow] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let verifyModal = document.querySelector(".mobileComponent-modal");
    verifyModal &&
      verifyModal.setAttribute("aria-describedby", "mobileComponent-para");
  }, []);

  const handleClose = () => {
    props.setModalShow(false);
    setShow(false);
  };

  const instructionsContainer = useRef(null);

  const copylinkcontent = (e) => {
    /* clipboard.writeText() will work in HTTPS only. To work this in localhost,
      go to chrome://flags --> Insecure origins treated as secure --> Enter the origin needs to be treated as secure,
      e.g. http://exchange.local.pearson.com:3000 --> Enable --> Relaunch
    */
    navigator.clipboard.writeText(instructionsContainer.current.innerText).then(
      function () {
        setShowAlert(true);
      },
      function () {
        //Better to implement error logging
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="mobileComponent-heading"
      className="mobileComponent-modal">
      <div className="share-modal-wr">
        <Modal.Header closeButton></Modal.Header>
        {showAlert && (
          <Alert
            dismissible
            id="after-copy-alert"
            className="alert-copy-content d-flex flex-row align-items-center"
            role="alert"
            aria-live="assertive">
            <span className="vertical-line"></span>
            <span className="alert-icon" aria-hidden="true"></span>
            <p className="mb-0 notification-txt copy-txt">
              Contents copied to clipboard.
            </p>
          </Alert>
        )}

        <h2 className="mt-0" id="mobileComponent-heading">
          {" "}
          Encourage anytime, anywhere access{" "}
        </h2>
        <p id="mobileComponent-para" tabindex="-1">
          Share the instructions below to encourage your students to download
          the Pearson eText mobile app.
        </p>
        <p className="instruction-txt">Instructions and links</p>

        <div
          id="instructions-container"
          className="instruction-container"
          ref={instructionsContainer}>
          <p>
            Once you have signed up for{" "}
            {mobileFeature && mobileFeature.primaryTitle}, be sure to download
            the mobile app. It lets you read, create and organize highlights and
            notes, and study — even when offline.
          </p>

          <p>
            {" "}
            Search for <b>Pearson eText</b> in the App Store or the Google Play
            store — or use the links:
          </p>

          {appleFeature && (
            <p>
              <b>App store (iOS)</b>
              <br />
              {appleFeature && appleFeature.ctaUrl}
            </p>
          )}

          {androidFeature && (
            <p>
              <b>Google Play store (Android)</b>
              <br />
              {androidFeature && androidFeature.ctaUrl}
            </p>
          )}
        </div>

        <button
          onClick={(e) => copylinkcontent(e)}
          className="custom-btn secondary-btn">
          Copy instructions
        </button>
      </div>
    </Modal>
  );
}
