import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  displaySerpLeftBar: false,
};

export const serpLeftBarSlice = createSlice({
  name: "serpLeftBar",
  initialState,
  reducers: {
    updateDisplaySerpLeftBar: (state, action) => {
      state.displaySerpLeftBar = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { updateDisplaySerpLeftBar } = serpLeftBarSlice.actions;
export default serpLeftBarSlice.reducer;
