import axios from "axios";

export function fetchUserData(token) {
  if (token) {
    return axios.get(
      `${window.location.protocol}//${
        process.env.REACT_APP_EXCHANGE_URL
          ? process.env.REACT_APP_EXCHANGE_URL
          : window.location.host
      }/api/user?token=${token}`
    );
  } else {
    return axios.get(
      `${window.location.protocol}//${
        process.env.REACT_APP_EXCHANGE_URL
          ? process.env.REACT_APP_EXCHANGE_URL
          : window.location.host
      }/api/user?token=`
    );
  }
}
