import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Subject } from "./Subject";
import { disciplineAsync } from "../discipline/disciplineSlice";
import {
  MAX_SUBJECTS,
  ORG_CLASSIFICATION,
  SERP_PLATFORMS,
} from "../../../config";
import "./subject.scss";

export function SubjectContainer(props) {
  const disciplineCount = useSelector((state) => state.subject.disciplineCount);
  const k12DisciplineCount = useSelector(
    (state) => state.subject.k12DisciplineCount
  );
  const isHigheredDisCountReceived = useSelector(
    (state) => state.subject.isHigheredDisCountReceived
  );
  const isK12ResultDisCountReceived = useSelector(
    (state) => state.subject.isK12ResultDisCountReceived
  );
  const disciplineList = useSelector(
    (state) => state.discipline.disciplineList
  );
  const orgClassification = useSelector(
    (state) => state.user.orgClassification
  );
  const currentResultPage = useSelector(
    (state) => state.searchResult.currentResultPage
  );
  const selectedPlatform = useSelector(
    (state) => state.platform.selectedPlatform
  );
  const [topSubjects, setTopSubjects] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [isShowMore, setIsShowMore] = useState(true);
  const [allDisciplineCount, setAllDisciplineCount] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (disciplineCount && !disciplineList) {
      dispatch(disciplineAsync());
    } else {
      populateSubjects(allDisciplineCount, disciplineList);
    }
  }, [disciplineCount, disciplineList, allDisciplineCount]);

  useEffect(() => {
    if (
      orgClassification !== ORG_CLASSIFICATION.HIGHER_ED &&
      currentResultPage === 1 &&
      (!selectedPlatform ||
        selectedPlatform === SERP_PLATFORMS.ALL.type ||
        selectedPlatform === SERP_PLATFORMS.REVEL.type) &&
      isHigheredDisCountReceived &&
      isK12ResultDisCountReceived
    ) {
      let tempAllDisciplineCount = { count: 0, searchResults: [] };
      if (disciplineCount !== "") {
        tempAllDisciplineCount = JSON.parse(JSON.stringify(disciplineCount));
      }

      k12DisciplineCount &&
        k12DisciplineCount.searchResults &&
        k12DisciplineCount.searchResults.forEach((countItem) => {
          const tempItem = tempAllDisciplineCount.searchResults.findIndex(
            (element) => element.key === countItem.key
          );
          if (tempItem > -1) {
            tempAllDisciplineCount.searchResults[tempItem].count =
              countItem.count + disciplineCount.searchResults[tempItem].count;
          } else {
            tempAllDisciplineCount.searchResults.push(countItem);
          }
        });
      setAllDisciplineCount(tempAllDisciplineCount);
    } else {
      setAllDisciplineCount(disciplineCount);
    }
  }, [
    isHigheredDisCountReceived,
    isK12ResultDisCountReceived,
    orgClassification,
    disciplineCount,
  ]);

  const populateSubjects = (disciplineCountItems, discipline) => {
    if (disciplineCountItems && discipline) {
      let subjectItems = disciplineCountItems.searchResults.map(
        (disciplineCountItem) => {
          for (const categoryItem of discipline._embedded
            .disciplineCategories) {
            let subjectItem = null;
            subjectItem = _.find(
              categoryItem.primaryDisciplines,
              function (category) {
                return category.name === disciplineCountItem.key;
              }
            );
            if (subjectItem) {
              return {
                ...disciplineCountItem,
                category: categoryItem.name,
              };
            }
          }
          return null;
        }
      );

      const formatSubjectData = (subjectItems) => {
        let categories = _.unionBy(subjectItems, "category").map(
          (e) => e.category
        );
        let subjects = categories.map((category) => {
          let subject = {};
          subject.category = category;
          subject.items = _.filter(subjectItems, function (item) {
            return category === item.category;
          });
          return subject;
        });
        return subjects;
      };

      setTopSubjects(formatSubjectData(_.take(subjectItems, MAX_SUBJECTS)));
      setAllSubjects(formatSubjectData(subjectItems));
    } else {
      setTopSubjects([]);
      setAllSubjects([]);
    }
  };

  return (
    <React.Fragment>
      {topSubjects && topSubjects.length > 0 && isShowMore && (
        <Subject subjectItems={topSubjects} />
      )}
      {allSubjects && allSubjects.length > 0 && !isShowMore && (
        <Subject subjectItems={allSubjects} />
      )}
      {disciplineList &&
        allDisciplineCount &&
        allDisciplineCount.searchResults &&
        allDisciplineCount.searchResults.length > MAX_SUBJECTS && (
          <button
            onClick={() => setIsShowMore((prevIsShowMore) => !prevIsShowMore)}
            className="show-link text-btn-link"
            aria-expanded={!isShowMore}
            aria-live="assertive"
            aria-atomic="true"
            aria-relevant="all">
            {isShowMore ? "Show more +" : "Show less -"}
          </button>
        )}
    </React.Fragment>
  );
}
