import React, { useEffect } from "react";
import Pagination from "react-js-pagination";
import { updateCurrentResultPage } from "../searchResult/searchResultSlice";
import { useDispatch, useSelector } from "react-redux";
import "./srpPagination.scss";
import { SEARCH_PAGE_RESULT_COUNT, SEARCH_PAGE_RANGE } from "../../../config";
import { updateSendSearchRequest } from "../search/searchSlice";

export function SrpPagination(props) {
  const dispatch = useDispatch();
  const searchResult = useSelector((state) => state.searchResult.searchResult);
  const currentResultPage = useSelector(
    (state) => state.searchResult.currentResultPage
  );
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );

  const handlePageChange = (pageNumber) => {
    dispatch(updateCurrentResultPage(pageNumber));
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    let paginationElementDisabled = document.querySelector(
      ".pagination-item .disabled"
    );

    if (paginationElementDisabled) {
      paginationElementDisabled.setAttribute("aria-disabled", "true");
    }
  });

  useEffect(() => {
    let paginationElement = document.querySelectorAll(".pagination-item > a");
    paginationElement.forEach((x) => {
      x.removeAttribute("aria-current", "page");
    });
    let paginationElementActive = document.querySelector(".active > a");

    if (paginationElementActive) {
      paginationElementActive.setAttribute("aria-current", "page");
    }
  });

  useEffect(() => {
    if (searchResult) {
      let paginationlist = document.querySelector(".pagination");
      let pageCount = Math.floor(searchResult.count / 24);
      if (paginationlist) {
        if (searchResult.count % 24 === 0) {
          paginationlist.setAttribute(
            "data-current-page",
            `${currentResultPage} of ${pageCount}`
          );
        } else {
          paginationlist.setAttribute(
            "data-current-page",
            `${currentResultPage} of ${pageCount + 1}`
          );
        }
      }
    }
  });

  const prevLinkClickability = () => {
    if (searchResult.count === 1 || currentResultPage === 1) {
      return "disabled";
    }
    return "";
  };

  const nextLinkClickability = () => {
    if (
      searchResult.count > 0 &&
      searchResult.count / SEARCH_PAGE_RESULT_COUNT > currentResultPage
    ) {
      return "";
    } else {
      return "disabled";
    }
  };

  return (
    <div className="row ml-0 pagination-wr">
      <nav role="navigation" aria-label="Search Pagination">
        {searchResult &&
          searchResult.searchResults &&
          searchResult.searchResults.length > 0 && (
            <Pagination
              activePage={currentResultPage}
              itemsCountPerPage={SEARCH_PAGE_RESULT_COUNT}
              totalItemsCount={searchResult.count}
              pageRangeDisplayed={SEARCH_PAGE_RANGE}
              onChange={handlePageChange}
              hideFirstLastPages={true}
              linkClassPrev={prevLinkClickability()}
              linkClassNext={nextLinkClickability()}
              itemClass="pagination-item"
            />
          )}
      </nav>
    </div>
  );
}
