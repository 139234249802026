import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userDataAsync, changeIsMobileView } from "./userSlice";
import { HamburgerContainer } from "../hamburger/HamburgerContainer";
import { Search } from "../search/Search";
import { PAGES, USER_ROLE } from "../../../config";
import { Header } from "@console/console-header";
import { useLocation } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../widgets/search/images/search-icon.svg";
import { updateIsMobileSearchView } from "../../widgets/search/searchSlice";
import { reinitializedSearchResultStatus } from "../../widgets/searchResult/searchResultSlice";

export function ExHeader(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const isMobileView = useSelector((state) => state.user.isMobileView);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
  });
  const [userSigneIn, setUserSigneIn] = useState(false);
  let config = window.__env.configs["appConfig"];
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  const searchInputRef = React.useRef(null);
  const isMobileSearchView = useSelector(
    (state) => state.search.isMobileSearchView
  );

  useEffect(() => {
    if (window.piSession && window.piSession.userId()) {
      dispatch(userDataAsync(queryParams.get("integration_context_token")));
    }

    if (props.cPage !== PAGES.SERP) {
      dispatch(reinitializedSearchResultStatus());
    }

    setTimeout(function () {
      if (queryParams.has("integration_context_token")) {
        if (
          userData === null &&
          window.piSession &&
          window.piSession.userId()
        ) {
          dispatch(userDataAsync(queryParams.get("integration_context_token")));
        }
      }
    }, 3000);
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      const activeElement = window.document.activeElement;

      if (event.key === "Enter") {
        if (
          activeElement.classList.contains("profile-button") &&
          activeElement.classList.contains("signed-in")
        ) {
          activeElement.setAttribute("id", "profile-button");
          setTimeout(() => {
            const myAccMenu = document.getElementById("my-account-menu");
            if (myAccMenu) {
              document
                .getElementById("my-account-menu")
                .setAttribute("tabIndex", "-1");
              const all = myAccMenu.getElementsByTagName("a");
              for (let i = 0, max = all.length; i < max; i++) {
                all[i].setAttribute("href", "#");
              }
            }
          }, 200);
        }
      }

      if (event.key === "Tab") {
        const myAccMenu = document.getElementById("my-account-menu");
        if (myAccMenu) {
          const all = myAccMenu.getElementsByTagName("a");
          for (let i = 0, max = all.length; i < max; i++) {
            if (activeElement === all[all.length - 1]) {
              setTimeout(() => {
                document.getElementById("profile-button").focus();
              }, 100);
            }
          }
        }
      }
    });
  }, []);
  useEffect(() => {
    if (
      window.piSession &&
      window.piSession.userId() &&
      userData &&
      userData.id
    ) {
      setUserSigneIn(true);
      setUserDetails({
        firstName: userData.userDetails.givenName
          ? userData.userDetails.givenName
          : "",
        lastName: userData.userDetails.familyName
          ? userData.userDetails.familyName
          : "",
      });
    }

    if (
      userData &&
      userData.id &&
      userData.isInstructor === true &&
      window.iph === undefined
    ) {
      window.loadZoominWideget(config.ZOOMIN_INST_WIDGET_ID);
    }
  }, [userData]);

  const logoutCallback = () => {
    if (window.piSession) {
      window.sessionStorage.removeItem("exchange.product.baseRelated");
      window.sessionStorage.removeItem("exchange.search.selectedPlatform");
      window.sessionStorage.removeItem(
        "exchange.search.selectedPlatformDisplayName"
      );
      window.sessionStorage.removeItem("exchange.search.layout");
      window.sessionStorage.removeItem("exchange.search.selectedShow");
      window.sessionStorage.removeItem(
        "exchange.search.selectedShowDisplayName"
      );
      window.sessionStorage.removeItem("exchange.search.selectedSort");
      window.sessionStorage.removeItem(
        "exchange.search.selectedSortDisplayName"
      );
      window.localStorage.removeItem("exchange.user.defaultInstitute");
      window.localStorage.removeItem("exchange.user.lmsInstitute");
      let params = new URLSearchParams(window.location.search);
      params.delete("disc");
      params.delete("cat");
      window.piSession.logout();
    }
  };

  const logoutSessionCallback = (url) => {
    window.location.href = url;
  };

  const handleLogoClick = () => {
    window.location.href = config.CONSOLE_URL;
  };

  const accountSettingsCallback = () => {
    window.location.href = `${config.CONSOLE_URL}/account/manage/account`;
  };

  useEffect(() => {
    window.addEventListener("resize", resizeSrpSearchClickHandler);
    return () =>
      window.removeEventListener("resize", resizeSrpSearchClickHandler);
  }, []);

  useEffect(() => {
    handleSearchResponsiveView();
    window.addEventListener("resize", handleSearchResponsiveView);
    return () =>
      window.removeEventListener("resize", handleSearchResponsiveView);
  }, []);

  useEffect(() => {
    window.addEventListener("click", handleInitialTabfocus);
    return () =>
      window.removeEventListener("click", handleInitialTabfocus);
  }, []);

  const handleSearchResponsiveView = () => {
    if (window.innerWidth < 992) {
      dispatch(changeIsMobileView(true));
    } else {
      dispatch(changeIsMobileView(false));
    }
  };

  const mobileSearchClickHandler = (e) => {
    if (window.innerWidth < 992) {
      dispatch(updateIsMobileSearchView(true));
    } else {
      dispatch(updateIsMobileSearchView(false));
    }
  };

  const resizeSrpSearchClickHandler = () => {
    if (isMobileSearchView) {
      mobileSearchClickHandler();
    }
  };

  const signInCallBack = () => {
    let oneTrustCookieGroups;
    const cookieData = document.cookie.split("; ");
    let cookieConsent;
    cookieData.forEach((cookieItem) => {
      if (cookieItem.match("OptanonConsent")) {
        const optanonConsentData = decodeURIComponent(cookieItem).split("&");
        optanonConsentData.forEach((consentItem) => {
          if (consentItem.match("groups")) {
            const consentValues = consentItem.replace("groups=", "").split(",");
            let vals = [];
            consentValues.forEach((consentValue) => {
              vals.push(consentValue.slice(-1));
            });
            cookieConsent = vals.toString().replaceAll(",", "");
          }
        });
      }
    });


    if (cookieConsent && cookieConsent.length > 0) {
      oneTrustCookieGroups = `&cookieConsentGroups=${cookieConsent}`;
    }

    if (window.piSession) {
      const PI_SIGNIN = `${config.PI_API}/login/webchallenge`;
      const CLIENT_KEY = config.PI_CLIENT_ID;
      const successUrl = encodeURI(window.location.href);
      const loginUrl = `${PI_SIGNIN}?client_id=${CLIENT_KEY}&login_success_url=${successUrl}${oneTrustCookieGroups}`;
      window.piSession.login(successUrl);

    }
  };

  const handleInitialTabfocus = (e) => {
    if (e.target.id == 'help-button') {
      setTimeout(() => {
        if (document.getElementById('zdocs_widget_container') !== null) {
          document.getElementById('zdocs_widget_container').setAttribute("tabindex", "-1");
          document.getElementById('zdocs_widget_container').focus();
        }
      }, 50);
    }
  }

  const ZoominClickHandler = () => {
    (window.iph !== undefined) && window.iph("open");
  };


  return (
    <React.Fragment>
      <div className="in-header-wr">
        <HamburgerContainer cPage={props.cPage} />
      </div>
      <div className="ex-header-wr">
        <Header
          handleLogout={logoutCallback}
          handleLogoClick={handleLogoClick}
          handleAccountSettings={accountSettingsCallback}
          handleLoggedOutSession={logoutSessionCallback}
          onHelpIconClick={ZoominClickHandler}
          isHelpVisible={userData && userData.isInstructor ? true : false}
          signedIn={userSigneIn}
          onSignIn={signInCallBack}
          isInstructor={false}
          clientKey={config.PI_CLIENT_ID}
          user={userDetails}
          position={
            props.cPage === PAGES.PDP ||
              props.cPage === PAGES.GET_START ||
              props.cPage === PAGES.NOT_FOUND
              ? "fixed"
              : "flex"
          }
          context="exchange"
          env={config.ENV}>
          {props.cPage === PAGES.SERP && (
            <React.Fragment>
              <div className="search-wr">
                <Search cPage={props.cPage} />{" "}
              </div>
              <div className="mobile-search-wr">
                <button
                  aria-label="Search"
                  className="searchbar-icon responsive-search-icon"
                  type="submit"
                  ref={searchInputRef}
                  title="Submit the search query."
                  onClick={() => mobileSearchClickHandler()}>
                  <SearchIcon />
                </button>
              </div>
            </React.Fragment>
          )}
          {(props.cPage === PAGES.PDP ||
            props.cPage === PAGES.GET_START ||
            props.cPage === PAGES.NOT_FOUND) && (
              <React.Fragment>
                {!isMobileView && (
                  <div className="pdp-search-wr search-wr">
                    <Search cPage={props.cPage} />
                  </div>
                )}
                {isMobileView && (
                  <div className="pdp-search-wr mobile-search-wr">
                    <button
                      aria-label="Search"
                      ref={searchInputRef}
                      className="searchbar-icon responsive-search-icon"
                      type="submit"
                      title="Submit the search query."
                      onClick={() => mobileSearchClickHandler()}>
                      <SearchIcon />
                    </button>
                  </div>
                )}
              </React.Fragment>
            )}
        </Header>
      </div>
    </React.Fragment>
  );
}
