import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDisplay: false,
};

export const hamburgerSlice = createSlice({
  name: "hamburger",
  initialState,
  reducers: {
    updateIsDisplay: (state, action) => {
      state.isDisplay = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { updateIsDisplay } = hamburgerSlice.actions;
export default hamburgerSlice.reducer;
