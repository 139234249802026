import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateSelectedSubject } from "./subjectSlice";
import "./subject.scss";
import {
  getSubjectSearchUrl,
  findSubjectDisciplinesCodes,
} from "../../util/disciplineUtil";
import _ from "lodash";
import ReactGA from "react-ga4";
import { updateSendSearchRequest } from "../search/searchSlice";
import { updateCurrentResultPage } from "../searchResult/searchResultSlice";
import { resetDisciplineCount } from "../subject/subjectSlice";

export function Subject(props) {
  const dispatch = useDispatch();
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let discParam = decodeURIComponent(queryParams.get("disc"));
  let catParam = queryParams.get("cat");
  if (catParam) {
    catParam = decodeURIComponent(catParam);
  }
  const disciplineList = useSelector(
    (state) => state.discipline.disciplineList
  );
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );

  useEffect(() => {
    if (discParam) {
      let discCode = discParam.split("|");
      if (
        disciplineList &&
        disciplineList._embedded &&
        disciplineList._embedded.disciplineCategories &&
        disciplineList._embedded.disciplineCategories.length > 0
      ) {
        let disciplineDisplayValue = null;
        for (
          let i = 0;
          i < disciplineList._embedded.disciplineCategories.length;
          i++
        ) {
          let disciplineCategory =
            disciplineList._embedded.disciplineCategories[i];
          for (
            let x = 0;
            x < disciplineCategory.primaryDisciplines.length;
            x++
          ) {
            let primaryDiscipline = disciplineCategory.primaryDisciplines[x];
            const selectedSecDiscipline = _.find(
              primaryDiscipline.secondaryDisciplines,
              function (secondaryDiscipline) {
                return secondaryDiscipline.code === discCode[0];
              }
            );
            if (selectedSecDiscipline) {
              disciplineDisplayValue = primaryDiscipline;
              break;
            }
          }
          if (disciplineDisplayValue) {
            break;
          }
        }
        if (disciplineDisplayValue) {
          dispatch(
            updateSelectedSubject({
              type: disciplineDisplayValue.code,
              displayValue: disciplineDisplayValue.name,
            })
          );
        }
      }
    }

    if (catParam) {
      dispatch(
        updateSelectedSubject({
          type: catParam,
          displayValue: catParam,
        })
      );
    }
  }, [disciplineList]);

  const subjectClickHandler = (event) => {
    dispatch(resetDisciplineCount());
    dispatch(updateCurrentResultPage(1));
    ReactGA.event({
      category: "Facet",
      action: "click items on SERP filter by Subject",
      label: event.target.dataset.disciplineKey,
    });
    dispatch(
      updateSelectedSubject({
        type: event.target.getAttribute("data-discipline-code"),
        displayValue: event.target.getAttribute("data-discipline-key"),
      })
    );
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
  };

  const displayPrimaryDispilines = (primaryDisciplines) => {
    return primaryDisciplines.map((primaryDiscipline, index) => (
      <li key={index}>
        <Link
          to={getSubjectSearchUrl(
            disciplineList,
            primaryDiscipline,
            queryParams
          )}
          onClick={(event) => subjectClickHandler(event)}
          className="subject-discipline"
          data-discipline-code={findSubjectDisciplinesCodes(
            disciplineList,
            primaryDiscipline
          )}
          data-discipline-key={primaryDiscipline.key}
        >
          {`${primaryDiscipline.key}`} &nbsp;
          <strong
            data-discipline-code={findSubjectDisciplinesCodes(
              disciplineList,
              primaryDiscipline
            )}
            data-discipline-key={primaryDiscipline.key}
          >{`(${primaryDiscipline.count})`}</strong>
        </Link>
      </li>
    ));
  };

  const displaySubjectCategories = props.subjectItems.map(
    (subjectItem, index) => (
      <div className="filter-displines" key={index}>
        <div className="sub-category">{subjectItem.category}</div>
        <div className="sub-item">
          <ul>{displayPrimaryDispilines(subjectItem.items)}</ul>
        </div>
      </div>
    )
  );

  return (
    <React.Fragment>
      <div className="subject-category-wr">{displaySubjectCategories}</div>
    </React.Fragment>
  );
}
