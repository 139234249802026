import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import "./mobileProductTabNav.scss";

export function NavTab({ title, itemId, key, tabClickHandler }) {
  const activeMobileProductTab = useSelector(
    (state) => state.product.activeMobileProductTab
  );
  const {
    scrollToItem,
  } = useContext(VisibilityContext);

  const tabFocusHandler = (event) => {
    scrollToItem(event.target, "smooth", "start");
  };
  return (
    <div
      className={
        activeMobileProductTab === itemId ? "active-tab tab-item" : "tab-item"
      }
      tabIndex={key}
      id={title}
      role="tab"
      tabindex="0"
      aria-selected={activeMobileProductTab === itemId ? "true" : "false"}
      data-item-id={itemId}
      onClick={(event) => tabClickHandler(event)}
      onFocus={(event) => tabFocusHandler(event)}>
      {title}
    </div>
  );
}
