import { PLATFORMS, PAGES } from "../../config";
import "./productUtil.scss";

export const getComingSoonOverlayer = (data, page) => {
  // if (page === PAGES.SERP) {
  //   // Here data is product data for SERP
  //   if (
  //     data &&
  //     data.productPlatform &&
  //     data.productPlatform === PLATFORMS.COLLECTION
  //   ) {
  //     return isComingSoonProduct(data.datePublished);
  //   } else {
  //     if (
  //       data &&
  //       data.offers &&
  //       data.offers.searchResults &&
  //       data.offers.searchResults.length > 0 &&
  //       data.offers.searchResults[0].source &&
  //       data.offers.searchResults[0].source["schema:offerStartDate"]
  //     ) {
  //       return isComingSoonProduct(
  //         data.offers.searchResults[0].source["schema:offerStartDate"]
  //       );
  //     }
  //   }
  // }
  // if (page === PAGES.PDP) {
  //   // Here data is product offer data for PDP, except for collections
  //   if (
  //     data &&
  //     data.productPlatform &&
  //     data.productPlatform === PLATFORMS.COLLECTION
  //   ) {
  //     return isComingSoonProduct(data.datePublished);
  //   } else if (data && data.comingSoon) {
  //     return data.comingSoon;
  //   }
  // }
  return false;
};

/*param date is something like "2016-04-12T00:00:00.000Z"*/

export const isComingSoonProduct = (date) => {
  if (date && date.length > 0) {
    let array = date.split(/[^0-9]/);
    date = new Date(
      array[0],
      array[1] - 1,
      array[2],
      array[3],
      array[4],
      array[5]
    );
  } else {
    date = new Date();
  }

  let now = new Date();
  if (
    now.getFullYear === date.getFullYear() &&
    now.getDate() === date.getDate() &&
    now.getMonth() === date.getMonth()
  ) {
    return false;
  } else {
    return date > now;
  }
};

export const getProductImage = (productData) => {
  if (productData.images && productData.images.thumbnailUrl) {
    return productData.images.thumbnailUrl;
  } else if (productData.images && productData.images.coverThumbnailUrl) {
    return productData.images.coverThumbnailUrl;
  } else if (productData.images && productData.images.coverUrl) {
    return productData.images.coverUrl;
  } else if (productData.images && productData.images.catalogUrl) {
    return productData.images.catalogUrl;
  } else if (
    productData.image &&
    productData.image.thumbnail &&
    productData.image.thumbnail.url
  ) {
    return productData.image.thumbnail.url;
  } else if (
    productData.image &&
    productData.image.catalog &&
    productData.image.catalog.url
  ) {
    return productData.image.catalog.url;
  }
};

export const getSrpProductImage = (productData) => {
  
  if (productData.source["schema:images"] && productData.source["schema:images"]["schema:thumbnailUrl"]) {
    return productData.source["schema:images"]["schema:thumbnailUrl"];
  } else if (productData.source["schema:images"] && productData.source["schema:images"]["schema:coverThumbnailUrl"]) {
    return productData.source["schema:images"]["schema:coverThumbnailUrl"];
  } else if (productData.source["schema:images"] && productData.source["schema:images"]["schema:coverUrl"]) {
    return productData.source["schema:images"]["schema:coverUrl"];
  } else if (productData.source["schema:images"] && productData.source["schema:images"]["schema:catalogUrl"]) {
    return productData.source["schema:images"]["schema:catalogUrl"];
  } else if (
    productData.source["schema:images"] &&
    productData.source["schema:images"]["schema:thumbnail"] &&
    productData.source["schema:images"]["schema:thumbnail"]["schema:url"]
  ) {
    return productData.source["schema:images"]["schema:thumbnail"]["schema:url"];
  } else if (
    productData.source["schema:images"] &&
    productData.source["schema:images"]["schema:catalog"] &&
    productData.source["schema:images"]["schema:catalog"]["schema:url"]
  ) {
    return productData.source["schema:images"]["schema:catalog"]["schema:url"];
  }
};

export const getLmsToken = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("integration_context_token");
};

export const isValidInstructorWithOutOrg = (user) => {
  const lmsToken = getLmsToken();
  if (
    user &&
    user.id &&
    !user.hasInstitutions &&
    user.selfIdentifiedInstructor &&
    !lmsToken
  ) {
    return true;
  } else {
    return false;
  }
};

export const getProductImageClassName = (productData) => {
  if (productData) {
    let classNameLearningApps = "product-image";
    if (productData.productPlatform === PLATFORMS.LEARNING_APPLICATIONS) {
      classNameLearningApps += " pdp-learning-apps";
    }
    return classNameLearningApps;
  } else {
    return "";
  }
};

const generateDisplayOfferPaylod = function (offerData) {
  let offer = {};
  offer.offerTypeCode = offerData.offerType.offerTypeCode;
  offer.description = offerData.description;
  offer.lowPrice =
    offerData.offerType.definition.price > -1
      ? offerData.offerType.definition.price
      : -1;
  offer.highPrice = -1;
  offer.currency = offerData.offerType.definition.currency;
  let expandInfo = [];
  let expandOfferInfo = {};
  expandOfferInfo.realPrice =
    offerData.offerType.definition.price > -1
      ? offerData.offerType.definition.price
      : -1;
  expandOfferInfo.subscription = offerData.offerType.definition
    .subscriptionDuration
    ? offerData.offerType.definition.subscriptionDuration
    : -1;
  expandOfferInfo.realCurrency = offerData.offerType.definition.currency
    ? offerData.offerType.definition.currency
    : "";
  expandInfo[0] = expandOfferInfo;
  offer.expandInfo = expandInfo;
  return offer;
};

export const displaySortedOffers = function (offerList) {
  let displayOffers = [];
  if (offerList && offerList.length > 0) {
    let index = 0;
    let offerType = "";
    let offerCurrency = "";
    offerList.forEach((value) => {
      if (
        index === 0 ||
        offerType !== offerList[index].offerType.offerTypeCode
      ) {
        offerType = value.offerType.offerTypeCode;
        offerCurrency = value.offerType.definition.currency
          ? value.offerType.definition.currency
          : "";
        displayOffers[displayOffers.length] = generateDisplayOfferPaylod(value);
      } else if (offerType === offerList[index].offerType.offerTypeCode) {
        let offer = displayOffers[displayOffers.length - 1];
        if (
          value.offerType.definition.price > -1 &&
          (offer.lowPrice === -1 ||
            offer.lowPrice > value.offerType.definition.price)
        ) {
          offer.lowPrice =
            value.offerType.definition.price > -1
              ? value.offerType.definition.price
              : -1;
          offer.description = value.description;
        } else if (
          value.offerType.definition.price > -1 &&
          (offer.highPrice === -1 ||
            offer.highPrice < value.offerType.definition.price)
        ) {
          offer.highPrice =
            value.offerType.definition.price > -1
              ? value.offerType.definition.price
              : -1;
        }
        var expandOfferInfo = {};
        expandOfferInfo.realPrice =
          value.offerType.definition.price > -1
            ? value.offerType.definition.price
            : -1;
        expandOfferInfo.subscription = value.offerType.definition
          .subscriptionDuration
          ? value.offerType.definition.subscriptionDuration
          : -1;
        expandOfferInfo.realCurrency =
          offerList[index].offerType.definition.currency;
        if (
          offerList[index].offerType.definition &&
          offerList[index].offerType.definition.currency !== offerCurrency
        ) {
          offer.currency = "";
        }

        offer.expandInfo[offer.expandInfo.length] = expandOfferInfo;
        displayOffers[displayOffers.length - 1] = offer;
        offerCurrency = value.offerType.definition.currency
          ? value.offerType.definition.currency
          : "";
        offerType = value.offerType.offerTypeCode;
      }
      index++;
    });
    return displayOffers;
  } else {
    return offerList;
  }
};

export const getProductBrand = (productData) => {
  const revelBrand = "TM";
  const productBrandCaps = productData.brand.name.toUpperCase();
  if (productBrandCaps === "REVEL") {
    return (
      <p className="brand-name mb-0">
        Platform:
        <span>
          {" "}
          {productBrandCaps} <sup> {revelBrand} </sup>
        </span>
      </p>
    );
  }
  return (
    <p className="brand-name mb-0">
      Platform: {" "}<span>{productData.brand.name}</span>
    </p>
  );
};

export const isProductNameWithEdition = (productName) => {
  if (productName && productName.length > 0) {
    const matchText = productName.match(/[0-9](e|E)/g);
    if (matchText && matchText.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
