import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Product } from "../../widgets/product/Product";
import { Link, useLocation } from "react-router-dom";
import "./getStart.scss";
import { Button, Form } from "react-bootstrap";
import { productAsync } from "../pdp/productSlice";
import { ExHeader } from "../../widgets/exHeader/ExHeader";
import { PAGES } from "../../../config";
import { ReactComponent as BackToResultArrow } from "../../images/back-to-result-arrow.svg";
import { SearchMobileView } from "../../widgets/searchMobileView/SearchMobileView";
import { disciplineAsync } from "../../widgets/discipline/disciplineSlice";

export function GetStart() {  
  const productData = useSelector((state) => state.product.productData);
  const disciplineList = useSelector(
    (state) => state.discipline.disciplineList
  );
  const [courseLink, setCourseLink] = useState("");
  const [isValidCourseLink, setIsValidCourseLink] = useState(true);
  const dispatch = useDispatch();
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);

  const isMobileView = useSelector((state) => state.user.isMobileView);
  const isMobileSearchView = useSelector(
    (state) => state.search.isMobileSearchView
  );
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (!productData) {
      let returnUrl = new URL(queryParams.get("return"));
      let returnUrlParams = new URLSearchParams(returnUrl.search);
      dispatch(
        productAsync({
          productId: returnUrlParams.get("uuid"),
          isRelated: false,
        })
      );
    }
  });

  useEffect(() => {
    document.title = "To find and join your course, enter the invitation link.";
    if (!disciplineList) {
      dispatch(disciplineAsync());
    }
  }, []);

  const courseLinkOnChangeHandler = (event) => {
    let envConfig = window.__env.configs["appConfig"];
    if (
      event.target.value.indexOf(`${envConfig.CONSOLE_URL}/enrollment/`) > -1
    ) {
      setIsValidCourseLink(true);
    } else {
      setIsValidCourseLink(false);
    }
    setCourseLink(event.target.value);
  };

  const joinBtnClickHandler = () => {
    if (courseLink !== "") {
      window.location.href = courseLink;
    }
  };

  const courseLinkKeyPressHandler = (event) => {
    if (event.charCode === 13 && isValidCourseLink) {
      joinBtnClickHandler();
    }
  };

  const backToCourseHandler = () => {
    const backToCourseUrl = new URL(queryParams.get("return"));
    window.location.href =
      backToCourseUrl.pathname + encodeURI(decodeURI(backToCourseUrl.search));
  };

  const [displaySkipNav, setdisplaySkipNav] = useState("");

  return (
    <React.Fragment>
      {!isMobileSearchView && (
        <React.Fragment>
          <a
            className="skip-nav skip-nav-fixed-header"
            data-role="none"
            href="#main-content-starts"
            onFocus={() => {
              setdisplaySkipNav("fixedHeaderSkipNavLinkOnFocus");
            }}
            onBlur={() => {
              setdisplaySkipNav("fixedHeaderSkipNavLinkOnBlur");
            }}>
            Skip to main content
          </a>
          <div
            className={`"container-fluid main-bg custom-pdp getting-started-wr" ${displaySkipNav}`}>
            <div className="container-fluid header-wr">
              <div
                className={
                  userData === null
                    ? "header-container"
                    : "header-container signed-in-wr"
                }>
                <ExHeader cPage={PAGES.GET_START} />
              </div>
            </div>
            <div className="horizontal-separator"></div>
            <main
              className="pdp-wrap row flex-column getting-started-page-wr"
              id="main-content-starts"
              tabIndex="-1">
              <div className="pdp-leftside col-9">
                <Link
                  to={() => false}
                  onClick={() => backToCourseHandler()}
                  className="back-to-detail-link">
                  <BackToResultArrow />
                  Back to course detail
                </Link>
                {productData && <Product productData={productData} />}
                <div className="join-course-info mb-5">
                  <h2>Here to join?</h2>
                  <p>
                    To find and join your course, enter the invitation link that
                    you recieved in your syllabus or email.
                  </p>
                  <div className="get-started-input-wr">
                    <Form
                      className="d-flex input-container"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}>
                      <div className="input-wr">
                        <Form.Label className="sr-only" for="invitationLink">
                          Enter invitation Link to join course
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="invitationLink"
                          placeholder=""
                          className={
                            isValidCourseLink
                              ? "custom-form-control"
                              : "custom-form-control input-alert-container"
                          }
                          onChange={(event) => courseLinkOnChangeHandler(event)}
                          onKeyPress={(event) =>
                            courseLinkKeyPressHandler(event)
                          }
                        />
                        {!isValidCourseLink && (
                          <p
                            className="mb-0 error-text"
                            role="alert"
                            aria-live="assertive">
                            Sorry, course not found. Please check the link.
                          </p>
                        )}
                      </div>

                      <Button
                        id="join-btn"
                        disabled={!isValidCourseLink}
                        aria-disabled={!isValidCourseLink}
                        onClick={() => joinBtnClickHandler()}
                        className="custom-btn secondary-btn">
                        Join
                      </Button>
                    </Form>
                  </div>
                  <h3>Don't have your invitation link?</h3>
                  <p className="mb-5">
                    Sorry about that. You will need to contact your instructor.
                  </p>
                </div>
              </div>
            </main>
          </div>
        </React.Fragment>
      )}
      {isMobileView && isMobileSearchView && <SearchMobileView />}
    </React.Fragment>
  );
}
