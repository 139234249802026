import React, { useEffect } from "react";
import { DisciplineContainer } from "../../widgets/discipline/DisciplineContainer";
import { Search } from "../../widgets/search/Search";
import { ExHeader } from "../../widgets/exHeader/ExHeader";
import { PAGES } from "../../../config";
import { resetSubject } from "../../widgets/subject/subjectSlice";
import { resetSelectedPlatform } from "../../widgets/platform/platformSlice";
import { resetSelectedShow } from "../../widgets/show/showSlice";
import { resetSelectedSort } from "../../widgets/sort/sortSlice";
import { reinitializedSearchResult } from "../../widgets/searchResult/searchResultSlice";
import { resetProductData } from "../pdp/productSlice";
import { updateIsDisplay } from "../../widgets/hamburger/hamburgerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../../widgets/notification/Notification";
import "./home.scss";
import ReactGA from "react-ga4";
import { ScrollToTop } from "../../widgets/scrollToTop/ScrollToTop";
import { SearchMobileView } from "../../widgets/searchMobileView/SearchMobileView";

export function Home() {
  const dispatch = useDispatch();

  const isMobileSearchView = useSelector(
    (state) => state.search.isMobileSearchView
  );
  const userData = useSelector((state) => state.user.userData);
  const userConsent = useSelector((state) => state.user.userConsent);

  useEffect(() => {
    dispatch(resetSubject());
    dispatch(resetSelectedPlatform());
    dispatch(resetSelectedShow());
    dispatch(resetSelectedSort());
    dispatch(reinitializedSearchResult());
    dispatch(resetProductData());
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: "pageview",
      action: "on home page",
    });
  }, [userConsent]);

  return (
    <React.Fragment>
      {isMobileSearchView && <SearchMobileView />}
      {!isMobileSearchView && (
        <div className="hm-container">
          <div>
            <a
              className="skip-nav"
              data-role="none"
              href="#main-content-starts"
            >
              Skip to main content
            </a>
            <div className="container-fluid header-wr">
              <div
                className={
                  userData === null
                    ? "container header-container header-hm-container"
                    : "container header-container signed-in-wr header-hm-container"
                }
              >
                <ExHeader cPage={PAGES.HOME} />
              </div>
            </div>
          </div>

          <main
            onClick={() => dispatch(updateIsDisplay(false))}
            id="main-content-starts"
            tabIndex="-1"
          >
            <Notification cPage={PAGES.HOME} />
            <section className="hm-header">
              <div className="hm-header-inner">
                <h1>Find course materials</h1>
                <Search cPage={PAGES.HOME} />
              </div>
            </section>
            <section className="hm-discipline-container">
              <DisciplineContainer />
            </section>
          </main>
          <ScrollToTop />
        </div>
      )}
    </React.Fragment>
  );
}
