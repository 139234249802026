import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDisciplineCount } from "./subjectApi";

const initialState = {
  disciplineCount: null,
  k12DisciplineCount: null,
  selectedSubject: null,
  selectedSubjectDisplayName: null,
  isSubjectCoutInternalError: false,
  isHigheredDisCountReceived: false,
  isK12ResultDisCountReceived: false,
};

export const disciplineCountAsync = createAsyncThunk(
  "subject/fetchDisciplineCount",
  async (args) => {
    const response = await fetchDisciplineCount(
      args.searchValue,
      args.category,
      args.country,
      args.institution,
      args.discipline,
      args.featureFlag,
      args.limit,
      args.offset,
      args.type,
      args.sort,
      args.show,
      args.token,
      args.orgClassification,
      args.resultType
    );
    return response.data;
  }
);

export const subjectSlice = createSlice({
  name: "subject",
  initialState,
  reducers: {
    updateSelectedSubject: (state, action) => {
      state.selectedSubject = action.payload.type;
      state.selectedSubjectDisplayName = action.payload.displayValue;
    },
    resetSelectedSubject: (state, action) => {
      state.selectedSubject = null;
      state.selectedSubjectDisplayName = null;
    },
    resetSubject: (state, action) => {
      state.selectedSubject = null;
      state.selectedSubjectDisplayName = null;
      state.disciplineCount = null;
      state.k12DisciplineCount = null;
    },
    resetDisciplineCount: (state, action) => {
      state.disciplineCount = null;
      state.k12DisciplineCount = null;
      state.isHigheredDisCountReceived = false;
      state.isK12ResultDisCountReceived = false;
    },
    updateIsSubjectCoutInternalError: (state, action) => {
      state.isSubjectCoutInternalError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(disciplineCountAsync.fulfilled, (state, action) => {
        if (action.meta.arg.resultType === "k12") {
          state.k12DisciplineCount = action.payload;
          state.isSubjectCoutInternalError = false;
          state.isK12ResultDisCountReceived = true;
        } else {
          state.disciplineCount = action.payload;
          state.isSubjectCoutInternalError = false;
          state.isHigheredDisCountReceived = true;
        }
      })
      .addCase(disciplineCountAsync.rejected, (state, action) => {
        if (
          action &&
          action.meta &&
          action.meta.arg &&
          action.error &&
          action.error.message.includes("500")
        ) {
          state.isSubjectCoutInternalError = true;
        }
      });
  },
});

export const {
  updateSelectedSubject,
  resetSelectedSubject,
  resetSubject,
  updateIsSubjectCoutInternalError,
  resetDisciplineCount,
} = subjectSlice.actions;

export const selectSubject = state => state.subject.selectedSubjectDisplayName;

export default subjectSlice.reducer;
