import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Alert, Modal } from "react-bootstrap";
import {
  updateIsUserServiceInternalError,
  updateRefreshInstitution,
  updateNoInstitutionProfile,
  updateIsVerifiedAccount,
} from "../exHeader/userSlice";
import { updateIsInternalError } from "../searchResult/searchResultSlice";
import { updateIsSubjectCoutInternalError } from "../subject/subjectSlice";
import { updateIsDisciplineInternalError } from "../discipline/disciplineSlice";
import {
  updateIsProductInternalError,
  updateIsOfferInternalError,
  updateIsProductDisciplineInternalError,
  updateIsAvailableToBuyOffersInternalError
} from "../../pages/pdp/productSlice";
import "./notification.scss";
import { VerifyAccountPopup } from "../verifyAccountPopup/VerifyAccountPopup";

export const Notification = React.memo((props) => {
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const isVerifiedAccount = useSelector(
    (state) => state.user.isVerifiedAccount
  );
  const isRefreshInstitution = useSelector(
    (state) => state.user.isRefreshInstitution
  );
  const isDisplayPdp = useSelector((state) => state.product.isDisplayPdp);
  const isProductAvailable = useSelector(
    (state) => state.product.isProductAvailable
  );
  const userData = useSelector((state) => state.user.userData);
  const isNoInstitutionProfile = useSelector(
    (state) => state.user.isNoInstitutionProfile
  );
  const isInternalError = useSelector(
    (state) => state.searchResult.isInternalError
  );
  const isUserServiceInternalError = useSelector(
    (state) => state.user.isUserServiceInternalError
  );
  const isSubjectCoutInternalError = useSelector(
    (state) => state.subject.isSubjectCoutInternalError
  );
  const isDisciplineInternalError = useSelector(
    (state) => state.discipline.isDisciplineInternalError
  );
  const isProductInternalError = useSelector(
    (state) => state.product.isProductInternalError
  );
  const isOfferInternalError = useSelector(
    (state) => state.product.isOfferInternalError
  );
  const isAvailableToBuyOffersInternalError = useSelector(
    (state) => state.product.isAvailableToBuyOffersInternalError
  );
  const isProductDisciplineInternalError = useSelector(
    (state) => state.product.isProductDisciplineInternalError
  );

  const config = window.__env.configs["appConfig"];
  let location = useLocation();
  let history = useHistory();
  let queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (
      userData &&
      userData.isInstructor &&
      userData.organizations &&
      userData.organizations.length === 0
    ) {
      dispatch(updateNoInstitutionProfile(true));
    }
  }, [userData, dispatch]);

  const handleClose = () => {
    props.setModalShow(false);
    setShow(false);
  };

  const closeNoInstitutionProfile = () => {
    dispatch(updateNoInstitutionProfile(false));
  };

  const closeInternalError = () => {
    dispatch(updateIsInternalError(false));
    dispatch(updateIsUserServiceInternalError(false));
    dispatch(updateIsSubjectCoutInternalError(false));
    dispatch(updateIsDisciplineInternalError(false));
    dispatch(updateIsProductInternalError(false));
    dispatch(updateIsOfferInternalError(false));
    dispatch(updateIsAvailableToBuyOffersInternalError(false));
    dispatch(updateIsProductDisciplineInternalError(false));
  };

  const displayNotification = () => {
    dispatch(updateRefreshInstitution(true));
  };

  const redirectToHome = () => {
    if (queryParams.has("uuid")) {
      queryParams.delete("uuid");
    }
    if (queryParams.has("q")) {
      queryParams.delete("q");
    }
    history.push(`/?${queryParams.toString()}`);
  };

  const backToPrevPage = () => {
    history.goBack();
  };

  const refreshCurrentWindow = () => {
    window.localStorage.removeItem("exchange.user.defaultInstitute");
    window.location.reload();
  };

  const verifyYourAccountClickHandler = () => {
    dispatch(updateIsVerifiedAccount(true));
    setShowModal(true);
  };

  const closeVerifyYourAccount = () => {
    dispatch(updateIsVerifiedAccount(true));
  };

  const verifyHandleClose = () => {
    setShowModal(false);
  };

  const refreshModalHandler = (event) => {
    event.setAttribute("aria-describedby", "refresh-para refresh-btn");
    event.setAttribute("id", "refresh-modal");
  };

  return (
    <React.Fragment>
      {isNoInstitutionProfile && (
        <div className="alert-wr">
          <Alert
            onClose={() => closeNoInstitutionProfile()}
            dismissible
            className="custom-alert d-flex flex-row"
            role="alert"
            aria-live="assertive">
            <span className="alert-icon" aria-hidden="true"></span>
            <span>
              <a
                href={`${
                  config.CONSOLE_URL
                }/account/manage/account?type=${encodeURIComponent(
                  "Return to Search"
                )}&returnUrl=${encodeURIComponent(
                  decodeURIComponent(window.location.href)
                )}`}
                target="_blank"
                rel="noreferrer"
                className="text-link"
                onClick={() => displayNotification()}>
                {" "}
                Add your institution{" "}
                <span className="sr-only">Opens in a new tab</span>
              </a>
              <span className="mb-0 notification-txt">
                to see all products and pricing options available.
              </span>
            </span>
          </Alert>
        </div>
      )}
      {!isVerifiedAccount && (
        <div className="alert-wr">
          <Alert
            onClose={() => closeVerifyYourAccount()}
            dismissible
            className="custom-alert d-flex flex-row align-items-center"
            role="alert"
            aria-live="assertive">
            <span
              className="alert-icon verify-instructor-icon"
              aria-hidden="true"></span>

            <p className="mb-0 notification-txt">
              <a
                href="javascript:void(0)"
                className="text-link verify-link"
                onClick={() => verifyYourAccountClickHandler()}>
                Verify your account{" "}
              </a>
              to get full access to courses you create. {""}
            </p>
          </Alert>
        </div>
      )}
      {showModal && (
        <VerifyAccountPopup
          showModal={showModal}
          verifyHandleClose={verifyHandleClose}
        />
      )}
      {isUserServiceInternalError && (
        <div className="alert-wr internal-error">
          <Alert
            onClose={() => closeInternalError()}
            dismissible
            className="custom-alert d-flex flex-row"
            role="alert"
            aria-live="assertive">
            <span className="alert-icon warning-icon" aria-hidden="true"></span>
            <div className="d-flex flex-column">
              <h1 className="notification-txt">
                <b>Due to a system error, we are unable to retrieve full details for this course.</b>
              </h1>
              <p className="notification-txt mb-3">
                Students will not be able to enroll and instructors will not be able to create a course at this time. Please try again later.
              </p>              
            </div>
          </Alert>
        </div>
      )}
      {(isInternalError ||
        isSubjectCoutInternalError ||
        isDisciplineInternalError ||
        isProductInternalError ||
        isOfferInternalError ||
        isAvailableToBuyOffersInternalError ||
        isProductDisciplineInternalError) && (
        <div className="alert-wr internal-error">
          <Alert
            onClose={() => closeInternalError()}
            dismissible
            className="custom-alert d-flex flex-row"
            role="alert"
            aria-live="assertive">
            <span className="alert-icon warning-icon" aria-hidden="true"></span>
            <div className="d-flex flex-column">
              <h1 className="notification-txt">
                <b>Oops, something went wrong.</b>
              </h1>
              <p className="notification-txt mb-3">
                The server encountered an internal error and was unable to
                complete your request.
              </p>
              <p className="notification-txt">
                <b>Cause :</b>
              </p>
              {isInternalError && (
                <React.Fragment>
                  <p className="notification-txt mb-3">
                    Error occured while loading Search Results.
                  </p>
                  <p className="notification-txt">
                    Search Service Unavailable!
                  </p>
                </React.Fragment>
              )}
              {isProductInternalError && (
                <React.Fragment>
                  <p className="notification-txt mb-3">
                    Error occurred while loading the product data.
                  </p>
                  <p className="notification-txt">
                    Product Repository Unavailable!
                  </p>
                </React.Fragment>
              )}
              {isOfferInternalError && (
                <React.Fragment>
                  <p className="notification-txt mb-3">
                    Error occurred while loading offers for this product.
                  </p>
                  <p className="notification-txt">
                    Offers Service Unavailable!
                  </p>
                </React.Fragment>
              )}
              {isAvailableToBuyOffersInternalError && (
                <React.Fragment>
                  <p className="notification-txt mb-3">
                    Error occurred while loading available to buy offers for this product.
                  </p>
                  <p className="notification-txt">
                    Available to buy offers Service Unavailable!
                  </p>
                </React.Fragment>
              )}
              {(isSubjectCoutInternalError || isDisciplineInternalError) && (
                <React.Fragment>
                  <p className="notification-txt mb-3">
                    Error information not available
                  </p>
                </React.Fragment>
              )}
              {isProductDisciplineInternalError && (
                <React.Fragment>
                  <p className="notification-txt mb-3">
                    Search Service Unavailable!
                  </p>
                </React.Fragment>
              )}
            </div>
          </Alert>
        </div>
      )}
      {isRefreshInstitution && (
        <Modal
          show={show}
          onHide={handleClose}
          className="refresh-modal-dialog"
          aria-labelledby="refresh-heading"
          onEntered={(event) => refreshModalHandler(event)}>
          <div className="share-modal-wr">
            <h2 className="mt-0" id="refresh-heading">
              Just a moment...{" "}
            </h2>
            <p id="refresh-para" tabIndex="-1">
              We’re refreshing this page so you can see pricing options for the
              institution you selected.
            </p>

            <button
              onClick={() => refreshCurrentWindow()}
              id="refresh-btn"
              className="custom-btn secondary-btn">
              Refresh
            </button>
          </div>
        </Modal>
      )}
      {!isDisplayPdp && (
        <div
          className="container noDisplay-PDP d-flex flex-column"
          role="alert"
          aria-live="assertive">
          <h1 className="my-0">Sorry, some pages are best left unseen.</h1>
          <p>It looks like you do not have permissions to access this page.</p>
          <button onClick={redirectToHome} className="custom-btn secondary-btn">
            Back to the homepage
          </button>
        </div>
      )}
      {!isProductAvailable && (
        <div
          className="container noDisplay-PDP d-flex flex-column error-404"
          role="alert"
          aria-live="assertive">
          <h1 className="my-0">Well…This is awkward.</h1>
          <p>You are looking for something that doesn't exist.</p>
          <button
            onClick={redirectToHome}
            className="custom-btn secondary-btn btn-404">
            Back to the homepage
          </button>
          <h2 className="my-0">What happened?</h2>
          <p>
            The page you're looking for appears to have been moved, deleted or
            does not exist. You could go to the home page, or back to{" "}
            <a
              href="javascript:void(0)"
              onClick={backToPrevPage}
              className="text-link">
              your previous page
            </a>
            .
          </p>
        </div>
      )}
    </React.Fragment>
  );
});
