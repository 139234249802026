import React, { useEffect, useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  PLATFORMS,
  PRODUCT_MODELS,
  OVERVIEW_DESCRIPTION_LENGTH,
  FEATURE_DESCRIPTION_LENGTH,
  TOC_DESCRIPTION_LENGTH,
  AUTHOR_DESCRIPTION_LENGTH,
  MODULE_LIST_LENGTH,
  TOC_LIST_LENGTH,
  PRODUCT_TABS,
} from "../../../config";
import "./productTabs.scss";
import DOMPurify from "dompurify";
import { ReadMore } from "../readMore/ReadMore";
import { Feature } from "../feature/Feature";
import { MobileProductTabNav } from "../mobileProductTabNav/MobileProductTabNav";
import {
  chngIsProductTopActive,
  updateProductTabs,
  updateActiveMobileProductTab,
} from "../../pages/pdp/productSlice";
import { Tabs, Tab } from "react-bootstrap";
import {
  getOneTrustCookieConsent,
  getOneTrustCookieConsentHosts,
  formatOneTrustConsent,
} from "../../util/userUtil";

export function ProductTabs(props) {
  const productTabs = useSelector((state) => state.product.productTabs);
  const activeMobileProductTab = useSelector(
    (state) => state.product.activeMobileProductTab
  );
  const params = new URLSearchParams(window.location.search);
  const lmsToken = params.get("integration_context_token");
  const productData = useSelector((state) => state.product.productData);
  const dispatch = useDispatch();
  let defaultActiveTab = useRef();
  const collectionTabContent = useRef();
  const [consentForVideoDisplay, setConsentForVideoDisplay] = useState(false);
  const [oneTrustConsent, setOneTrustConsent] = useState(false);

  const getPdpTabs = useCallback(() => {
    if (
      (props.productData.description && props.productData.description.html) ||
      getVideoURL() !== null
    ) {
      dispatch(updateProductTabs({ [PRODUCT_TABS.OVERVIEW.KEY]: true }));
    }

    if (lmsToken && lmsToken.length > 0) {
      dispatch(updateProductTabs({ [PRODUCT_TABS.GETTING_STARTED.KEY]: true }));
    } else {
      if (
        props.productData.potentialAction &&
        props.productData.potentialAction.useAction &&
        props.productData.potentialAction.useAction.length > 0 &&
        props.productData.potentialAction.useAction[0].description
      ) {
        dispatch(
          updateProductTabs({ [PRODUCT_TABS.GETTING_STARTED.KEY]: true })
        );
      }
    }

    if (
      (props.productDisciplineFeature &&
        props.productDisciplineFeature.contents &&
        props.productDisciplineFeature.contents.length > 0) ||
      (props.productData.productModel &&
        props.productData.productModel.features)
    ) {
      dispatch(updateProductTabs({ [PRODUCT_TABS.FEATURES.KEY]: true }));
    }

    if (props.productToc && Object.keys(props.productToc).length > 0) {
      dispatch(updateProductTabs({ [PRODUCT_TABS.CONTENTS.KEY]: true }));
    }

    if (
      props.productData.authors &&
      props.productData.authors.length > 0 &&
      props.productData.authors[0].biography
    ) {
      dispatch(updateProductTabs({ [PRODUCT_TABS.AUTHORS.KEY]: true }));
    }

    if (
      props.productData.productPlatform === PLATFORMS.COLLECTION &&
      props.productModule &&
      props.productModule.modules &&
      props.productModule.modules.length > 0
    ) {
      dispatch(updateProductTabs({ [PRODUCT_TABS.MODULE.KEY]: true }));
    }
  }, [
    props.productData,
    lmsToken,
    props.productDisciplineFeature,
    props.productToc,
    props.productModule,
    dispatch,
  ]);

  useEffect(() => {
    getPdpTabs();
  }, [getPdpTabs]);

  // collection product Tab navigation behaviour
  useEffect(() => {
    if (defaultActiveTab.current) {
      defaultActiveTab.current.querySelector("defaultKey");
    }

    const strConsent = getOneTrustCookieConsent();
    const strConsentHosts = getOneTrustCookieConsentHosts();

    let jsnConsent = {};
    let jsnConsentHosts = {};
    if (strConsent && strConsent.length > 0) {
      jsnConsent = formatOneTrustConsent(strConsent);
    }
    if (strConsentHosts && strConsentHosts.length > 0) {
      jsnConsentHosts = formatOneTrustConsent(strConsentHosts);
    }
   
    if (jsnConsent.C0003 && jsnConsent.C0004) {
      setConsentForVideoDisplay(true);
    } else {
      setConsentForVideoDisplay(false);
    }

    if (jsnConsentHosts.H3) {
      setConsentForVideoDisplay(true);
    } else {
      setConsentForVideoDisplay(false);
    }
  }, [oneTrustConsent]);

  useEffect(() => {
    window.OneTrust?.OnConsentChanged(() => {
      setOneTrustConsent((previous) => !previous);
    });
  }, []);

  const [isMobileViewPDPTabs, setIsTabMobileViewPDPTabs] = useState(false);
  const handleresponsiveView = () => {
    if (window.innerWidth < 600) {
      setIsTabMobileViewPDPTabs(true);
    } else {
      setIsTabMobileViewPDPTabs(false);
    }
  };

  useEffect(() => {
    handleresponsiveView();
    window.addEventListener("resize", handleresponsiveView);
    return () => window.removeEventListener("resize", handleresponsiveView);
  }, []);

  useEffect(() => {
    if (props.isTabMobileView && productTabs) {
      const tempProductTabs = Object.keys(productTabs);
      if (tempProductTabs.length > 0) {
        dispatch(updateActiveMobileProductTab(tempProductTabs[0]));
      }
    }
  }, [productTabs]);

  const handleOnSelectTab = () => {
    let topPos = collectionTabContent.current.offsetTop + 20;
    if (topPos) {
      window.scrollTo({
        top: topPos,
        behavior: "smooth",
      });
    }
  };

  const changeMySettingsHandler = () => {
    window.OneTrust?.ToggleInfoDisplay();
  };  

  const getVideoURL = () => {
    if (
      props.productDisciplineFeature &&
      props.productDisciplineFeature.potentialActions &&
      props.productDisciplineFeature.potentialActions.url
    ) {
      return props.productDisciplineFeature.potentialActions.url;
    } else if (props.productData.video && props.productData.video.embedUrl) {
      return props.productData.video.embedUrl;
    } else {
      return null;
    }
  };

  const getProductModelName = (productModel) => {
    let newModelName = "REVEL";
    switch (productModel) {
      case "REVEL_CITE":
      case "SUMATRA_CITE":
        newModelName = "REVEL";
        break;
      case "ETEXT2_CITE":
      case "ETEXT2_PXE":
      case "ETEXT1_PDF":
      case "ETEXT_EPUB_BRONTE":
      case "ETEXT_EPUB_STANDARD":
        newModelName = "Pearson eText";
        break;
      case "LEARNING_CATALYTICS":
        newModelName = "Live Response";
        break;
      case "RIO_CITE":
        newModelName = "RIO Cite";
        break;
      case "OZ_CITE":
        newModelName = "OZ Cite";
        break;
      case "LRX":
        newModelName = "Lorax";
        break;
      default:
        newModelName = "REVEL";
        break;
    }

    return newModelName;
  };

  const getOverview = () => {
    const videoURL = getVideoURL();

    return (
      <div
        id={PRODUCT_TABS.OVERVIEW.KEY}
        data-scroll={PRODUCT_TABS.OVERVIEW.KEY}
        className="anchor_offset overview"
        role="tabpanel"
        aria-labelledby={PRODUCT_TABS.OVERVIEW.KEY}>
        {videoURL && videoURL.length > 0 && consentForVideoDisplay && (
          <div className="video-container">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item optanon-category-C0004"
                src={videoURL}
                allowfullscreen></iframe>
            </div>
          </div>
        )}

        {videoURL && videoURL.length > 0 && !consentForVideoDisplay && (
          <div className="video-container">
            <div className="alert youtube" role="alert">
              <div className="notification-txt">
                This content is hosted by a third party (www.youtube.com). To view it, 
                please change your cookie settings to allow cookies from this vendor. 
                Be sure to search in "Functional Cookies" or "Targeting Cookies" sections.
              </div>
              <button type="button" className="custom-btn outline-btn feature-btn" onClick={() => changeMySettingsHandler()}>
                Change my settings
              </button>              
            </div>
          </div>
        )}

        {props.productData &&
          props.productData.productPlatform !== PLATFORMS.COLLECTION &&
          props.productData.description &&
          props.productData.description.html && (
            <div className="overview-content">
              <h2 className="pdp-tabcontent-sub-title" id="showMoreOverview">
                {PRODUCT_TABS.OVERVIEW.VALUE}
              </h2>

              <ReadMore
                content={DOMPurify.sanitize(props.productData.description.html)}
                descriptionLength={OVERVIEW_DESCRIPTION_LENGTH}
                buttonId="showMoreButtonOverview"
                headingId="showMoreOverview"
              />
            </div>
          )}
      </div>
    );
  };

  const getGettingStarted = () => {
    if (lmsToken && lmsToken.length > 0) {
      const newModelName = getProductModelName(
        props.productData.productModel.name
      );
      return (
        <div
          id={PRODUCT_TABS.GETTING_STARTED.KEY}
          data-scroll={PRODUCT_TABS.GETTING_STARTED.KEY}
          className="getting-started anchor_offset"
          role="tabpanel"
          aria-labelledby={PRODUCT_TABS.GETTING_STARTED.KEY}>
          <h2 className="pdp-tabcontent-sub-title">
            {PRODUCT_TABS.GETTING_STARTED.VALUE}
          </h2>
          <div>
            Click <b>SELECT</b> to choose the {newModelName} content to add to
            your course. Next, you'll enter content details and place the link
            to {newModelName} from your course. After you create assignments and
            let the students know the location of the link, you're ready to
            start teaching with {newModelName} .
          </div>
        </div>
      );
    } else {
      return (
        <div
          id={PRODUCT_TABS.GETTING_STARTED.KEY}
          data-scroll={PRODUCT_TABS.GETTING_STARTED.KEY}
          className="getting-started anchor_offset"
          role="tabpanel"
          aria-labelledby={PRODUCT_TABS.GETTING_STARTED.KEY}>
          {productData.productPlatform !== PLATFORMS.COLLECTION ? (
            <h2 className="pdp-tabcontent-sub-title">
              {PRODUCT_TABS.GETTING_STARTED.VALUE}
            </h2>
          ) : (
            <h3 className="pdp-tabcontent-sub-title">
              {PRODUCT_TABS.GETTING_STARTED.VALUE}
            </h3>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                props.productData.potentialAction.useAction[0].description
              ),
            }}
          />
        </div>
      );
    }
  };

  const getFeatures = () => {
    if (
      props.productData.productPlatform === PLATFORMS.ETEXT ||
      props.productData.productModel.name === PRODUCT_MODELS.ETEXT2_CITE
    ) {
      return (
        <div
          id={PRODUCT_TABS.FEATURES.KEY}
          data-scroll={PRODUCT_TABS.FEATURES.KEY}
          className="anchor_offset features"
          role="tabpanel"
          aria-labelledby={PRODUCT_TABS.FEATURES.KEY}>
          {productData.productPlatform !== PLATFORMS.COLLECTION ? (
            <h2 className="pdp-tabcontent-sub-title" id="showMoreFeatures">
              {PRODUCT_TABS.FEATURES.VALUE}
            </h2>
          ) : (
            <h3 className="pdp-tabcontent-sub-title" id="showMoreFeatures">
              {PRODUCT_TABS.FEATURES.VALUE}
            </h3>
          )}
          <div className="feature-subcontent">
            {props.productData.productModel &&
              props.productData.productModel.features &&
              props.productData.productModel.features.length > 0 && (
                <ReadMore
                  content={DOMPurify.sanitize(
                    props.productData.productModel.features
                  )}
                  descriptionLength={FEATURE_DESCRIPTION_LENGTH}
                  buttonId="showMoreButtonFeatures"
                  headingId="showMoreFeatures"
                />
              )}
          </div>
          {props.productDisciplineFeature &&
            props.productDisciplineFeature.contents &&
            props.productDisciplineFeature.contents.length > 0 && (
              <Feature
                productDisciplineFeature={props.productDisciplineFeature}
              />
            )}
        </div>
      );
    } else {
      if (
        props.productDisciplineFeature &&
        props.productDisciplineFeature.contents &&
        props.productDisciplineFeature.contents.length > 0
      ) {
        return (
          <div
            id={PRODUCT_TABS.FEATURES.KEY}
            data-scroll={PRODUCT_TABS.FEATURES.KEY}
            className="anchor_offset features"
            role="tabpanel"
            aria-labelledby={PRODUCT_TABS.FEATURES.KEY}>
            {productData.productPlatform !== PLATFORMS.COLLECTION ? (
              <h2 className="pdp-tabcontent-sub-title" id="showMoreFeatures">
                {PRODUCT_TABS.FEATURES.VALUE}
              </h2>
            ) : (
              <h3 className="pdp-tabcontent-sub-title" id="showMoreFeatures">
                {" "}
                {PRODUCT_TABS.FEATURES.VALUE}
              </h3>
            )}
            <Feature
              productDisciplineFeature={props.productDisciplineFeature}
            />
          </div>
        );
      } else {
        if (
          props.productData.productModel &&
          props.productData.productModel.features
        ) {
          return (
            <div
              id={PRODUCT_TABS.FEATURES.KEY}
              data-scroll={PRODUCT_TABS.FEATURES.KEY}
              className="anchor_offset features"
              role="tabpanel"
              aria-labelledby={PRODUCT_TABS.FEATURES.KEY}>
              {productData.productPlatform !== PLATFORMS.COLLECTION ? (
                <h2 className="pdp-tabcontent-sub-title" id="showMoreFeatures">
                  {PRODUCT_TABS.FEATURES.VALUE}
                </h2>
              ) : (
                <h3 className="pdp-tabcontent-sub-title" id="showMoreFeatures">
                  {PRODUCT_TABS.FEATURES.VALUE}
                </h3>
              )}

              {props.productData.productModel &&
                props.productData.productModel.features &&
                props.productData.productModel.features.length > 0 && (
                  <ReadMore
                    content={DOMPurify.sanitize(
                      props.productData.productModel.features
                    )}
                    descriptionLength={FEATURE_DESCRIPTION_LENGTH}
                    buttonId="showMoreButtonFeatures"
                    headingId="showMoreFeatures"
                  />
                )}
            </div>
          );
        }
      }
    }
  };

  const getContents = () => {
    return (
      <div
        id={PRODUCT_TABS.CONTENTS.KEY}
        data-anchor={PRODUCT_TABS.CONTENTS.KEY}
        className="anchor_offset toc"
        role="tabpanel"
        aria-labelledby={PRODUCT_TABS.CONTENTS.KEY}>
        <h2 className="pdp-tabcontent-sub-title" id="showMoreToc">
          Table of contents
        </h2>
        {((props.productToc.toc && props.productToc.toc.length > 0) ||
          (props.productToc.tocString &&
            props.productToc.tocString.length > 0)) && (
          <ReadMore
            isToc={true}
            productToc={props.productToc}
            itemListLength={TOC_LIST_LENGTH}
            content={
              props.productToc.tocString
                ? DOMPurify.sanitize(props.productToc.tocString)
                : ""
            }
            descriptionLength={TOC_DESCRIPTION_LENGTH}
            buttonId="showMoreButtonToc"
            headingId="showMoreToc"
          />
        )}
      </div>
    );
  };

  const getModules = () => {
    return (
      <div
        id={PRODUCT_TABS.MODULE.KEY}
        data-anchor={PRODUCT_TABS.MODULE.KEY}
        className="anchor_offset modules"
        role="tabpanel"
        aria-labelledby={PRODUCT_TABS.MODULE.KEY}>
        <h3 className="pdp-tabcontent-sub-title" id="showMoreModule">
          {PRODUCT_TABS.MODULE.VALUE}
        </h3>

        {props.productModule.modules && (
          <ReadMore
            isModule={true}
            productModule={props.productModule}
            itemListLength={MODULE_LIST_LENGTH}
            buttonId="showMoreButtonModule"
            headingId="showMoreModule"
          />
        )}
      </div>
    );
  };

  const getAuthros = () => {
    return (
      <div
        id={PRODUCT_TABS.AUTHORS.KEY}
        data-scroll={PRODUCT_TABS.AUTHORS.KEY}
        className="author anchor_offset"
        role="tabpanel"
        aria-labelledby={PRODUCT_TABS.AUTHORS.KEY}>
        {productData.productPlatform !== PLATFORMS.COLLECTION ? (
          <h2 className="pdp-tabcontent-sub-title" id="showMoreAuthor">
            About the author
          </h2>
        ) : (
          <h3 className="pdp-tabcontent-sub-title" id="showMoreAuthor">
            About the author
          </h3>
        )}

        <ReadMore
          content={DOMPurify.sanitize(props.productData.authors[0].biography)}
          descriptionLength={AUTHOR_DESCRIPTION_LENGTH}
          buttonId="showMoreButtonAuthor"
          headingId="showMoreAuthor"
        />
      </div>
    );
  };

  const getNonCollectionOverviewProductTabs = () => {
    return (
      <React.Fragment>
        {productTabs && productTabs.overview && getOverview()}
        {productTabs && productTabs.gettingStarted && getGettingStarted()}
        {productTabs && productTabs.features && getFeatures()}
        {productTabs && productTabs.contents && getContents()}
        {productTabs && productTabs.authors && getAuthros()}
      </React.Fragment>
    );
  };

  const getNonCollectionGettingStartedProductTabs = () => {
    return (
      <React.Fragment>
        {productTabs && productTabs.gettingStarted && getGettingStarted()}
        {productTabs && productTabs.features && getFeatures()}
        {productTabs && productTabs.contents && getContents()}
        {productTabs && productTabs.authors && getAuthros()}
      </React.Fragment>
    );
  };

  const getNonCollectionFeaturesProductTabs = () => {
    return (
      <React.Fragment>
        {productTabs && productTabs.features && getFeatures()}
        {productTabs && productTabs.contents && getContents()}
        {productTabs && productTabs.authors && getAuthros()}
      </React.Fragment>
    );
  };

  const getNonCollectionContentsProductTabs = () => {
    return (
      <React.Fragment>
        {productTabs && productTabs.contents && getContents()}
        {productTabs && productTabs.authors && getAuthros()}
      </React.Fragment>
    );
  };

  const getCollectionOverviewProductTabs = () => {
    return (
      <React.Fragment>
        {productTabs && productTabs.overview && getOverview()}
        {productTabs && productTabs.features && getFeatures()}
        {productTabs && productTabs.module && getModules()}
        {productTabs && productTabs.authors && getAuthros()}
        {productTabs && productTabs.gettingStarted && getGettingStarted()}
      </React.Fragment>
    );
  };

  const getCollectionFeaturesProductTabs = () => {
    return (
      <React.Fragment>
        {productTabs && productTabs.features && getFeatures()}
        {productTabs && productTabs.module && getModules()}
        {productTabs && productTabs.authors && getAuthros()}
        {productTabs && productTabs.gettingStarted && getGettingStarted()}
      </React.Fragment>
    );
  };

  const getCollectionModulesProductTabs = () => {
    return (
      <React.Fragment>
        {productTabs && productTabs.module && getModules()}
        {productTabs && productTabs.authors && getAuthros()}
        {productTabs && productTabs.gettingStarted && getGettingStarted()}
      </React.Fragment>
    );
  };

  const getCollectionAuthorsProductTabs = () => {
    return (
      <React.Fragment>
        {productTabs && productTabs.authors && getAuthros()}
        {productTabs && productTabs.gettingStarted && getGettingStarted()}
      </React.Fragment>
    );
  };

  return (
    <div id="pdp-menu">
      {!isMobileViewPDPTabs ? (
        <React.Fragment>
          {props.productData.productPlatform !== PLATFORMS.COLLECTION ? (
            <React.Fragment>
              {/* <NavigationBar /> */}
              <div className="pdp-tabcontent" ref={collectionTabContent}>
                <Tabs
                  aria-label="product details"
                  id="collection-tab-navigation"
                  transition={false}
                  onSelect={handleOnSelectTab}
                  ref={defaultActiveTab}>
                  {productTabs && productTabs.overview && getOverview() && (
                    <Tab
                      eventKey={PRODUCT_TABS.OVERVIEW.KEY}
                      title={PRODUCT_TABS.OVERVIEW.VALUE}
                      className="defaultKey">
                      {getNonCollectionOverviewProductTabs()}
                    </Tab>
                  )}
                  {productTabs &&
                    productTabs.gettingStarted &&
                    getGettingStarted() && (
                      <Tab
                        eventKey={PRODUCT_TABS.GETTING_STARTED.KEY}
                        title={PRODUCT_TABS.GETTING_STARTED.VALUE}
                        className="defaultKey">
                        {getNonCollectionGettingStartedProductTabs()}
                      </Tab>
                    )}
                  {productTabs && productTabs.features && getFeatures() && (
                    <Tab
                      eventKey={PRODUCT_TABS.FEATURES.KEY}
                      title={PRODUCT_TABS.FEATURES.VALUE}
                      className="defaultKey">
                      {getNonCollectionFeaturesProductTabs()}
                    </Tab>
                  )}
                  {productTabs && productTabs.contents && getContents() && (
                    <Tab
                      eventKey={PRODUCT_TABS.CONTENTS.KEY}
                      title={PRODUCT_TABS.CONTENTS.VALUE}
                      className="defaultKey">
                      {getNonCollectionContentsProductTabs()}
                    </Tab>
                  )}
                  {productTabs && productTabs.authors && getAuthros() && (
                    <Tab
                      eventKey={PRODUCT_TABS.AUTHORS.KEY}
                      title={PRODUCT_TABS.AUTHORS.VALUE}
                      className="defaultKey">
                      {productTabs && productTabs.authors && getAuthros()}
                    </Tab>
                  )}
                </Tabs>
              </div>
            </React.Fragment>
          ) : (
            <div className="pdp-tabcontent" ref={collectionTabContent}>
              <Tabs
                aria-label="product details"
                id="collection-tab-navigation"
                transition={false}
                onSelect={handleOnSelectTab}
                ref={defaultActiveTab}>
                {productTabs && productTabs.overview && getOverview() && (
                  <Tab
                    eventKey={PRODUCT_TABS.OVERVIEW.KEY}
                    title={PRODUCT_TABS.OVERVIEW.VALUE}
                    className="defaultKey">
                    {getCollectionOverviewProductTabs()}
                  </Tab>
                )}

                {productTabs && productTabs.features && getFeatures() && (
                  <Tab
                    eventKey={PRODUCT_TABS.FEATURES.KEY}
                    title={PRODUCT_TABS.FEATURES.VALUE}
                    className="defaultKey">
                    {getCollectionFeaturesProductTabs()}
                  </Tab>
                )}
                {productTabs && productTabs.module && getModules() && (
                  <Tab
                    eventKey={PRODUCT_TABS.MODULE.KEY}
                    title={PRODUCT_TABS.MODULE.VALUE}
                    className="defaultKey">
                    {getCollectionModulesProductTabs()}
                  </Tab>
                )}
                {productTabs && productTabs.authors && getAuthros() && (
                  <Tab
                    eventKey={PRODUCT_TABS.AUTHORS.KEY}
                    title={PRODUCT_TABS.AUTHORS.VALUE}
                    className="defaultKey">
                    {getCollectionAuthorsProductTabs()}
                  </Tab>
                )}
                {productTabs &&
                  productTabs.gettingStarted &&
                  getGettingStarted() && (
                    <Tab
                      eventKey={PRODUCT_TABS.GETTING_STARTED.KEY}
                      title={PRODUCT_TABS.GETTING_STARTED.VALUE}
                      className="defaultKey">
                      {productTabs &&
                        productTabs.gettingStarted &&
                        getGettingStarted()}
                    </Tab>
                  )}
              </Tabs>
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="pdp-tabcontent">
          <MobileProductTabNav />
          {props.productData.productPlatform !== PLATFORMS.COLLECTION ? (
            <div className="tab-content">
              {activeMobileProductTab === PRODUCT_TABS.OVERVIEW.KEY &&
                getNonCollectionOverviewProductTabs()}
              {activeMobileProductTab === PRODUCT_TABS.GETTING_STARTED.KEY &&
                getNonCollectionGettingStartedProductTabs()}
              {activeMobileProductTab === PRODUCT_TABS.FEATURES.KEY &&
                getNonCollectionFeaturesProductTabs()}
              {activeMobileProductTab === PRODUCT_TABS.CONTENTS.KEY &&
                getNonCollectionContentsProductTabs()}
              {activeMobileProductTab === PRODUCT_TABS.AUTHORS.KEY &&
                productTabs &&
                productTabs.authors &&
                getAuthros()}
            </div>
          ) : (
            <div className="tab-content">
              {activeMobileProductTab === PRODUCT_TABS.OVERVIEW.KEY &&
                getCollectionOverviewProductTabs()}
              {activeMobileProductTab === PRODUCT_TABS.FEATURES.KEY &&
                getCollectionFeaturesProductTabs()}
              {activeMobileProductTab === PRODUCT_TABS.MODULE.KEY &&
                getCollectionModulesProductTabs()}
              {activeMobileProductTab === PRODUCT_TABS.AUTHORS.KEY &&
                getCollectionAuthorsProductTabs()}
              {activeMobileProductTab === PRODUCT_TABS.GETTING_STARTED.KEY &&
                productTabs &&
                productTabs.gettingStarted &&
                getGettingStarted()}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
