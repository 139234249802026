import React from "react";
import { useSelector } from "react-redux";
import { InstitutionalLicensing } from "./institutionalLicensing/InstitutionalLicensing";
import { AccessCode } from "./accessCode/AccessCode";

export const InstitutionalOfferTab = (props) => {
  const productAvailableToBuyOffers = useSelector(
    (state) => state.product.productAvailableToBuyOffers
  );
  const productOffers = useSelector((state) => state.product.productOffers);

  return (
    <React.Fragment>
      {productAvailableToBuyOffers &&
        productAvailableToBuyOffers.availableTobuy &&
        productAvailableToBuyOffers.availableTobuy.length > 0 && (
          <InstitutionalLicensing />
        )}
      {props.accessCodeOffers && productOffers && !productOffers.comingSoon && (
        <AccessCode accessCodeOffers={props.accessCodeOffers} />
      )}
      <div className="access-code-wr institutional-licensing-wr">
        <p className="sub-text pricing-des mb-0">
          <sup>*</sup>Pricing shown reflects cost when institutions purchase directly from Pearson
        </p>
      </div>
    </React.Fragment>
  );
};
