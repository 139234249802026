import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  getSearchUrl,
  getsecondaryDisciplinesCodes,
} from "../../util/disciplineUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSelectedDisciplineCategory,
  updateSelectedPrimaryDiscipline,
} from "../discipline/disciplineSlice";
import { PAGES } from "../../../config";
import { updateSelectedSubject } from "../../widgets/subject/subjectSlice";
import { resetSelectedSort } from "../sort/sortSlice";
import { resetSelectedShow } from "../show/showSlice";
import { resetSelectedPlatform } from "../platform/platformSlice";
import { updateIsDisplay } from "./hamburgerSlice";
import { updateSendSearchRequest, resetSearch } from "../search/searchSlice";
import { resetSelectedSubject } from "../subject/subjectSlice";
import { updateIsRedirectToSearch, updateCurrentResultPage } from "../searchResult/searchResultSlice";
import useRefPlatforms from "../../util/useRefPlatforms";

export function Discipline(props) {
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const [handleRefPlatform] = useRefPlatforms();

  const disciplineCategoryClickHandler = (event) => {
    dispatch(resetSearch()); 
    dispatch(resetSelectedSort());
    dispatch(resetSelectedShow());
    dispatch(resetSelectedPlatform());
    dispatch(updateCurrentResultPage(1));
    dispatch(resetSelectedSubject());   
    dispatch(updateSelectedDisciplineCategory(event.target.innerText));
    if (props.cPage === PAGES.SERP) {
      dispatch(
        updateSelectedSubject({
          type: event.target.innerText,
          displayValue: event.target.innerText,
        })
      );
    }
    if (!event.ctrlKey) {
      dispatch(updateIsDisplay(false));
    }
    if (props.cPage !== PAGES.SERP) {
      handleRefPlatform();
    }
    dispatch(updateIsRedirectToSearch(true));
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
  };

  const primaryDisciplineClickHandler = (event) => {
    dispatch(resetSearch());
    dispatch(resetSelectedSort());
    dispatch(resetSelectedShow());
    dispatch(resetSelectedPlatform());
    dispatch(updateCurrentResultPage(1));
    dispatch(resetSelectedSubject());    
    dispatch(updateSelectedPrimaryDiscipline(event.target.innerText));
    if (props.cPage === PAGES.SERP) {
      dispatch(
        updateSelectedSubject({
          type: event.target.getAttribute("data-discipline-codes"),
          displayValue: event.target.innerText,
        })
      );
    }
    if (!event.ctrlKey) {
      dispatch(updateIsDisplay(false));
    }
    if (props.cPage !== PAGES.SERP) {
      handleRefPlatform();
    }
    dispatch(updateIsRedirectToSearch(true));
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
  };

  return (
    <React.Fragment>
      {
        <div className="hamburger-content">
          <Link
            className="hb-df-titles"
            to={getSearchUrl(queryParams, true, props.discipline, null)}
            onClick={(event) => disciplineCategoryClickHandler(event)}
            aria-label={props.discipline.name}>
            {props.discipline.name}
          </Link>
          <ul>
            {props.discipline.primaryDisciplines &&
              props.discipline.primaryDisciplines.map(
                (primaryDiscipline, index) => (
                  <li key={index}>
                    <Link
                      to={getSearchUrl(
                        queryParams,
                        false,
                        null,
                        primaryDiscipline.secondaryDisciplines
                      )}
                      onClick={(event) => primaryDisciplineClickHandler(event)}
                      data-discipline-codes={getsecondaryDisciplinesCodes(
                        primaryDiscipline.secondaryDisciplines
                      )}
                      aria-label={primaryDiscipline.name}>
                      {primaryDiscipline.name}
                    </Link>
                  </li>
                )
              )}
          </ul>
        </div>
      }
    </React.Fragment>
  );
}
