import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./mobileFeature.scss";
import { UpdateSharePopup } from "../UpdateSharePopup/UpdateSharePopup";

export const MobileFeature = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [androidFeature, setAndroidFeature] = useState(null);
  const [appleFeature, setAppleFeature] = useState(null);
  const userData = useSelector((state) => state.user.userData);
  const productMobileFeature = useSelector(
    (state) => state.product.productMobileFeature
  );
  const getFeatures = () => {
    if (
      productMobileFeature.appCTAs &&
      productMobileFeature.appCTAs.length > 0
    ) {
      if (
        productMobileFeature.appCTAs.length === 1 &&
        productMobileFeature.appCTAs[0].platformType === "android"
      ) {
        setAndroidFeature(productMobileFeature.appCTAs[0]);
      } else if (
        productMobileFeature.appCTAs.length === 1 &&
        productMobileFeature.appCTAs[0].platformType === "iTunes"
      ) {
        setAppleFeature(productMobileFeature.appCTAs[0]);
      } else if (productMobileFeature.appCTAs.length === 2) {
        if (productMobileFeature.appCTAs[0].platformType === "iTunes") {
          setAppleFeature(productMobileFeature.appCTAs[0]);
          setAndroidFeature(productMobileFeature.appCTAs[1]);
        } else {
          setAppleFeature(productMobileFeature.appCTAs[1]);
          setAndroidFeature(productMobileFeature.appCTAs[0]);
        }
      }
    }
  };

  useEffect(() => {
    getFeatures();
  }, []);

  return (
    <React.Fragment>
      {productMobileFeature && productMobileFeature.primaryTitle && (
        <div className="mobile-component">
          <div className="d-flex flex-row justify-content-between align-items-center mobile-title-section">
            <h3 className="my-0">{productMobileFeature.primaryTitle}</h3>
            <img
              className="mobile-component-img"
              src={productMobileFeature.resourceUrl}
              alt=""
            />
          </div>
          <div className="mobile-content-section">
            <h4 className="my-0">{productMobileFeature.secondaryTitle}</h4>
            {userData && userData.isStudent && (
              <p>{productMobileFeature.studentDescription}</p>
            )}
            {(!userData || (userData && userData.isInstructor)) && (
              <p className="my-0">{productMobileFeature.description}</p>
            )}
          </div>
          <div className="app-brand-icon d-flex">
            {appleFeature && (
              <a
                href={appleFeature.ctaUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-describedby="iosLink"
                aria-label="Download on the appstore">
                <span className="sr-only" id="iosLink">
                  Opens in a new tab
                </span>
                <img
                  className="app-store-icon"
                  src={appleFeature.ctaImageUrl}
                  alt=""
                  aria-hidden="true"
                />
              </a>
            )}
            {androidFeature && (
              <a
                href={androidFeature.ctaUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-describedby="androidLink"
                aria-label="Get it on Google play">
                <span className="sr-only" id="androidLink">
                  Opens in a new tab
                </span>
                <img
                  className="google-play-icon"
                  src={androidFeature.ctaImageUrl}
                  alt=""
                  aria-hidden="true"
                />
              </a>
            )}
          </div>
          {userData && userData.isInstructor && (
            <button
              onClick={() => setModalShow(true)}
              className="custom-btn outline-btn share-btn">
              Share
            </button>
          )}
          {modalShow && (
            <UpdateSharePopup
              setModalShow={setModalShow}
              mobileFeature={productMobileFeature}
              appleFeature={appleFeature}
              androidFeature={androidFeature}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};
