import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Discipline } from "./Discipline";
import { PAGES } from "../../../config";
import { updateIsDisplay } from "./hamburgerSlice";
import "./hamburger.scss";

export function HamburgerContainer(props) {
  const dispatch = useDispatch();
  const disciplineList = useSelector(
    (state) => state.discipline.disciplineList
  );
  const isDisplay = useSelector((state) => state.hamburger.isDisplay);
  const searchValue = useSelector((state) => state.search.searchValue);

  const disciplineFacetOpen = () => {
    let openDisciplines = "hm-nav";
    if (isDisplay === true) {
      openDisciplines += " hm-open";
    }
    return openDisciplines;
  };

  return (
    <React.Fragment>
      {props.cPage === PAGES.PDP || props.cPage === PAGES.GET_START || props.cPage === PAGES.NOT_FOUND ? (
        <div className="pdp-hamburger">
          <div className="hamburger-nav-pdp">
            <button
              className={"hamburger-icon " + disciplineFacetOpen()}
              data-toggle="collapse"
              aria-expanded={isDisplay}
              aria-label="Hamburger Menu"
              onClick={() => {
                dispatch(updateIsDisplay(!isDisplay));
              }}></button>
          </div>
          {isDisplay && disciplineList && (
            <div className="hb-nav-content col-12" style={props.displayHamburgerContent}>
              <h2 className={"hm-close " + disciplineFacetOpen()}>
                Browse your subject
              </h2>
              <div className="hm-pdp">
                {disciplineList._embedded &&
                  disciplineList._embedded.disciplineCategories &&
                  disciplineList._embedded.disciplineCategories.map(
                    (discipline, index) => (
                      <div className="content-col col-4" key={index}>
                        <Discipline
                          discipline={discipline}
                          searchValue={searchValue}
                          setIsDisplay={isDisplay}
                          cPage={props.cPage}
                        />
                      </div>
                    )
                  )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="hamburger-nav">
          <button
            className={"hamburger-icon " + disciplineFacetOpen()}
            data-toggle="collapse"
            aria-expanded={isDisplay}
            aria-label="Hamburger Menu"
            onClick={() => {
              dispatch(updateIsDisplay(!isDisplay));
            }}></button>
          {isDisplay && disciplineList && (
            <div className="hb-nav-content col-12" style={props.displayHamburgerContent}>
              <h2 className={"hm-close " + disciplineFacetOpen()}>
                Browse your subject
              </h2>
              {disciplineList._embedded &&
                disciplineList._embedded.disciplineCategories &&
                disciplineList._embedded.disciplineCategories.map(
                  (discipline, index) => (
                    <div className="content-col col-4" key={index}>
                      <Discipline
                        discipline={discipline}
                        searchValue={searchValue}
                        setIsDisplay={isDisplay}
                        cPage={props.cPage}
                      />
                    </div>
                  )
                )}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
