import { useDispatch, useSelector } from "react-redux";
import { updateSelectedPlatform } from "../widgets/platform/platformSlice";
import {
  updateSelectedSort,
  resetSelectedSort,
} from "../widgets/sort/sortSlice";
import { SERP_PLATFORMS, SERP_SORT, USER_ROLE } from "../../config";
import { useLocation } from "react-router-dom";

export default function useRefPlatforms() {
  let location = useLocation();
  const userRole = useSelector((state) => state.user.userRole);
  let queryParams = new URLSearchParams(location.search);
  const selectedSortDisplayName = useSelector(
    (state) => state.sort.selectedSortDisplayName
  );
  const dispatch = useDispatch();

  const resetSortByPublicationDate = () => {
    if (window.performance) {
      if (
        window.performance.getEntriesByType("navigation") &&
        window.performance.getEntriesByType("navigation").length > 0 &&
        window.performance.getEntriesByType("navigation")[0].type !== "reload"
      ) {
        dispatch(resetSelectedSort());
      }
      // window.performance.navigation.type is deprecated.
      // older veriosns from iOS 15.1 are not supporting 'performance.getEntriesByType("navigation")[0]'
      else if (
        window.performance.navigation &&
        window.performance.navigation.type !== 1
      ) {
        dispatch(resetSelectedSort());
      }
    }
  };

  const handleRefPlatform = () => {
    if (
      queryParams.has("ref") &&
      queryParams.get("ref").toLowerCase().includes("revel")
    ) {
      if (userRole !== USER_ROLE.HIGHER_ED_INSTRUCTOR) {
        dispatch(
          updateSelectedSort({
            type: SERP_SORT.PUBLICATION_DATE.type,
            displayValue: SERP_SORT.PUBLICATION_DATE.displayValue,
          })
        );
      } else if (
        userRole === USER_ROLE.HIGHER_ED_INSTRUCTOR &&
        selectedSortDisplayName === SERP_SORT.PUBLICATION_DATE.displayValue
      ) {
        resetSortByPublicationDate();
      }
      dispatch(
        updateSelectedPlatform({
          type: SERP_PLATFORMS.REVEL.type,
          displayValue: SERP_PLATFORMS.REVEL.displayValue,
        })
      );
    } else if (
      queryParams.has("ref") &&
      queryParams.get("ref").toLowerCase() === "etext"
    ) {
      dispatch(
        updateSelectedPlatform({
          type: SERP_PLATFORMS.ETEXT.type,
          displayValue: SERP_PLATFORMS.ETEXT.displayValue,
        })
      );
    } else if (
      queryParams.has("ref") &&
      queryParams.get("ref").toLowerCase() === "collections"
    ) {
      dispatch(
        updateSelectedPlatform({
          type: SERP_PLATFORMS.CUSTOM.type,
          displayValue: SERP_PLATFORMS.CUSTOM.displayValue,
        })
      );
    } else if (
      queryParams.has("ref") &&
      queryParams.get("ref").toLowerCase().includes("console") &&
      userRole !== USER_ROLE.HIGHER_ED_INSTRUCTOR
    ) {
      dispatch(
        updateSelectedSort({
          type: SERP_SORT.PUBLICATION_DATE.type,
          displayValue: SERP_SORT.PUBLICATION_DATE.displayValue,
        })
      );
    } else if (
      queryParams.has("ref") &&
      queryParams.get("ref").toLowerCase().includes("console") &&
      userRole === USER_ROLE.HIGHER_ED_INSTRUCTOR &&
      selectedSortDisplayName === SERP_SORT.PUBLICATION_DATE.displayValue
    ) {
      resetSortByPublicationDate();
    } else if (
      !queryParams.has("ref") &&
      userRole !== USER_ROLE.HIGHER_ED_INSTRUCTOR
    ) {
      dispatch(
        updateSelectedSort({
          type: SERP_SORT.PUBLICATION_DATE.type,
          displayValue: SERP_SORT.PUBLICATION_DATE.displayValue,
        })
      );
    } else if (
      userRole === USER_ROLE.HIGHER_ED_INSTRUCTOR &&
      selectedSortDisplayName === SERP_SORT.PUBLICATION_DATE.displayValue
    ) {
      resetSortByPublicationDate();
    }
  };
  return [handleRefPlatform];
}
