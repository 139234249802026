import getSymbolFromCurrency from "currency-symbol-map";

export const getCurrencySymbol = (currency) => {
  const listOfCurrencies = ["USD", "AUD", "CAD", "NZD"];
  if (listOfCurrencies.includes(currency)) {
    return getSymbolFromCurrency("USD");
  } else if (["GBP", "EUR", "TRY"].includes(currency)) {
    return getSymbolFromCurrency(currency);
  } else {
    return "";
  }
};
