import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { SubjectContainer } from "../../widgets/subject/SubjectContainer";
import { Platform } from "../../widgets/platform/Platform";
import { Sort } from "../../widgets/sort/Sort";
import { Show } from "../../widgets/show/Show";
import { Filters } from "../../widgets/filters/Filters";
import { ContextAwareToggle } from "../../widgets/Custom-toggle-accordion/ContextAwareToggle";
import { useSelector, useDispatch } from "react-redux";
import { resetSelectedSort } from "../sort/sortSlice";
import { resetSelectedShow } from "../show/showSlice";
import "./serpLeftbar.scss";
import { ReactComponent as FacetArrowIcon } from "../../images/filter-arrow-icon.svg";
import { ReactComponent as FilterCloseIcon } from "../../images/filter-close.svg";

export function SERPleftbar(props) {
  const dispatch = useDispatch();
  const selectedPlatform = useSelector(
    (state) => state.platform.selectedPlatform
  );
  const displaySerpLeftBar = useSelector(
    (state) => state.serpLeftBar.displaySerpLeftBar
  );
  const [isSortByDisplay, setIsSortByDisplay] = useState(true);
  const [isShowByDisplay, setIsShowByDisplay] = useState(true);

  useEffect(() => {
    if (selectedPlatform && selectedPlatform === "applications") {
      setIsSortByDisplay(false);
      dispatch(resetSelectedSort());
    } else {
      setIsSortByDisplay(true);
    }

    if (
      selectedPlatform &&
      (selectedPlatform === "applications" || selectedPlatform === "reviewer")
    ) {
      setIsShowByDisplay(false);
      dispatch(resetSelectedShow());
    } else {
      setIsShowByDisplay(true);
    }
  }, [selectedPlatform]);

  const FacetResponsive = () => {
    let openFacet = "";
    if (window.innerWidth < 992) {
      if (displaySerpLeftBar === true) {
        openFacet += " facet-open";
      }
      return openFacet;
    }
  };

  return (
    <React.Fragment>
      <div className={`serp-leftbar ${FacetResponsive()}`}>
        <button
          className="filter-close-responsive"
          aria-label="close filter"
          onClick={(event) => props.filterByClickHandler(false, event)}>
          <FilterCloseIcon />
        </button>
        <Filters />
        <p className="filter mt-0">Filter by</p>
        <Accordion defaultActiveKey="1" key="1">
          <Card>
            <Card.Header className="facet-header">
              <ContextAwareToggle eventKey="1">
                <div
                  data-toggle-type="Subject"
                  className="d-flex flex-row justify-content-between facet-items align-items-center">
                  <p className="mb-0">Subject</p>
                  <FacetArrowIcon />
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <SubjectContainer />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="2" key="2">
          <Card>
            <Card.Header className="facet-header">
              <ContextAwareToggle eventKey="2">
                <div
                  data-toggle-type="Platform"
                  className="d-flex flex-row justify-content-between facet-items align-items-center">
                  <p className="mb-0">Platform</p>
                  <FacetArrowIcon />
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <Platform />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {isShowByDisplay && (
          <Accordion defaultActiveKey="3" key="3">
            <Card>
              <Card.Header className="facet-header">
                <ContextAwareToggle eventKey="3">
                  <div
                    data-toggle-type="Show by"
                    className="d-flex flex-row justify-content-between facet-items align-items-center">
                    <p className="mb-0">Show by</p>
                    <FacetArrowIcon />
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <Show />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
        {isSortByDisplay && (
          <Accordion defaultActiveKey="4" key="4">
            <Card>
              <Card.Header className="facet-header">
                <ContextAwareToggle eventKey="4">
                  <div
                    data-toggle-type="Sort by"
                    className="d-flex flex-row justify-content-between facet-items align-items-center">
                    <p className="mb-0">Sort by</p>
                    <FacetArrowIcon />
                  </div>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <Sort />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </div>
    </React.Fragment>
  );
}
