import React, { useState } from "react";
import {
  OFFER_TYPES,
  ORG_CLASSIFICATION,
  PLATFORMS,
  PRODUCT_MODELS,
  PRODUCT_AUDIENCE,
} from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import { getLmsToken } from "../../util/productUtil";
import { updateRefreshInstitution } from "../exHeader/userSlice";
import "./ctaText.scss";
import { VerifyAccountPopup } from "../verifyAccountPopup/VerifyAccountPopup";

export function CTAText(props) {
  const dispatch = useDispatch();
  const config = window.__env.configs["appConfig"];
  const [showModal, setShowModal] = useState(false);
  const [showVerifyAccount, setShowVerifyAccount] = useState(true);
  const orgClassification = useSelector(
    (state) => state.user.orgClassification
  );
  const userData = useSelector((state) => state.user.userData);
  const currentInstitute = useSelector((state) => state.user.currentInstitute);
  const productData = useSelector((state) => state.product.productData);
  const productOffers = useSelector((state) => state.product.productOffers);
  const lmsToken = getLmsToken();

  const displayNotification = () => {
    dispatch(updateRefreshInstitution(true));
  };

  const verifyYourAccountClickHandler = () => {
    setShowModal(true);
    setShowVerifyAccount(false);
  };

  const applyMessage = () => {
    if (
      productOffers &&
      (!productOffers.offers ||
        (currentInstitute &&
          productOffers.offers &&
          productOffers.offers.length > 0 &&
          (productOffers.offers[0].offerType.offerTypeCode ===
            OFFER_TYPES.DIRECT_BILL ||
            productOffers.offers[0].offerType.offerTypeCode ===
              OFFER_TYPES.SEAT_LICENSE) &&
          ((!lmsToken &&
            productOffers.offers[0].orgId !==
              currentInstitute.organization.orgId) ||
            (lmsToken &&
              productOffers.offers[0].orgId !== currentInstitute.id))) ||
        (orgClassification === ORG_CLASSIFICATION.HIGHER_ED &&
          productData.audience === PRODUCT_AUDIENCE.K12)) &&
      userData &&
      !userData.isStudent
    ) {
      return (
        <div className="verify-instructor k-12-edge-case d-flex flex-row">
          <div className="vertical-line"></div>
          <div className="d-flex flex-row">
            <span className="alert-icon-outlined" aria-hidden="true"></span>
            <p className="mb-0 notification-txt">
              This course isn’t available for your selected institution. Make
              sure you have the right institution selected above to see pricing
              options for this course.
            </p>
          </div>
        </div>
      );
    } else if (
      showVerifyAccount &&
      orgClassification !== ORG_CLASSIFICATION.ANONYMOUS &&
      !userData.isStudent &&
      !userData.isValidatedInstructor &&
      !(
        orgClassification === ORG_CLASSIFICATION.HIGHER_ED &&
        productData &&
        productData.audience === PRODUCT_AUDIENCE.K12
      )
    ) {
      return (
        <div className="verify-instructor d-flex flex-row">
          <div className="vertical-line"></div>
          <span className="alert-icon-outlined" aria-hidden="true"></span>
          <p className="mb-0 notification-txt">
            <a
              href="javascript:void(0)"
              className="text-link"
              onClick={() => verifyYourAccountClickHandler()}>
              Verify your account{" "}
            </a>
            to get full access to this course
          </p>
        </div>
      );
    } else if (
      !userData &&
      productData &&
      productData.productPlatform !== PLATFORMS.COLLECTION
    ) {
      return (
        <div className="verify-instructor d-flex flex-row">
          <div className="vertical-line"></div>
          <span className="alert-icon-outlined" aria-hidden="true"></span>
          <p className="mb-0 notification-txt">
            Register or sign in to see pricing options and to create this course
          </p>
        </div>
      );
    } else if (
      userData &&
      userData.isInstructor &&
      productData &&
      productData.productPlatform !== PLATFORMS.COLLECTION &&
      userData.hasInstitutions === false &&
      (userData.selfIdentifiedInstructor || userData.isValidatedInstructor) &&
      !lmsToken
    ) {
      return (
        <React.Fragment>
          <div className="verify-instructor d-flex flex-row">
            <div className="vertical-line"></div>
            <span className="alert-icon-outlined" aria-hidden="true"></span>
            <p className="mb-0 notification-txt">
              <a
                href={`${
                  config.CONSOLE_URL
                }/account/manage/account?type=${encodeURIComponent(
                  "Return to Search"
                )}&returnUrl=${encodeURIComponent(
                  decodeURIComponent(window.location.href)
                )}`}
                target="_blank"
                rel="noreferrer"
                className="text-link"
                onClick={() => displayNotification()}
                aria-describedby="addLinkPdp">
                {" "}
                Add your institution{" "}
                <span className="sr-only" id="addLinkPdp">
                  Opens in a new tab
                </span>
              </a>
              to see all products and pricing options available.
            </p>
          </div>
        </React.Fragment>
      );
    } else if (
      userData &&
      !userData.isInstructor &&
      productData &&
      (productData.productPlatform === PLATFORMS.ETEXT ||
        productData.productPlatform === PLATFORMS.LEARNING_APPLICATIONS ||
        (productData.productPlatform === PLATFORMS.GLP &&
          productData.productModel.name === PRODUCT_MODELS.ETEXT2_CITE))
    ) {
      return (
        <div className="verify-instructor d-flex flex-row">
          <div className="vertical-line"></div>
          <span className="alert-icon-outlined" aria-hidden="true"></span>
          <p className="mb-0 notification-txt">
            You will need a course invite link.
          </p>
        </div>
      );
    }
  };

  const verifyHandleClose = () => {
    setShowModal(false);
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      {applyMessage()}
      {showModal && (
        <VerifyAccountPopup
          showModal={showModal}
          verifyHandleClose={verifyHandleClose}
        />
      )}
    </React.Fragment>
  );
}
