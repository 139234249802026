import React from "react";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../../../../util/offerUtils";
import "./institutionalLicensing.scss";

export const InstitutionalLicensing = () => {
   const productAvailableToBuyOffers = useSelector(
     (state) => state.product.productAvailableToBuyOffers
   );
  

  const getLicenseInfo = (productAvailableToBuyOffers) => {
    return (
      <React.Fragment>
        {(!productAvailableToBuyOffers.availableTobuy ||
          (productAvailableToBuyOffers.availableTobuy &&
            productAvailableToBuyOffers.availableTobuy.length === 0)) && (
          <React.Fragment>
            <div className="d-flex align-items-center justify-content-between available-offer">
              <p className="title mb-0">TBD</p>
            </div>
            <p className="sub-text mb-0">{`Access is sold per student, per course, for up to 1-year online access.`}</p>
            <p className="sub-text mb-0">
              <b>ISBN </b>TBD
            </p>
          </React.Fragment>
        )}
        {productAvailableToBuyOffers.availableTobuy &&
          productAvailableToBuyOffers.availableTobuy.length > 0 &&
          productAvailableToBuyOffers.availableTobuy.map(
            (availableOffer, index) => (
              <React.Fragment>
                <div className="d-flex align-items-center justify-content-between available-offer">
                  {availableOffer.offertitle ? (
                    <React.Fragment>
                      <p className="title mb-0">{availableOffer.offertitle}</p>
                      <p className="title mb-0">
                        {availableOffer.price &&
                        availableOffer.price > 0 &&
                        availableOffer.currency
                          ? getCurrencySymbol(
                              availableOffer.currency.toUpperCase()
                            )
                          : ""}
                        {availableOffer.price && availableOffer.price > 0
                          ? availableOffer.price.toFixed(2) + "*"
                          : availableOffer.price === 0
                          ? "$0.00"
                          : "TBD"}
                      </p>
                    </React.Fragment>
                  ) : (
                    <p className="title mb-0">TBD</p>
                  )}
                </div>

                {availableOffer.offerDescription ? (
                    <p className="sub-text mb-0">{availableOffer.offerDescription}</p>
                ) : (
                    <p className="sub-text mb-0">TBD</p>
                )}
                <p className="sub-text mb-0">
                  <b>ISBN </b>
                  {availableOffer.isbn ? availableOffer.isbn : "TBD"}
                </p>
              </React.Fragment>
            )
          )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {productAvailableToBuyOffers && (
        <div className="institutional-licensing-wr">
          <div className="intitute-icon d-flex align-items-center justify-content-center">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true">
              <path
                d="M12 2.57143V3.00001C12 3.05684 11.9789 3.11134 11.9414 3.15153C11.9039 3.19171 11.853 3.21429 11.8 3.21429H11.2V3.53572C11.2 3.71323 11.0657 3.85715 10.9 3.85715H1.1C0.934325 3.85715 0.8 3.71323 0.8 3.53572V3.21429H0.2C0.146957 3.21429 0.0960859 3.19171 0.0585786 3.15153C0.0210714 3.11134 0 3.05684 0 3.00001V2.57143C2.86036e-07 2.52904 0.011737 2.4876 0.033725 2.45235C0.0557131 2.41711 0.0869645 2.38964 0.123525 2.37343L5.92352 0.016293C5.97249 -0.00543099 6.02751 -0.00543099 6.07647 0.016293L11.8765 2.37343C11.913 2.38964 11.9443 2.41711 11.9663 2.45235C11.9883 2.4876 12 2.52904 12 2.57143ZM11.4 10.7143H0.6C0.268625 10.7143 0 11.0021 0 11.3571V11.7857C0 11.8425 0.0210714 11.8971 0.0585786 11.9372C0.0960859 11.9774 0.146957 12 0.2 12H11.8C11.853 12 11.9039 11.9774 11.9414 11.9372C11.9789 11.8971 12 11.8425 12 11.7857V11.3571C12 11.0021 11.7314 10.7143 11.4 10.7143ZM2 4.28572V9.42857H1.1C0.934325 9.42857 0.8 9.57249 0.8 9.75V10.2857H11.2V9.75C11.2 9.57249 11.0657 9.42857 10.9 9.42857H10V4.28572H8.4V9.42857H6.8V4.28572H5.2V9.42857H3.6V4.28572H2Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="title mb-0">Institutional licensing</p>
          <p className="sub-text mb-0">
            Buy student access without students needing to redeem an access code
          </p>
          {getLicenseInfo(productAvailableToBuyOffers)}
        </div>
      )}
    </React.Fragment>
  );
};
