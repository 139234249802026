import React from "react";
import { useSelector } from "react-redux";
import { CallToAction } from "../callToAction/CallToAction";
import { CTAText } from "../ctaText/CTAText";
import "./ctaOfferContainer.scss";
import {
  USER_ROLE,
  PLATFORMS,
  PRODUCT_STAGE,
  PRODUCT_AUDIENCE,
} from "../../../config";
import { Offers } from "../offers/Offers";
import { ReactComponent as SeatLicensePdp } from "../../images/seat-license-pdp.svg";

export function CTAOfferContainer() {
  const userRole = useSelector((state) => state.user.userRole);
  const userData = useSelector((state) => state.user.userData);
  const productData = useSelector((state) => state.product.productData);
  const showDDA = useSelector((state) => state.product.showDDA);
  const isActiveSeatLicenseAvaliable = useSelector(
    (state) => state.product.isActiveSeatLicenseAvaliable
  );

  const getLicenseActivated = () => {
    return (
      <div className="seat-license-activated-wr">
        <div className="seat-license-img d-flex justify-content-center">
          <SeatLicensePdp />
        </div>
        {userData.isValidatedInstructor && (
          <React.Fragment>
            <p>Institutional license activated</p>
            <p>Create this course using your institution's license</p>
          </React.Fragment>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      {userRole === USER_ROLE.ANONYMOUS && (
        <div className="pdp-rightside-wr">
          <CallToAction />
          {productData &&
            productData.productPlatform !== PLATFORMS.COLLECTION &&
            productData.productStage !== PRODUCT_STAGE.REVIEW && <CTAText />}
        </div>
      )}
      {userRole === USER_ROLE.HIGHER_ED_INSTRUCTOR && (
        <React.Fragment>
          {showDDA ? (
            <React.Fragment>
              <CallToAction />
              {productData.audience === PRODUCT_AUDIENCE.HIGHER_ED && (
                <Offers />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="pdp-rightside-wr pdp-hied-wr">
                <CallToAction />
                {productData &&
                  productData.productPlatform !== PLATFORMS.COLLECTION &&
                  productData.productStage !== PRODUCT_STAGE.REVIEW && (
                    <CTAText />
                  )}
              
              {productData.audience === PRODUCT_AUDIENCE.HIGHER_ED && (
                <Offers />
              )}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {userRole === USER_ROLE.K12_BUYER && (
        <div className="pdp-rightside-wr pdp-k12-buyer-wr">
          <div className="custom-padding">
            {!showDDA && isActiveSeatLicenseAvaliable && getLicenseActivated()}
            <CallToAction />
            {!showDDA &&
              productData &&
              productData.productPlatform !== PLATFORMS.COLLECTION &&
              productData.productStage !== PRODUCT_STAGE.REVIEW && <CTAText />}
          </div>
          {userData && productData && !isActiveSeatLicenseAvaliable && (
            <Offers />
          )}
        </div>
      )}
      {userRole === USER_ROLE.STUDENT && (
        <React.Fragment>
          <div className="pdp-rightside-wr pdp-hied-student-wr">
            <CallToAction />

            <Offers />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
