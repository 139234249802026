import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ExHeader } from "../../widgets/exHeader/ExHeader";
import { PAGES } from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import { disciplineAsync } from "../../widgets/discipline/disciplineSlice";
import { SearchMobileView } from "../../widgets/searchMobileView/SearchMobileView";

export function NotFound() {
  let location = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();
  let queryParams = new URLSearchParams(location.search);
  const [displaySkipNav, setdisplaySkipNav] = useState("");
  const disciplineList = useSelector(
    (state) => state.discipline.disciplineList
  );
  const isMobileView = useSelector((state) => state.user.isMobileView);
  const isMobileSearchView = useSelector(
    (state) => state.search.isMobileSearchView
  );
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (!disciplineList) {
      dispatch(disciplineAsync());
    }
  }, []);

  const redirectToHome = () => {
    if (queryParams.has("uuid")) {
      queryParams.delete("uuid");
    }
    if (queryParams.has("q")) {
      queryParams.delete("q");
    }
    history.push(`/?${queryParams.toString()}`);
  };

  const backToPrevPage = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      {!isMobileSearchView && (
        <React.Fragment>
          <a
            className="skip-nav skip-nav-fixed-header"
            data-role="none"
            href="#main-content-starts"
            onFocus={() => {
              setdisplaySkipNav("fixedHeaderSkipNavLinkOnFocus");
            }}
            onBlur={() => {
              setdisplaySkipNav("fixedHeaderSkipNavLinkOnBlur");
            }}>
            Skip to main content
          </a>
          <div
            className={`"container-fluid main-bg custom-pdp getting-started-wr" ${displaySkipNav}`}>
            <div className="container-fluid header-wr">
            <div className={userData === null ? "header-container" : "header-container signed-in-wr"}>
                <ExHeader cPage={PAGES.NOT_FOUND} />
              </div>
            </div>
            <div className="horizontal-separator"></div>
            <main
              className="pdp-wrap row flex-column getting-started-page-wr"
              id="main-content-starts"
              tabIndex="-1">
              <div
                className="container noDisplay-PDP d-flex flex-column error-404"
                role="alert"
                aria-live="assertive">
                <h1 className="my-0">Well…This is awkward.</h1>
                <p>You are looking for something that doesn't exist.</p>
                <button
                  onClick={redirectToHome}
                  className="custom-btn secondary-btn btn-404">
                  Back to the homepage
                </button>
                <h2 className="my-0">What happened?</h2>
                <p>
                  The page you're looking for appears to have been moved,
                  deleted or does not exist. You could go to the home page, or
                  back to{" "}
                  <a href="javascript:void(0)" onClick={backToPrevPage} className="text-link">
                    your previous page
                  </a>
                  .
                </p>
              </div>
            </main>
          </div>
        </React.Fragment>
      )}
      {isMobileView && isMobileSearchView && <SearchMobileView />}
    </React.Fragment>
  );
}
