import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFilterSelected: false,
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    updateIsFilterSelected: (state, action) => {
      state.isFilterSelected = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { updateIsFilterSelected } = filtersSlice.actions;
export default filtersSlice.reducer;
