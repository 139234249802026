import React from "react";
import discipline01 from "../../images/discipline_01.jpg";
import discipline02 from "../../images/discipline_02.jpg";
import discipline03 from "../../images/discipline_03.jpg";

export function DisciplineHeaderImage(props) {
  const disciplineImages = [discipline01, discipline02, discipline03];

  return (
    <React.Fragment>
      <div className="ex-box-img">
        <picture>
          {
            <img
              src={disciplineImages[props.index]}
              alt=""
              role="presentation"
            />
          }
        </picture>
      </div>
    </React.Fragment>
  );
}
