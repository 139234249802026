import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAutoComplete } from "./searchApi";
import { AUTO_COMPLETE_LENGTH } from "../../../config";

const initialState = {
  autoCompleteList: null,
  isAutoComplete: false,
  searchValue: "",
  isAutoCompleteSearch: false,
  sendSearchRequest: 0,
  isFocusCloseButton: false,
  isMobileSearchView: false,
};

export const autocompleteAsync = createAsyncThunk(
  "search/fetchAutoComplete",
  async (args) => {
    const response = await fetchAutoComplete(
      args.searchValue,
      args.country,
      args.orgId,
      args.orgClassification,
      args.token
    );
    return response.data;
  }
);

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    chngIsAutoComplete: (state, action) => {
      if (action.payload === false) {
        state.autoCompleteList = null;
      }
      state.isAutoComplete = action.payload;
    },
    chngeSearchValue: (state, action) => {
      state.searchValue = action.payload;
      state.isAutoCompleteSearch = false;
    },
    resetAutoComplete: (state, action) => {
      state.isAutoComplete = false;
      state.autoCompleteList = null;
      state.isAutoCompleteSearch = true;
    },
    resetSearch: (state, action) => {
      state.isAutoComplete = false;
      state.autoCompleteList = null;
      state.isAutoCompleteSearch = true;
      state.searchValue = "";
    },
    updateSendSearchRequest: (state, action) => {
      state.sendSearchRequest = action.payload;
    },
    updateIsFocusCloseButton: (state, action) => {
      state.isFocusCloseButton = action.payload;
    },
    updateIsMobileSearchView: (state, action) => {
      state.isMobileSearchView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(autocompleteAsync.fulfilled, (state, action) => {
      state.autoCompleteList = action.payload;
      if (
        state.searchValue.length >= AUTO_COMPLETE_LENGTH &&
        state.isAutoCompleteSearch === false
      ) {
        state.isAutoComplete = true;
      } else {
        state.isAutoComplete = false;
      }
    });
  },
});

export const {
  chngIsAutoComplete,
  chngeSearchValue,
  resetAutoComplete,
  resetSearch,
  updateSendSearchRequest,
  updateIsFocusCloseButton,
  updateIsMobileSearchView,
} = searchSlice.actions;
export default searchSlice.reducer;
