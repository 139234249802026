import React, { useState, useEffect } from "react";
import { ExHeader } from "../../widgets/exHeader/ExHeader";
import { PAGES } from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import { disciplineAsync } from "../../widgets/discipline/disciplineSlice";
import { SearchMobileView } from "../../widgets/searchMobileView/SearchMobileView";

export function RedeemCode() {
  const dispatch = useDispatch();
  const [displaySkipNav, setdisplaySkipNav] = useState("");
  const disciplineList = useSelector(
    (state) => state.discipline.disciplineList
  );
  const isMobileView = useSelector((state) => state.user.isMobileView);
  const isMobileSearchView = useSelector(
    (state) => state.search.isMobileSearchView
  );
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (!disciplineList) {
      dispatch(disciplineAsync());
    }
  }, []);

  return (
    <React.Fragment>
      {!isMobileSearchView && (
        <React.Fragment>
          <a
          
            className="skip-nav skip-nav-fixed-header"
            data-role="none"
            href="#main-content-starts"
            onFocus={() => {
              setdisplaySkipNav("fixedHeaderSkipNavLinkOnFocus");
            }}
            onBlur={() => {
              setdisplaySkipNav("fixedHeaderSkipNavLinkOnBlur");
            }}>
            Skip to main content
          </a>
          <div
            className={`"container-fluid main-bg custom-pdp getting-started-wr" ${displaySkipNav}`}>
            <div className="container-fluid header-wr">
              <div
                className={
                  userData === null
                    ? "header-container"
                    : "header-container signed-in-wr"
                }>
                <ExHeader cPage={PAGES.NOT_FOUND} />
              </div>
            </div>
            <div className="horizontal-separator"></div>
            <main
              className="pdp-wrap row flex-column getting-started-page-wr"
              id="main-content-starts"
              tabIndex="-1">
              <div
                className="container noDisplay-PDP d-flex flex-column error-404 pt-0"
                role="alert"
                aria-live="assertive">
                <h2 className="mb-3">This page is no longer active.</h2>
                <p className="mb-2">
                  To access your instructor's Pearson eText course, you must use
                  your instructor's <i>course invite link.</i>
                </p>
                <p className="mb-2">
                  {" "}
                  If you do not have a course invite link, you can request a
                  refund from your original point of purchase and buy instant
                  access to your eText through{" "}
                  <a
                    href="https://www.pearson.com/en-us/pearsonplus.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link">
                    Pearson+
                  </a>{" "}
                  in the US or{" "}
                  <a
                    href="https://www.pearson.com/en-ca.html"
                    className="text-link"
                    target="_blank"
                    rel="noopener noreferrer">
                    Pearson Learner Store
                  </a>{" "}
                  in Canada.
                </p>
                <p className="mb-2">
                  {" "}
                  Questions? Contact{" "}
                  <a
                    href="https://support.pearson.com/getsupport/s/"
                    className="text-link"
                    target="_blank"
                    rel="noopener noreferrer">
                    Tech Support
                  </a>
                  .
                </p>
              </div>
            </main>
          </div>
        </React.Fragment>
      )}
      {isMobileView && isMobileSearchView && <SearchMobileView />}
    </React.Fragment>
  );
}
