import React from "react";
import {
  updateIsMobileSearchView,
  chngeSearchValue,
  resetAutoComplete,
} from "../../widgets/search/searchSlice";
import { Search } from "../../widgets/search/Search";
import { useDispatch } from "react-redux";
import "./searchMobileView.scss";
import { ReactComponent as SearchCloseIcon } from "../../widgets/search/images/search-close-icon.svg";

export const SearchMobileView = () => {
    const dispatch = useDispatch();

  const mobileSearchCLoseHandler = () => {
    dispatch(updateIsMobileSearchView(false));
    dispatch(chngeSearchValue(""));
    dispatch(resetAutoComplete());
  };

  return (
    <div className="tab-mobile-search-wr d-flex align-items-center">
      <Search />
      <button
        className="search-close-responsive"
        aria-label="Clear search"
        onClick={() => mobileSearchCLoseHandler()}>
        <SearchCloseIcon />
      </button>
    </div>
  );
};
