import { configureStore } from "@reduxjs/toolkit";
import disciplineReducer from "../components/widgets/discipline/disciplineSlice";
import searchReducer from "../components/widgets/search/searchSlice";
import searchResultReducer from "../components/widgets/searchResult/searchResultSlice";
import subjectReducer from "../components/widgets/subject/subjectSlice";
import userReducer from "../components/widgets/exHeader/userSlice";
import productReducer from "../components/pages/pdp/productSlice";
import platformReducer from "../components/widgets/platform/platformSlice";
import sortReducer from "../components/widgets/sort/sortSlice";
import showReducer from "../components/widgets/show/showSlice";
import filtersReducer from "../components/widgets/filters/filtersSlice";
import hamburgerReducer from "../components/widgets/hamburger/hamburgerSlice";
import serpLeftBarReducer from "../components/widgets/serpLeftBar/serpLeftBarSlice";

export const store = configureStore({
  reducer: {
    discipline: disciplineReducer,
    search: searchReducer,
    searchResult: searchResultReducer,
    subject: subjectReducer,
    user: userReducer,
    product: productReducer,
    platform: platformReducer,
    sort: sortReducer,
    show: showReducer,
    filters: filtersReducer,
    hamburger: hamburgerReducer,
    serpLeftBar: serpLeftBarReducer,
  },
});
