import _ from "lodash";

export const getsecondaryDisciplinesCodes = (disciplines) => {
  let codeList = disciplines.map((discipline) => {
    return discipline.code;
  });
  return codeList.join("|");
};

export const getSearchUrl = (
  queryParams,
  isCategory,
  discipline,
  secondaryDisciplines
) => {
  if (isCategory) {
    queryParams.set("cat", discipline.name);
    if (queryParams.has("disc")) {
      queryParams.delete("disc");
    }
  } else {
    queryParams.set("disc", getsecondaryDisciplinesCodes(secondaryDisciplines));
    if (queryParams.has("cat")) {
      queryParams.delete("cat");
    }
  }

  if (queryParams.has("q")) {
    queryParams.delete("q");
  }

  if (queryParams.has("uuid")) {
    queryParams.delete("uuid");
  }
  return `/search?${queryParams.toString()}`;
};

export const findSubjectDisciplinesCodes = (
  disciplineList,
  primaryDiscipline
) => {
  let disciplinesCodes = "";

  for (
    var i = 0;
    i < disciplineList._embedded.disciplineCategories.length;
    i++
  ) {
    let selectedPrimaryDiscipline = _.find(
      disciplineList._embedded.disciplineCategories[i].primaryDisciplines,
      function (pDiscipline) {
        return pDiscipline.name === primaryDiscipline.key;
      }
    );
    if (selectedPrimaryDiscipline) {
      disciplinesCodes = getsecondaryDisciplinesCodes(
        selectedPrimaryDiscipline.secondaryDisciplines
      );
      break;
    }
  }
  return disciplinesCodes;
};

export const getSubjectSearchUrl = (
  disciplineList,
  primaryDiscipline,
  queryParams
) => {
  const disciplinesCodes = findSubjectDisciplinesCodes(
    disciplineList,
    primaryDiscipline
  );
  queryParams.set("disc", disciplinesCodes);
  if (queryParams.has("cat")) {
    queryParams.delete("cat");
  }
  return `/search?${queryParams.toString()}`;
};
