import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { SERP_SHOW, SERP_PLATFORMS } from "../../../config";
import { updateSelectedShow, resetSelectedShow } from "./showSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { updateSendSearchRequest } from "../search/searchSlice";
import { updateCurrentResultPage } from "../searchResult/searchResultSlice";
import { resetDisciplineCount } from "../subject/subjectSlice";

export function Show(props) {
  const dispatch = useDispatch();
  const selectedShow = useSelector((state) => state.show.selectedShow);
  const userData = useSelector((state) => state.user.userData);
  const selectedPlatform = useSelector(
    (state) => state.platform.selectedPlatform
  );
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const [showItems, setShowItems] = useState([]);
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    setShowItems([]);
    if (selectedPlatform === SERP_PLATFORMS.ETEXT.type) {
      setShowItems((prevState) => [...prevState, SERP_SHOW.AVAILABLE]);
      if (
        userData &&
        userData.isInstructor &&
        userData.organizations &&
        userData.organizations.length > 0
      ) {
        setShowItems((prevState) => [...prevState, SERP_SHOW.INSTITUTION]);
      }
      if (
        selectedShow === SERP_SHOW.SAMPLES ||
        selectedShow === SERP_SHOW.COMING_SOON
      ) {
        dispatch(resetSelectedShow());
      }
    } else if (selectedPlatform === SERP_PLATFORMS.COLLECTION.type) {
      setShowItems((prevState) => [...prevState, SERP_SHOW.AVAILABLE]);
      if (
        selectedShow === SERP_SHOW.SAMPLES ||
        selectedShow === SERP_SHOW.COMING_SOON ||
        selectedShow === SERP_SHOW.INSTITUTION
      ) {
        dispatch(resetSelectedShow());
      }
    } else if (selectedPlatform === SERP_PLATFORMS.CUSTOM.type) {
      setShowItems((prevState) => [...prevState, SERP_SHOW.AVAILABLE]);
      if (
        selectedShow === SERP_SHOW.SAMPLES ||
        selectedShow === SERP_SHOW.COMING_SOON ||
        selectedShow === SERP_SHOW.INSTITUTION
      ) {
        dispatch(resetSelectedShow());
      }
    } else if (selectedPlatform === SERP_PLATFORMS.CLASS_TEST.type) {
      if (
        userData &&
        userData.isInstructor &&
        userData.organizations &&
        userData.organizations.length > 0
      ) {
        setShowItems((prevState) => [...prevState, SERP_SHOW.INSTITUTION]);
      }
      if (
        selectedShow === SERP_SHOW.SAMPLES ||
        selectedShow === SERP_SHOW.COMING_SOON ||
        selectedShow === SERP_SHOW.AVAILABLE
      ) {
        dispatch(resetSelectedShow());
      }
    } else if (
      selectedPlatform === SERP_PLATFORMS.REVEL.type &&
      queryParams.has("integration_context_token")
    ) {
      setShowItems((prevState) => [...prevState, SERP_SHOW.AVAILABLE]);
      if (
        userData &&
        userData.isInstructor &&
        userData.organizations &&
        userData.organizations.length > 0
      ) {
        setShowItems((prevState) => [...prevState, SERP_SHOW.INSTITUTION]);
      }
      if (
        selectedShow === SERP_SHOW.SAMPLES ||
        selectedShow === SERP_SHOW.COMING_SOON
      ) {
        dispatch(resetSelectedShow());
      }
    } else {
      setShowItems((prevState) => [...prevState, SERP_SHOW.AVAILABLE]);
      setShowItems((prevState) => [...prevState, SERP_SHOW.SAMPLES]);
      setShowItems((prevState) => [...prevState, SERP_SHOW.COMING_SOON]);
      if (
        userData &&
        userData.isInstructor &&
        userData.organizations &&
        userData.organizations.length > 0
      ) {
        setShowItems((prevState) => [...prevState, SERP_SHOW.INSTITUTION]);
      }
    }
  }, [selectedPlatform, userData]);

  const showClickHandler = (event) => {
    dispatch(resetDisciplineCount());
    dispatch(updateCurrentResultPage(1));
    dispatch(
      updateSelectedShow({
        type: event.target.value,
        displayValue: event.target.labels[0].innerText,
      })
    );
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
    ReactGA.event({
      category: "Facet",
      action: "click Show By SERP filter by Show By",
      label: event.target.value,
    });
  };

  const validateShowByChecked = (type) => {
    if (!selectedShow) {
      return false;
    } else {
      if (type === selectedShow) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Form>
      <div key="show-by" className="sort-by col-12">
        {showItems.map((showType, index) => (
          <Form.Check
            label={showType.displayValue}
            name="show"
            type="radio"
            id={showType.type}
            value={showType.type}
            checked={validateShowByChecked(showType.type)}
            onChange={(event) => showClickHandler(event)}
            key={index}
          />
        ))}
      </div>
    </Form>
  );
}
