import { uniq, has } from "lodash-es";
import {
  AUTO_COMPLETE_AUTHOR_LENGTH,
  AUTO_COMPLETE_TITLE_LENGTH,
} from "../../../config";

const CSG_CONSTANTS = {
  AUTHOR_MATCHEDFIELDS: [
    "cnt:auxiliaryFields.authors.givenName",
    "cnt:auxiliaryFields.authors.familyName",
  ],
  SHOW_ALL_TXT: "Show all results for",
  MAX_AUTHORS: 5,
};

const AUTOCOMPLETE_CONSTANTS = {
  URL: "http://help.pearsoncmg.com/collections/instructor/en-us/content/search/search.htm",
  HELPICON_CLASSNAME: "fa fa-question-circle fa-lg",
  ENTERKEYPRESS_EVENT: 13,
  UPARROWPRESS_EVENT: 38,
  DOWNARROWPRESS_EVENT: 40,
  ESCAPEPRESS_EVENT: 27,
  DELAY_EVENT: 300,
  MIN_CHAR_FOR_SEARCH: 2,
  MAX_CHAR_FOR_SEARCH: 100,
  ASCII_CHARACTER_DECIMAL_REGEX: /&#([0-9]{1,7});/g,
  ASCII_CHARACTER_HEXA_REGEX: /&#[xX]([0-9a-fA-F]{1,6});/g,
  HTML_REPLACE: /(<([^>]+)>)/gi,
};

function formatAuthorName(author) {
  const givenName = author.givenName ? author.givenName : "";
  const familyName = author.familyName ? author.familyName : "";
  return givenName + " " + familyName;
}

function replaceAngularBrackets(searchValue) {
  const chars = [decodeURIComponent("%3C"), decodeURIComponent("%3E")];

  let htmlTagObj = {
    [chars[0]]: "&lt",
    [chars[1]]: "&gt",
  };
  searchValue = searchValue.replace(
    /chars[0]|chars[1]/g,
    function (matchedVal) {
      return htmlTagObj[matchedVal];
    }
  );
  return searchValue;
}

function filterAuthorData(searchResults) {
  const authorList = [];

  searchResults.map((value) => {
    let isMatchFieldFound = false;
    if (value.matchedFields) {
      CSG_CONSTANTS.AUTHOR_MATCHEDFIELDS.map((matchfield) => {
        if (isMatchFieldFound === false) {
          if (has(value.matchedFields, matchfield)) {
            isMatchFieldFound = true;
          }
        }
        return true;
      });
    }

    if (isMatchFieldFound) {
      authorList.push(value);
    }
    return true;
  });

  return authorList;
}

function formatAuthors(authorList, searchKeyword) {
  let formatedAuthorList = [];
  if (authorList.length > 0) {
    authorList.map((value) => {
      value.source["cnt:auxiliaryFields"].authors.map((author) => {
        let authorName = formatAuthorName(author);
        if (
          authorName.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
        ) {
          if (formatedAuthorList.length < CSG_CONSTANTS.MAX_AUTHORS) {
            formatedAuthorList.push(authorName);
          }
        }
        return true;
      });
      return true;
    });
  }

  return uniq(formatedAuthorList);
}

function formatBooks(SearchResults) {
  let bookFormatedList = [];
  if (SearchResults.length > 0) {
    bookFormatedList = SearchResults.map((value) => {
      const formatedBook = {};
      formatedBook.resourceId = value.productId;
      if (value.source) {
        let productTitle = value.source["schema:name"]
          ? value.source["schema:name"]
          : "";
        if (productTitle.length > AUTO_COMPLETE_TITLE_LENGTH) {
          formatedBook.title = `${productTitle.slice(
            0,
            AUTO_COMPLETE_TITLE_LENGTH
          )}...`;
        } else {
          formatedBook.title = value.source["schema:name"]
            ? value.source["schema:name"]
            : "";
        }
        formatedBook.productid = value.productId ? value.productId : "";
        formatedBook.uuid = value.uuid ? value.uuid : "";
        formatedBook.slug = value.source["cnt:slug"]
          ? value.source["cnt:slug"]
          : "";

        if (value.source["schema:sourceIsbn"]) {
          formatedBook.isbn = value.source["schema:sourceIsbn"]["schema:isbn13"]
            ? value.source["schema:sourceIsbn"]["schema:isbn13"]
            : "";
        } else {
          formatedBook.isbn = "";
        }

        if (value.source["schema:images"]) {
          formatedBook.thumbnailUrl =
            value.source["schema:images"]["schema:thumbnailUrl"];
          formatedBook.thumbnailUrlCss = "thresult imageSize";
        } else {
          formatedBook.thumbnailUrlCss = "thresult defaultImageSize";
        }

        formatedBook.productPlatform = value.source["cnt:auxiliaryFields"]
          ? value.source["cnt:auxiliaryFields"].productPlatform
          : "";
        formatedBook.productModel = value.source["cnt:productModel"]
          ? value.source["cnt:productModel"]["cnt:name"]
          : "";
        formatedBook.authors = value.source["cnt:auxiliaryFields"]
          ? value.source["cnt:auxiliaryFields"].authors
          : "";
        formatedBook.productAuthorNames = "";

        if (formatedBook.authors && formatedBook.authors.length > 0) {
          var productAuthorNames = formatedBook.authors.map((author) => {
            return formatAuthorName(author);
          });

          let authorsNamesString = productAuthorNames.join(" , ");
          if (authorsNamesString.length > AUTO_COMPLETE_AUTHOR_LENGTH) {
            formatedBook.productAuthorNames = `${authorsNamesString.slice(
              0,
              AUTO_COMPLETE_AUTHOR_LENGTH
            )}...`;
          } else {
            formatedBook.productAuthorNames = authorsNamesString;
          }
        }
      }
      return formatedBook;
    });
  }
  return bookFormatedList;
}

export function formatAutoCompleteData(searchResults = [], searchKeyword = "") {
  let productsList = [];
  let authorsList = [];

  searchKeyword = replaceAngularBrackets(searchKeyword);
  productsList = formatBooks(searchResults);
  const authorList = filterAuthorData(searchResults);
  authorsList = formatAuthors(authorList, searchKeyword);

  return {
    authorsList: authorsList,
    productsList: productsList,
  };
}

function decodeASCII(str) {
  return str
    .replace(
      AUTOCOMPLETE_CONSTANTS.ASCII_CHARACTER_DECIMAL_REGEX,
      function (matches, matchValue) {
        return String.fromCharCode(parseInt(matchValue, 10));
      }
    )
    .replace(
      AUTOCOMPLETE_CONSTANTS.ASCII_CHARACTER_HEXA_REGEX,
      function (matches, matchValue) {
        return String.fromCharCode(parseInt(matchValue, 16));
      }
    );
}

function escapeSplChar(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'); // eslint-disable-line
}

function highlightSearchResult(text, searchValue) {
  if (text && text.length > 0) {
    var htmlRegExPart1 = new RegExp(/in <span\b[^>]*>(.*?)<\/span>/i);
    var htmlRegExPart4 = new RegExp(/<span\b[^>]*>(.*?)<\/span>/i);
    searchValue = replaceAngularBrackets(searchValue);
    var searchVal = searchValue.trim();
    var escSearchVal = escapeSplChar(searchVal); // escape special characters. eg:africa(
    var regex = new RegExp("(" + escSearchVal + ")", "gi");
    var str = decodeASCII(unescape(text));
    if (htmlRegExPart1.test(str)) {
      var hightlightTxt =
        '<b class="thresult highlightedTxt">' +
        text.replace(htmlRegExPart1, "").trim() +
        "</b>";
      return hightlightTxt + " " + htmlRegExPart1.exec(text)[0];
    }
    if (htmlRegExPart4.test(str)) {
      var _hightlightTxt =
        '<b class="thresult highlightedTxt">' +
        text.replace(htmlRegExPart4, "").trim() +
        "</b>";
      return htmlRegExPart4.exec(text)[0] + " " + _hightlightTxt;
    }
    return str.replace(regex, '<b class="thresult highlightedTxt">$1</b>');
  } else {
    return text;
  }
}

export function highlightText(name, searchValue) {
  return highlightSearchResult(name, searchValue);
}

export function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    url = urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
    return url;
  } else {
    return url;
  }
}
