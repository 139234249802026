import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSearchUrl } from "../../util/disciplineUtil";
import {
  updateSelectedDisciplineCategory,
  updateSelectedPrimaryDiscipline,
} from "./disciplineSlice";
import { updateSendSearchRequest } from "../search/searchSlice";
import { updateIsRedirectToSearch } from "../searchResult/searchResultSlice";
import { updateSelectedSubject } from "../subject/subjectSlice";
import useRefPlatforms from "../../util/useRefPlatforms";

export function Discipline(props) {
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const [handleRefPlatform] = useRefPlatforms();

  const disciplineCategoryClickHandler = (event) => {
    dispatch(updateSelectedDisciplineCategory(event.target.innerText));
    dispatch(
      updateSelectedSubject({
        type: event.target.innerText,
        displayValue: event.target.innerText,
      })
    );
    handleRefPlatform();
    dispatch(updateIsRedirectToSearch(true));
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
  };

  const primaryDisciplineClickHandler = (event) => {
    dispatch(updateSelectedPrimaryDiscipline(event.target.innerText));
    dispatch(
      updateSelectedSubject({
        type: event.target.innerText,
        displayValue: event.target.innerText,
      })
    );
    handleRefPlatform();
    dispatch(updateIsRedirectToSearch(true));
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
  };

  return (
    <React.Fragment>
      {
        <div className="ex-box-content">
          <h3 className="discipline-title">
            <Link
              to={getSearchUrl(queryParams, true, props.discipline, null)}
              onClick={(event) => disciplineCategoryClickHandler(event)}
            >
              {props.discipline.name}
            </Link>
          </h3>
          <ul className="list-group">
            {props.discipline.primaryDisciplines &&
              props.discipline.primaryDisciplines.map(
                (primaryDiscipline, index) => (
                  <li className="list-group-item" key={index}>
                    <Link
                      to={getSearchUrl(
                        queryParams,
                        false,
                        null,
                        primaryDiscipline.secondaryDisciplines
                      )}
                      onClick={(event) => primaryDisciplineClickHandler(event)}
                    >
                      {primaryDiscipline.name}
                    </Link>
                  </li>
                )
              )}
          </ul>
        </div>
      }
    </React.Fragment>
  );
}
