import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedSort } from "../sort/sortSlice";
import { resetSelectedShow } from "../show/showSlice";
import { resetSelectedPlatform } from "../platform/platformSlice";
import {
  resetSelectedSubject,
  resetSubject,
  resetDisciplineCount,
} from "../subject/subjectSlice";
import {
  resetSearchResult,
  updateCurrentResultPage,
} from "../searchResult/searchResultSlice";
import { useLocation, useHistory } from "react-router-dom";
import { updateSendSearchRequest } from "../search/searchSlice";
import { EXCHANGE_REF } from "../../../config";
import { ReactComponent as FilterClose } from "../../images/filter-close.svg";

export function Filters(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  let queryParams = new URLSearchParams(useLocation().search);
  const selectedSortDisplayName = useSelector(
    (state) => state.sort.selectedSortDisplayName
  );
  const selectedShowDisplayName = useSelector(
    (state) => state.show.selectedShowDisplayName
  );
  const selectedPlatformDisplayName = useSelector(
    (state) => state.platform.selectedPlatformDisplayName
  );
  const selectedSubjectDisplayName = useSelector(
    (state) => state.subject.selectedSubjectDisplayName
  );
  const sendSearchRequest = useSelector(
    (state) => state.search.sendSearchRequest
  );
  const searchValue = useSelector((state) => state.search.searchValue);

  const sortCloseBtnHandler = () => {
    dispatch(resetDisciplineCount());
    dispatch(updateCurrentResultPage(1));
    dispatch(resetSelectedSort());
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
  };

  const showCloseBtnHandler = () => {
    dispatch(resetDisciplineCount());
    dispatch(updateCurrentResultPage(1));
    dispatch(resetSelectedShow());
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
  };

  const platformCloseBtnHandler = () => {
    dispatch(resetSelectedPlatform());
    dispatch(resetDisciplineCount());
    dispatch(updateCurrentResultPage(1));
    if (
      queryParams.has("ref") &&
      queryParams.get("ref").toLowerCase().includes(EXCHANGE_REF.REVEL)
    ) {
      dispatch(resetSearchResult());
    } else {
      dispatch(updateSendSearchRequest(sendSearchRequest + 1));
    }
  };

  const subjectCloseBtnHandler = () => {
    dispatch(resetDisciplineCount());
    dispatch(resetSelectedSubject());
    dispatch(updateCurrentResultPage(1));
    if (queryParams.has("disc") || queryParams.has("cat")) {
      if (queryParams.has("disc")) {
        queryParams.delete("disc");
      }
      if (queryParams.has("cat")) {
        queryParams.delete("cat");
      }
      if (
        searchValue === undefined ||
        searchValue === null ||
        searchValue === ""
      ) {
        dispatch(resetSubject());
        dispatch(resetSelectedPlatform());
        dispatch(resetSelectedShow());
        dispatch(resetSelectedSort());
        dispatch(resetSearchResult());
      }
      history.push(`/search?${queryParams.toString()}`);
    }
    dispatch(updateSendSearchRequest(sendSearchRequest + 1));
  };

  const filter = (displayValue, closeBtnHandler) => {
    return (
      <li>
        <div className="applied-filter-wr d-flex flex-row align-items-center justify-content-between">
          <p className="mb-0">{displayValue}</p>
          <Button
            className="close-icon"
            onClick={() => closeBtnHandler()}
            aria-label={`Remove ${displayValue} Filter`}>
            <FilterClose />
          </Button>
        </div>
      </li>
    );
  };

  return (
    <div className="inline-block">
      {(selectedSortDisplayName !== null ||
        selectedShowDisplayName !== null ||
        selectedPlatformDisplayName !== null ||
        selectedSubjectDisplayName !== null) && (
        <div className="applied-filter-container">
          <p className="filter mt-0">Applied filters</p>
          <ul>
            {selectedSortDisplayName &&
              selectedSortDisplayName.length > 0 &&
              filter(selectedSortDisplayName, sortCloseBtnHandler)}
            {selectedShowDisplayName &&
              selectedShowDisplayName.length > 0 &&
              filter(selectedShowDisplayName, showCloseBtnHandler)}
            {selectedPlatformDisplayName &&
              selectedPlatformDisplayName.length > 0 &&
              filter(selectedPlatformDisplayName, platformCloseBtnHandler)}
            {selectedSubjectDisplayName &&
              selectedSubjectDisplayName.length > 0 &&
              filter(selectedSubjectDisplayName, subjectCloseBtnHandler)}
          </ul>
        </div>
      )}
    </div>
  );
}
