import axios from "axios";

export function fetchProduct(productId, isRelated, lmsIntegrationToken) {
  return axios.get(
    `${window.location.protocol}//${
      process.env.REACT_APP_EXCHANGE_URL
        ? process.env.REACT_APP_EXCHANGE_URL
        : window.location.host
    }/api/products/${productId}?lmsIntegrationToken=${lmsIntegrationToken}&getRelated=${isRelated}`
  );
}

export function fetchProductOffers(productId, org, lmsIntegrationToken) {
  return axios.get(
    `${window.location.protocol}//${
      process.env.REACT_APP_EXCHANGE_URL
        ? process.env.REACT_APP_EXCHANGE_URL
        : window.location.host
    }/api/products/offers/${productId}?institution=${encodeURIComponent(
      org
    )}&token=${lmsIntegrationToken}`
  );
}

export function fetchProductDisciplineFeature(
  instrFeature,
  isLearningApp,
  pDiscipline,
  pid,
  pModel
) {
  return axios.get(
    `${window.location.protocol}//${
      process.env.REACT_APP_EXCHANGE_URL
        ? process.env.REACT_APP_EXCHANGE_URL
        : window.location.host
    }/api/disciplineFeature?instrFeature=${instrFeature}&isLearningApp=${isLearningApp}&pDiscipline=${pDiscipline}&pId=${pid}&pModel=${pModel}`
  );
}

export function fetchProductToc(productId) {
  return axios.get(
    `${window.location.protocol}//${
      process.env.REACT_APP_EXCHANGE_URL
        ? process.env.REACT_APP_EXCHANGE_URL
        : window.location.host
    }/api/products/${productId}/toc`
  );
}

export function fetchProductModule(productId) {
  return axios.get(
    `${window.location.protocol}//${
      process.env.REACT_APP_EXCHANGE_URL
        ? process.env.REACT_APP_EXCHANGE_URL
        : window.location.host
    }/api/products/${productId}/module`
  );
}

export function fetchAvailableToBuyOffers(productId) {
  return axios.get(
    `${window.location.protocol}//${
      process.env.REACT_APP_EXCHANGE_URL
        ? process.env.REACT_APP_EXCHANGE_URL
        : window.location.host
    }/api/products/avalibleoffers/${productId}`
  );
}
