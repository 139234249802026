import React, { useEffect, useState } from "react";
import "./scrollToTop.scss";

export function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const main = document.querySelector("#main-content-starts");
    const focusableElements = 'h1, h2, h3';
    const firstFocusableElement = main.querySelectorAll(focusableElements)[0];
    firstFocusableElement.setAttribute("tabindex", "-1");
    firstFocusableElement.focus();
    setTimeout(() => {
      firstFocusableElement.removeAttribute("tabindex", "-1");
    }, 80);
    
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
      
    };

    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <button
          href="javascript:;"
          id="scrollTop"
          onClick={scrollToTop}
          className="scroll-content d-flex flex-column justify-content-center align-items-center"
          aria-label="Back to top">
          <span className="scroll-top-arrow" aria-hidden="true"></span>
          <span className="text-uppercase top-txt" aria-hidden="true">
            Top
          </span>
        </button>
      )}
    </div>
  );
}

export default ScrollToTop;
